export const searchConstants = {
  SET_SHOW_RISULTATI: 'SET_SHOW_RISULTATI',
  SET_AMBITO: 'SET_AMBITO',
  SET_FILTER: 'SET_FILTER',
  SET_SORTING: 'SET_SORTING',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_TIPO_RICERCA: 'SET_TIPO_RICERCA',
  RESET_PAGE_INDEX: 'RESET_PAGE_INDEX',
  RESET_RAGGRUPPAMENTI: 'RESET_RAGGRUPPAMENTI',
  RESET_SEARCH: 'RESET_SEARCH',
  SEARCH_REQUEST: 'SEARCH_REQUEST',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_FAILURE: 'SEARCH_FAILURE',
};
