import React from 'react';
//material components
import { Stepper, Step, StepLabel, withStyles } from '@material-ui/core';
//icon
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const styles = () => ({
  stepperStyle: {
    width: '100%',
    padding: 0,
  },
  step: {
    '& $active': { color: primaryColor },
    '& $completed': { color: primaryColor },
  },
  active: {},
  completed: {},
});

class SignatureWizard extends React.Component {
  render() {
    const { t, classes, verified, step, children } = this.props;

    return (
      <>
        <Stepper activeStep={step - 1} alternativeLabel className={classes.stepperStyle}>
          <Step key="Firma" classes={{ root: classes.step }}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.step,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              Firma
            </StepLabel>
          </Step>
          <Step
            key="Verifica OTP"
            classes={{ root: classes.step }}
            completed={verified ? true : false}
          >
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.step,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              Verfica OTP
            </StepLabel>
          </Step>
        </Stepper>
        {children}
      </>
    );
  }
}

export default withStyles(styles)(SignatureWizard);
