import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import ReactTable from 'react-table'; //CAMUSI 24072020

import { connect } from 'react-redux';

import Button from 'components/CustomButtons/Button.jsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Close from '@material-ui/icons/Close';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Card from 'components/Card/Card.jsx'; //CAMUSI 24072020
import CardBody from 'components/Card/CardBody.jsx'; //CAMUSI 24072020
import CardIcon from 'components/Card/CardIcon.jsx'; //CAMUSI 24072020
import CardHeader from 'components/Card/CardHeader.jsx'; //CAMUSI 24072020
import Folder from '@material-ui/icons/Folder'; //CAMUSI 24072020
import { ModelViewHelper } from 'Helpers/model-view.js'; //CAMUSI 24072020

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  //...extendedFormsStyle,
  ...regularFormsStyle,
};

const initialState = {
  selectedIdUnitaOrganizzativa: '',
  selectedUtente: '',
  initSelectUO: true,
  firma_visto: 'F',
};

class FirmatarioModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  handleClose() {
    this.setState(initialState);
    this.props.onClose();
    //this.state.id_uo_group="";
    //this.state.id_utente = "";
  }

  getFormFieldValue(pFieldName) {
    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    } else if (typeof this.props[pFieldName] !== 'undefined') {
      return this.props[pFieldName];
    }

    return undefined;
  }

  render() {
    const { t, classes, raccoglitori } = this.props;

    //CAMUSI 24072020 inizio
    let percorsiRaccoglitoreCorrente = [];
    if (raccoglitori?.current) {
      percorsiRaccoglitoreCorrente = raccoglitori.current.percorsi;
    }

    let wPercorsiData = percorsiRaccoglitoreCorrente
      .sort(function (a, b) {
        return a.ordinamento - b.ordinamento;
      })
      .map((prop, key) => {
        let wReturn = ModelViewHelper.step_percorso_ModelToView(prop);

        return wReturn;
      });
    //CAMUSI 24072020 fine

    return (
      <Dialog
        open={this.props.open}
        PaperProps={{
          style: {
            maxWidth: '50%',
            width: '50%',
            height: '60%',
          },
        }}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="success" icon>
                  <CardIcon color="success">
                    <Folder />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Step del Percorso di Firma</h4>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={wPercorsiData}
                    filterable
                    columns={[
                      {
                        Header: '#',
                        accessor: 'ordinamento',
                        sortable: false,
                        filterable: false,
                        maxWidth: 20,
                      },
                      {
                        Header: 'Nome',
                        accessor: 'nome_owner',
                        sortable: false,
                        filterable: false,
                        maxWidth: 300,
                        style: { whiteSpace: 'unset' },
                      },
                      {
                        Header: 'Tipo Utente',
                        accessor: 'tipo_utente',
                        sortable: false,
                        filterable: false,
                        maxWidth: 100,
                        style: { textAlign: 'right' },
                      },
                      {
                        Header: 'Tipo',
                        accessor: 'flag_firma_vista',
                        sortable: false,
                        filterable: false,
                        maxWidth: 100,
                        style: { textAlign: 'right' },
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    striped
                    hover
                    condensed
                    pagination
                    insertRow
                    deleteRow
                    search
                    // Text
                    previousText="Precedente"
                    nextText="Successiva"
                    loadingText="In caricamento..."
                    //noDataText='Nessun percorso firma trovato'
                    pageText="Pagina"
                    ofText="di"
                    rowsText="righe"
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          <Button color="info" onClick={() => this.handleClose()}>
            <Close style={{ margin: '0px' }} /> {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, raccoglitori, documenti, utils, users } = state;

  return {
    authentication,
    alert,
    raccoglitori,
    documenti,
    utils,
    users,
  };
}

const connectedFirmatarioModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(FirmatarioModalForm);
export default connectedFirmatarioModalForm;
