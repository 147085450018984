import React from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { temaActions, userActions } from '../Actions';
import Pages from '../layouts/TCSignPages.jsx';
import Dashboard from '../layouts/TCSignDashboard.jsx';
import Login from '../views/Pages/TC_LoginPage.jsx';
import Token from '../views/Pages/TC_TokenPage.jsx';
import NotFound from '../views/Pages/NotFoundPage';
import MaintenancePage from '../views/Pages/TCSignMaintenance';
import FirmaEsterna from '../views/Pages/FirmaEsterna.jsx';
import QRCode from '../views/Pages/QRCode.jsx';

import { NotFoundRedirect, LoginRedirect } from '../index';

const AppLoading = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={75} thickness={3} />
    </div>
  );
};

class App extends React.Component {
  state = {
    hist: null,
    routeType: null,
    user: localStorage.getItem('user'),
  };

  sessionCheck = (event) => {
    if (event.key === 'token') {
      let tenant = localStorage.getItem('theme_tenant');
      window.location.pathname = tenant;
    }
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('storage', (e) => this.sessionCheck(e));

    //check transition from single to multitenant
    let user = localStorage.getItem('user');
    if (user) {
      let { company } = JSON.parse(user);
      if (!company) this.props.dispatch(userActions.logout());
    }

    //retrieve company theme
    let company = window.location.hostname.split('.')[0];
    let tenantCode = company === process.env.REACT_APP_SUBDOMAIN ? 'default' : company;

    localStorage.setItem('theme_tenant', tenantCode);
    this.props.dispatch(temaActions.getTemaTenant(tenantCode));

    //manage routing
    let currentRoute = window.location.pathname.split('/')[1];

    let routeType = 'E';
    const externalRoutes = ['extSign', 'qrcode'];

    if (!externalRoutes.includes(currentRoute)) routeType = 'I';

    this.setState({
      hist: createBrowserHistory(),
      routeType,
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('storage', this.sessionCheck);
  };

  render() {
    const { t, hist, user, routeType } = this.state;
    const { id_tenant } = this.props;

    const externalRoutes = [
      { path: '/extSign', name: 'FirmaEsterna', component: FirmaEsterna },
      { path: '/qrcode/login', name: 'QRCode', component: QRCode },
    ];

    const internalRoutes = [
      { path: '/token', name: 'Token', component: Token },
      { path: '/auth', name: 'TCSignDashboard', component: Dashboard },
      { path: '/maintenance', name: 'Maintenance', component: MaintenancePage },
      { path: '/not_found', name: 'NotFound', component: NotFound },
      { path: '/login', name: 'Login', component: !user ? Login : Dashboard },
      {
        path: '/',
        name: 'Manager',
        component: id_tenant === -1 ? NotFoundRedirect : user ? Dashboard : LoginRedirect,
      },
    ];

    //routeType can be either "I" (internalRoutes) or "E" (externalRoutes)
    var indexRoutes = routeType === 'I' ? internalRoutes : externalRoutes;

    return !id_tenant ? (
      <AppLoading />
    ) : (
      <Router history={hist}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route path={prop.path} component={prop.component} key={key} />;
          })}
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { id_tenant } = state.tema;

  return {
    id_tenant,
  };
}

const connectedApp = connect(mapStateToProps)(App);

export default connectedApp;
