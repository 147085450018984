//import config from 'config';
import fetchTimeout from 'fetch-timeout';
import { authHeader, utils } from '../Helpers';

var wMount;
if (process.env.NODE_ENV === 'production') {
  wMount = process.env.REACT_APP_SERVICES_URL;
} else {
  wMount = process.env.REACT_APP_WKF_URL;
}
const wTimeoutInterval = 30000;

export const workflowService = {
  addStep,
  deleteStepById,
};

function addStep(flusso) {
  let wMethod = 'POST';

  let wUrl = wMount + '/api/v1/workflow/flusso';

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(flusso),
  };

  return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
    .then(handleResponse)
    .catch(function (err) {
      const error = err;
      return Promise.reject(error);
    });
}

function deleteStepById(idFlusso) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  let wUrl = wMount + '/api/v1/workflow/flusso/' + idFlusso;

  return fetchTimeout(wUrl, requestOptions, wTimeoutInterval, 'Servizio non disponibile (timeout)')
    .then(handleResponse)
    .catch(function (err) {
      const error = err;
      return Promise.reject(error);
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    var data;
    if (text && utils.isJson(text)) data = JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        return Promise.reject('unauthorizedOperation');
      } else {
        return Promise.reject('Sistema momentaneamente non disponibile');
      }
    } else {
      if (data.Result.Code !== 'E100') {
        const error = data?.Result?.Message;
        return Promise.reject(error);
      } else if (typeof data.Data.errors !== 'undefined' && data.Data.errors.length !== 0) {
        let error = '';
        for (var i = 0; i < data.Data.errors.length; i++) {
          error = data.Data.errors[i].error + '(' + data.Data.errors[i].id_documento + ')\n';
        }
        return Promise.reject(error);
      }
    }
    return data.Data;
  });
}
