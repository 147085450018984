import { alertConstants } from '../Constants';
import { userActions } from '../Actions';

export const alertActions = {
  success,
  error,
  clear,
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return (dispatch) => {
    dispatch(request());
    dispatch(userActions.resetError());
  };

  function request() {
    return { type: alertConstants.CLEAR };
  }
}
