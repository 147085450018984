import { posFirmaConstants } from '../Constants/posFirma.constants';

export const posFirmaActions = {
  setPosizionamentoFirmaDimensions,
  setPosizionamentoFirmaTagsFetched,
  setPosizionamentoFirmaTags,
  resetPosizionamentoFirma,
};

function resetPosizionamentoFirma() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: posFirmaConstants.RESET_POS_FIRMA };
  }
}

function setPosizionamentoFirmaDimensions(value) {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: posFirmaConstants.SET_POS_FIRMA_DIMENSIONS, dimensions: value };
  }
}

function setPosizionamentoFirmaTags(value) {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: posFirmaConstants.SET_POS_FIRMA_TAGS, tags: value };
  }
}

function setPosizionamentoFirmaTagsFetched(value) {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: posFirmaConstants.SET_POS_FIRMA_TAGS_FETCHED, tagsFetched: value };
  }
}
