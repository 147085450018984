import { searchConstants } from '../Constants';
import { searchService } from '../Services';
import { alertActions } from '../Actions';

const setShowRisultati = (value) => ({ type: searchConstants.SET_SHOW_RISULTATI, value });

const resetPageIndex = () => ({ type: searchConstants.RESET_PAGE_INDEX });

const setTipoRicerca = (tipoRicerca) => {
  return { type: searchConstants.SET_TIPO_RICERCA, tipoRicerca };
};

const resetSearch = () => {
  return { type: searchConstants.RESET_SEARCH };
};

const resetRaggruppamenti = () => {
  return { type: searchConstants.RESET_RAGGRUPPAMENTI };
};

const setAmbito = (name, value) => {
  return { type: searchConstants.SET_AMBITO, name, value };
};

const setFilter = (name, value) => {
  return { type: searchConstants.SET_FILTER, name, value };
};

const setFilterAndSearch = (name, value) => {
  return (dispatch, getState) => {
    dispatch(setFilter(name, value));
    dispatch(resetPageIndex());

    const { tipoRicerca, ambito, filters, pagination, sort } = getState().search;
    dispatch(search(tipoRicerca, ambito, filters, false, pagination, sort));
  };
};

const setPagination = (pageIndex, size) => {
  const request = () => ({ type: searchConstants.SET_PAGINATION, pageIndex, size });

  return (dispatch, getState) => {
    dispatch(request());
    const { tipoRicerca, ambito, filters, pagination, sort } = getState().search;

    dispatch(search(tipoRicerca, ambito, filters, false, pagination, sort));
  };
};

const setSorting = (sort) => {
  const request = () => ({ type: searchConstants.SET_SORTING, sort });

  return (dispatch, getState) => {
    dispatch(request());
    const { tipoRicerca, ambito, filters, pagination, sort } = getState().search;

    dispatch(search(tipoRicerca, ambito, filters, false, pagination, sort));
  };
};

const search = (tipoRicerca, ambito, filters, aggregate, pagination, sort) => {
  const request = () => ({ type: searchConstants.SEARCH_REQUEST });
  const success = (trovati, risultati, aggregati) => ({
    type: searchConstants.SEARCH_SUCCESS,
    trovati,
    risultati,
    aggregati,
  });
  const failure = (error) => ({ type: searchConstants.SEARCH_FAILURE, error });

  return (dispatch, getState) => {
    dispatch(request());
    let user = undefined;
    if (ambito.name === '' || !ambito.value) {
      const { id_utente, ids_uo_visibili, ruoli } = getState().authentication.user;
      user = { id_utente, ids_uo_visibili, ruoli };
    }
    searchService.search(tipoRicerca, ambito, filters, aggregate, pagination, sort, user).then(
      (results) => {
        const { trovati, risultati, aggregati } = results;
        dispatch(success(trovati, risultati, aggregati));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const newSearch = () => {
  const request = () => ({ type: searchConstants.SET_SHOW_RISULTATI, value: true });
  return (dispatch, getState) => {
    dispatch(request());
    dispatch(resetPageIndex());

    const { tipoRicerca, ambito, filters, pagination, sort } = getState().search;

    dispatch(search(tipoRicerca, ambito, filters, true, pagination, sort));
  };
};

export const searchActions = {
  setAmbito,
  setFilter,
  setFilterAndSearch,
  setSorting,
  setPagination,
  setTipoRicerca,
  setShowRisultati,
  newSearch,
  search,
  resetPageIndex,
  resetSearch,
  resetRaggruppamenti,
};
