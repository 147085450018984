import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ClipLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import DropzoneComponent from 'react-dropzone-component';
import SweetAlert from 'react-bootstrap-sweetalert';
import Moment from 'react-moment';
import moment from 'moment';
import { pdfjs } from 'react-pdf';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import SelectTooltip from '../Unita Organizzative/SelectTooltip';
import SelectSearch from 'Custom/SelectSearch.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import Table from 'components/Table/Table.jsx';
import Timeline from 'components/Timeline/Timeline.jsx';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

//@material-ui/core components
import { Dialog, DialogContent, DialogActions, Slide, Checkbox } from '@material-ui/core';
import { FormControl, Tooltip, withStyles } from '@material-ui/core';

//icons
import { Folder, Check, Save, Close, Add, Extension, Search } from '@material-ui/icons';

//assets
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import PercorsoFirma from '../Raccoglitore/PercorsoFirma.jsx';
import PosizionamentoFirma from '../Utilities/PosizionamentoFirma';
import DettaglioPercorsoModalForm from './DettaglioPercorsoModalForm.jsx'; //CAMUSI 23072020
import RaccoglitoreModalForm from '../Raccoglitore/RaccoglitoreModalForm.jsx';

import {
  documentiActions,
  alertActions,
  raccoglitoriActions,
  userActions,
  posFirmaActions,
} from '../../Actions';
import { wMount } from '../../Services/documenti.service';

//helpers
import FormValidationHelper from '../../Helpers/form-validation.js';
import { ModelViewHelper, Constants, Authorize, ServiceMatrix } from 'Helpers/model-view.js';
import { getListaFirmatariEsterni, getListaFirmatariInterni } from '../../Helpers';
import {
  areExternalsPositioned,
  areInternalsPositioned,
  areArraysOfObjectsEqual,
} from '../../Helpers';
import { toPDFPixels, authHeader, capitalizeFirstLetter, utils } from '../../Helpers';

var pathStatic = process.env.REACT_APP_STATIC_FILE;
pdfjs.GlobalWorkerOptions.workerSrc = wMount + '/' + pathStatic + '/pdf.worker.js';

const styles = {
  ...extendedFormsStyle,
  tcSignTableButton: {
    margin: '0px',
    padding: '0px',
    height: 'auto!important',
  },
  tcSignTableRow: {
    height: '28px!important',
    fontSize: '14px',
  },
  tcSignTableHeadRow: {
    height: '28px',
  },
  tcSignTableCell: {
    height: '28px!important',
  },
  tcSignTableHead: {
    color: '#AAA !important',
    padding: '14px 0px 0px 0px!important',
    fontSize: '11px!important',
    lineHeight: '1rem!important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  tcSignInputStyle: {
    fontSize: '8px',
  },
  tooltip: {
    fontSize: '14px',
  },
  selectLabel: {
    fontSize: '14px',
    //textTransform: "uppercase",
    color: '#AAA !important',
    top: '8px',
  },
  tcSignSelectControl: {
    margin: '7px 1px 0px 0px !important',
  },
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '0px',
  },
  tcsign_cardbody: {
    'padding-top': '0',
    'padding-bottom': '0',
  },
  tcsign_switchIconChecked: {
    'border-color': '#AAA',
  },
  tcsign_dialog: {
    'max-width': '700px',
  },
  tc_sign_btn_confirm: {
    'background-color': '#4caf50 !important',
  },
  tc_sign_required_div: {
    opacity: '1',
    'font-size': '14px',
    'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
    color: '#9a9898',
    'font-weight': '400',
    'line-height': '1.42857',
  },
  tcsign_percorso: {
    'line-height': '90px !important',
    padding: '0px !important',
    display: 'inline-block',
  },
};

const overlay = css`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const initialState = {
  value: '',
  documento: {},
  idDocumento: '',
  idDocumentoState: '',
  nomeDocumento: '',
  nomeDocumentoState: '',
  descrizioneDocumento: '',
  descrizioneDocumentoState: '',
  id_uo_created: undefined,
  id_uo_createdState: undefined,
  id_prioritaState: undefined,
  id_confidenzialita: undefined,
  id_confidenzialitaState: undefined,
  id_raccoglitore: undefined,
  id_raccoglitoreState: undefined,
  id_uo_richiedente: undefined,
  id_uo_richiedenteState: undefined,
  id_uo_rif_interno_protocollo: undefined,
  id_uo_rif_interno_protocolloState: undefined,
  showDettaglioPercorsoModalForm: false, //CAMUSI 23072020
  id_tipo_documento: undefined,
  id_tipo_documentoState: undefined,
  tipoDocumento: '',
  tipoDocumentoState: '',
  raccoglitoreDocumento: '',
  inviaProtocollo: '',
  inviaProtocolloState: '',
  linkDocumento: '',
  idRaccoglitore: '',
  statoDocumento: '',
  files: [],
  file: null,
  direzione: '1',
  signatureAlert: false,
  closeAlert: false,
  activeTab: 0,
  flag_protocollo: undefined,
  qr_code_enable: undefined,
  repertorio: undefined,
  flag_protocollo_uffici: undefined,
  nome: undefined,
  nomeState: undefined,
  descrizione: undefined,
  descrizioneState: undefined,
  numero_protocollo: undefined,
  oggetto_protocollo: undefined,
  impegno_spesa: undefined,
  oggetto_protocolloState: undefined,
  data_protocollo: undefined,
  uo_rif_interno_protocollo: undefined,
  utente_rif_interno_protocollo: undefined,
  submitted: false,
  noteModal: null,
  sollecitoModal: null,
  closeInfo: false,
  confirm: undefined,
  showRaccoglitore: false,
};

class DocumentoModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);
    const { t } = props;
    this.state = {
      ...initialState,
      id_priorita: !this.props.id ? 'B' : undefined,
    };

    this.componentConfig = {
      postUrl: 'no-url',
      addRemoveLinks: false,
      showFiletypeIcon: true,
      dictDefaultMessage: t('document.clickOrDrag'),
    };

    this.djsConfig = {
      autoProcessQueue: false,
      acceptedFiles:
        'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      dictDefaultMessage: t('document.clickOrDrag'),
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview">
          <div className="dz-details"></div>
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress="true"></span>
          </div>
        </div>
      ),
    };

    this.eventHandlers = {
      addedfile: (file) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          const document = fileReader.result;
          /*const regex = /(?<=:)(.*)(?=;)/;
                    const fileType = document.match(regex)[0];*/
          const fileType = utils.base64MimeType(document);
          const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ];

          console.log('Mimetype uploaded file : ', fileType);

          if (allowedTypes.includes(fileType)) this.uploadFile(file);
          else this.props.enqueueSnackbar(t('document.fileRestrict'), { variant: 'warning' });
        };
      },
    };
    this.navPills = React.createRef();
    this.gestMeeting = React.createRef();
  }

  componentDidCatch(errorString) {
    this.setState({ error: errorString });
  }

  componentDidMount() {
    const { id, documenti, alert } = this.props;
    const { loading, current } = documenti ?? {};

    const noAlert = typeof alert === 'undefined' || typeof alert.type === 'undefined';

    if (!loading && id && current && noAlert)
      this.props.dispatch(documentiActions.getDocumentoById(id));
  }

  uploadFile(file) {
    const { user } = this.props;
    this.props.dispatch(
      documentiActions.upload({ file, id: this.getFormFieldValue('id') }, user.ruoli)
    );
  }

  openNoteModal() {
    const { t } = this.props;
    this.setState({
      noteModal: (
        <SweetAlert
          input
          inputType="textarea"
          showCancel
          allowEscape
          style={{ display: 'block' }}
          title={t('note.addComment')}
          onConfirm={(e) => this.handleAddNota(e)}
          onCancel={() => this.closeNoteModal()}
          validationMsg={t('note.verifyComment')}
          confirmBtnText={t('note.save')}
          cancelBtnText={t('note.close')}
          confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
        />
      ),
    });
  }

  openSollecitoModal(id_documento, id_utente, id_uo, id_contatto) {
    const { i18n } = this.props;
    console.log(
      'UTENTE SOLL : ' + id_utente + ' UO SOLL : ' + id_uo + ' CONTATTO SOLL: ' + id_contatto
    );
    this.props.dispatch(
      documentiActions.sendSollecito(
        id_documento,
        id_utente,
        id_uo,
        undefined,
        id_contatto,
        i18n.language
      )
    );
  }

  bloccaProcessoModal(id_documento) {
    const { t, user } = this.props;
    this.setState({
      showalert: true,
      sweetAlertTitle: t('alert.confirmBlockFlow'),
      sweetAlertConfirm: () => {
        this.hideAlert();
        let azione = {
          id_azione: 9,
          id_documento,
        };
        this.props.dispatch(
          documentiActions.bloccaProcesso(azione, undefined, undefined, user.ruoli)
        );
      },
    });
  }

  closeSollecitoModal() {
    this.setState({
      sollecitoModal: null,
    });
  }

  closeNoteModal() {
    this.setState({
      noteModal: null,
    });
  }

  handleAddNota(e) {
    const { user } = this.props;

    let nota = {
      descrizione: e,
      id_utente: user.id_utente,
      id_documento: this.getFormFieldValue('id'),
    };

    this.props.dispatch(documentiActions.addNota(nota));

    this.setState({
      noteModal: null,
    });
  }

  handleRemoveNota(id_documento, id_nota) {
    this.props.dispatch(
      documentiActions.deleteNota({
        id_documento: id_documento,
        id_nota: id_nota,
      })
    );
  }

  handleClose() {
    const { t, raccoglitori, documenti, posizionamentoFirma } = this.props;

    const { riunioneChanged } = this.state;
    const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } =
      posizionamentoFirma?.dimensions ?? {};
    const tags = posizionamentoFirma?.tags ?? [];
    const tagsFetched = posizionamentoFirma?.tagsFetched;

    let firmatariEsterni = getListaFirmatariEsterni(raccoglitori?.current?.percorsi);
    let firmatariInterni = getListaFirmatariInterni(raccoglitori?.current?.percorsi);

    if (documenti?.current && tagsFetched && !documenti.current.from_import) {
      let tagsProps = [];
      let tagsState = [];

      //create shallow copy of state and props
      tags.forEach((tag) => {
        let coords = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
          x: tag.left,
          y: tag.top,
        });
        const coord = {
          coordinata_x: parseFloat(coords.x).toFixed(4),
          coordinata_y: parseFloat(coords.y).toFixed(4),
          pagina: tag.pagina,
        };

        if (tag.id_contatto) coord.id_contatto = tag.id_contatto;
        else if (tag.id_utente) coord.id_utente = tag.id_utente;
        else if (tag.id_uo) coord.id_uo = tag.id_uo;
        tagsState.push(coord);
      });

      documenti.current.coordinate.forEach((coordinata) => {
        const coord = {
          coordinata_x: parseFloat(coordinata.coordinata_x).toFixed(4),
          coordinata_y: parseFloat(coordinata.coordinata_y).toFixed(4),
          pagina: coordinata.pagina,
        };

        if (coordinata.id_contatto) coord.id_contatto = coordinata.id_contatto;
        else if (coordinata.id_utente) coord.id_utente = coordinata.id_utente;
        else if (coordinata.id_uo) coord.id_uo = coordinata.id_uo;

        tagsProps.push(coord);
      });

      const areThereExternals = firmatariEsterni?.length > 0;
      const tag_posizionato_est = areThereExternals
        ? areExternalsPositioned(tagsProps, firmatariEsterni)
        : null;
      const tag_posizionato_int = areInternalsPositioned(
        tagsProps,
        firmatariInterni,
        areThereExternals
      );

      if ((tagsFetched && !areArraysOfObjectsEqual(tagsProps, tagsState)) || riunioneChanged) {
        this.setState({
          closeInfo: false,
          confirm: (
            <SweetAlert
              info
              showCancel
              confirmBtnText={t('misc.yes')}
              confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
              cancelBtnText={t('misc.no')}
              cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
              title={t('document.noSaveUpdate')}
              onConfirm={(e) => {
                this.hideConfirm();
                if (tag_posizionato_est === false || tag_posizionato_int === false)
                  this.props.enqueueSnackbar(t('document.noSignPosition'), {
                    variant: 'warning',
                  });

                this.close();
              }}
              onCancel={() => {
                this.hideConfirm();
              }}
            >
              <p style={{ textAlign: 'center', margin: '0 1em' }}>{t('misc.closeConfirm')}</p>
            </SweetAlert>
          ),
        });
      } else {
        if (
          documenti.current.id_stato_documento === 1 &&
          (tag_posizionato_est === false || tag_posizionato_int === false)
        )
          this.setState({
            signatureAlert: `${t('document.noSignPosition')}`,
          });
        else this.close();
      }
    } else this.close();
  }

  hideSignatureAlert() {
    this.setState({ signatureAlert: false });
  }

  hideConfirm() {
    this.setState({
      confirm: undefined,
    });
  }

  close() {
    const { user } = this.props;
    this.navPills.current.setState({ init: 0 });
    this.setState(initialState);
    this.props.dispatch(documentiActions.resetDocument());
    this.props.dispatch(posFirmaActions.resetPosizionamentoFirma());
    //this.props.dispatch(documentiActions.getAll(undefined, user.ruoli));
    this.props.dispatch(documentiActions.getTaskList());
    this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
    this.props.onClose();
  }

  handleCloseAlert() {
    this.props.dispatch(alertActions.clear());
  }

  handleSelect = ({ target: { value, name } }) => {
    const { documenti } = this.props;
    const { current } = documenti;
    if (typeof value === 'undefined') value = '-1';

    if (name === 'id_raccoglitore' && value !== '-1') {
      this.props.dispatch(raccoglitoriActions.getRaccoglitoreById(value));
      if (documenti?.current?.id_tipo_documento) {
        this.props.dispatch(
          documentiActions.getDefaultCoordinate(documenti.current.id_tipo_documento)
        );
        if (current?.id_documento)
          setTimeout(() => {
            console.log('coordinate default impostate ' + documenti?.default_coord);
            this.handleSubmit(true);
          }, 1500);
      }
    }

    const wState = { [name]: value };

    if (name === 'id_tipo_documento') {
      this.props.dispatch(documentiActions.getDefaultCoordinate(value));
      if (current?.id_documento)
        setTimeout(() => {
          console.log('coordinate default impostate ' + documenti?.default_coord);
          this.handleSubmit(true);
        }, 1500);
    }

    this.setState(wState);
  };

  change(event, stateName, type, stateNameEqualTo) {
    const { value, checked } = event.target;

    const checkedStateNames = [
      'flag_protocollo',
      'qr_code_enable',
      'repertorio',
      'flag_protocollo_uffici',
    ];

    const wState = {};
    if (!FormValidationHelper.validateChar(value)) {
      wState[stateName + 'State'] = 'error';
      this.setState(wState);
      return;
    }
    wState[stateName] = checkedStateNames.includes(stateName) ? checked : value;

    if (stateName === 'descrizione') wState['oggetto_protocollo'] = value;

    if (stateName === 'flag_protocollo')
      this.setState({ repertorio: false, flag_protocollo_uffici: false });
    else if (stateName === 'repertorio')
      this.setState({ flag_protocollo: false, flag_protocollo_uffici: false });
    else if (stateName === 'flag_protocollo_uffici')
      this.setState({ repertorio: false, flag_protocollo: false });

    wState[stateName + 'State'] = FormValidationHelper.validateField(type, value, stateNameEqualTo);

    this.setState(wState);
  }

  setRepertorio(value) {
    //Lettera, Altro --> no repertorio
    var idTipoNoRepertorio = [1, 2];
    if (value && value !== -1 && !idTipoNoRepertorio.includes(value))
      this.setState({
        repertorio: true,
        flag_protocollo: false,
        flag_protocollo_uffici: false,
      });
    else this.setState({ repertorio: false });
  }

  handleFileDownload = (uuid, documento) => {
    this.props.dispatch(documentiActions.download(uuid, documento));
  };

  handleClickDeleteDocumento(idDoc, uuid) {
    this.props.dispatch(documentiActions.deleteFile(idDoc, uuid));
  }

  handleClickSendProtocollo(idDoc, uuid, checked) {
    this.props.dispatch(documentiActions.updateSendProtocollo(idDoc, uuid, checked));
  }

  handleSubmit(changeType) {
    const { t, raccoglitori, documenti, posizionamentoFirma, user } = this.props;
    const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } =
      posizionamentoFirma?.dimensions ?? {};
    const tags = posizionamentoFirma?.tags ?? [];

    //let nomeState = FormValidationHelper.validateField("required", this.getFormFieldValue("nome"), 1);
    //Il nome lo valorizzo automaticamente con lo stesso valore della descrizione (Richiesta cliente di eliminare il campo)
    let nomeState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('descrizione'),
      1
    );
    let descrizioneState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('descrizione'),
      1
    );
    let oggetto_protocolloState = 'success';
    let id_uo_rif_interno_protocolloState = 'success';
    if (this.getFormFieldValue('flag_protocollo')) {
      oggetto_protocolloState = FormValidationHelper.validateField(
        'min-length',
        this.getFormFieldValue('oggetto_protocollo'),
        30
      );
      id_uo_rif_interno_protocolloState = FormValidationHelper.validateField(
        'required-select',
        this.getFormFieldValue('id_uo_rif_interno_protocollo')
      );
    }
    let id_uo_createdState = FormValidationHelper.validateField(
      'required-select',
      this.getFormFieldValue('id_uo_created')
    );
    let id_prioritaState = FormValidationHelper.validateField(
      'required-select',
      this.getFormFieldValue('id_priorita')
    );
    let id_confidenzialitaState = FormValidationHelper.validateField(
      'required-select',
      this.getFormFieldValue('id_confidenzialita')
    );
    let id_raccoglitoreState = FormValidationHelper.validateField(
      'required-select',
      this.getFormFieldValue('id_raccoglitore')
    );
    //let id_uo_richiedenteState = FormValidationHelper.validateField("required-select", this.getFormFieldValue("id_uo_richiedente"));
    let id_tipo_documentoState = FormValidationHelper.validateField(
      'required-select',
      this.getFormFieldValue('id_tipo_documento')
    );

    let wUpdateState = {
      nomeState: nomeState,
      descrizioneState: descrizioneState,
      oggetto_protocolloState: oggetto_protocolloState,
      activeTab: 0,
      id_uo_createdState: id_uo_createdState,
      id_prioritaState: id_prioritaState,
      id_confidenzialitaState: id_confidenzialitaState,
      id_raccoglitoreState: id_raccoglitoreState,
      id_tipo_documentoState: id_tipo_documentoState,
      id_uo_rif_interno_protocolloState: id_uo_rif_interno_protocolloState,
    };

    if (
      nomeState === 'error' ||
      descrizioneState === 'error' ||
      id_uo_createdState === 'error' ||
      id_prioritaState === 'error' ||
      id_raccoglitoreState === 'error' ||
      id_tipo_documentoState === 'error'
    ) {
      this.setState(wUpdateState);
      this.props.dispatch(alertActions.error('compilationErrorInDocData'));
      return;
    }

    if (oggetto_protocolloState === 'error') {
      wUpdateState['activeTab'] = 1;
      this.setState(wUpdateState);
      this.props.dispatch(alertActions.error('compilationErrorProtData30'));
      return;
    }

    if (id_uo_rif_interno_protocolloState === 'error') {
      wUpdateState['activeTab'] = 1;
      this.setState(wUpdateState);
      this.props.dispatch(alertActions.error('compilationErrorProtData'));
      return;
    }

    this.setState(wUpdateState);

    this.setState({
      closeAlert: false,
      submitted: true,
    });

    let idStatoDocumento = this.getFormFieldValue('id_stato_documento');
    let idDocumento = this.getFormFieldValue('id');
    let flagProtocollo = this.getFormFieldValue('flag_protocollo');
    let qr_code_enable = this.getFormFieldValue('qr_code_enable');
    let repertorio = this.getFormFieldValue('repertorio');
    let flag_protocollo_uffici = this.getFormFieldValue('flag_protocollo_uffici');
    let id_uo_rif_interno_protocollo = this.getFormFieldValue('id_uo_rif_interno_protocollo');
    let id_uo_richiedente = this.getFormFieldValue('id_uo_richiedente');
    let id_uo_created = this.getFormFieldValue('id_uo_created');
    let utente_uo_richiedente = this.getFormFieldValue('utente_uo_richiedente');
    let oggetto_protocollo = this.getFormFieldValue('oggetto_protocollo');
    let impegno_spesa = this.getFormFieldValue('impegno_spesa');

    let firmatariEsterni = getListaFirmatariEsterni(raccoglitori?.current?.percorsi);
    let firmatariInterni = getListaFirmatariInterni(raccoglitori?.current?.percorsi);
    let coordinate = JSON.parse(JSON.stringify(tags));

    if (documenti?.default_coord) {
      console.log('######### handleSubmit salvo coordinate di default');
      coordinate = this.getDefaultCoordinate(
        documenti.default_coord,
        raccoglitori.current.percorsi,
        idDocumento,
        user
      );
    } else if (coordinate && coordinate.length > 0) {
      console.log('######### handleSubmit salvo coordinate da posizionamento');
      coordinate = this.getCoordinateFromTag(
        coordinate,
        CANVAS_WIDTH,
        CANVAS_HEIGHT,
        PDF_WIDTH,
        PDF_HEIGHT,
        idDocumento,
        user
      );
    } else coordinate = documenti.current.coordinate;

    let joinDocMeeting = undefined;

    const areThereExternals = firmatariEsterni?.length > 0;
    const tag_posizionato_est = areThereExternals
      ? areExternalsPositioned(coordinate, firmatariEsterni)
      : null;
    const tag_posizionato_int = areInternalsPositioned(
      coordinate,
      firmatariInterni,
      areThereExternals
    );

    console.log('############### coordinate impostate: ' + JSON.stringify(coordinate));
    console.log('############### lunghezza array: ' + coordinate.length);
    console.log('############### valore tag_posizionato_int: ' + tag_posizionato_int);
    if (!coordinate || coordinate.length < 2 || !tag_posizionato_int) {
      this.props.dispatch(alertActions.error('compilationErrorProtDataSign'));
      return;
    }
    let documento = {
      id_documento: idDocumento === '' ? undefined : idDocumento,
      nome: this.getFormFieldValue('descrizione'),
      descrizione: this.getFormFieldValue('descrizione'),
      id_raccoglitore: this.getFormFieldValue('id_raccoglitore'),
      id_tipo_documento: this.getFormFieldValue('id_tipo_documento'),
      priorita: this.getFormFieldValue('id_priorita'),
      confidenzialita: this.getFormFieldValue('id_confidenzialita'),
      id_stato_documento: idStatoDocumento === '' ? '1' : idStatoDocumento,
      flag_protocollo: flagProtocollo === '' ? false : flagProtocollo,
      qr_code_enable: qr_code_enable === '' ? false : qr_code_enable,
      repertorio: repertorio === '' ? false : repertorio,
      flag_protocollo_uffici: flag_protocollo_uffici === '' ? false : flag_protocollo_uffici,
      id_uo_rif_interno_protocollo:
        id_uo_rif_interno_protocollo !== '' ? id_uo_rif_interno_protocollo : undefined,
      //"uo_rif_interno_protocollo": (uo_rif_interno_protocollo !== "") ? uo_rif_interno_protocollo : undefined,
      id_uo_created: id_uo_created !== '' ? id_uo_created : undefined,
      id_uo_richiedente: id_uo_richiedente !== '' ? id_uo_richiedente : undefined,
      utente_uo_richiedente: utente_uo_richiedente !== '' ? utente_uo_richiedente : undefined,
      oggetto_protocollo,
      impegno_spesa: impegno_spesa ? impegno_spesa : null,
      tag_posizionato_est,
      tag_posizionato_int,
    };

    let { abilitaAnnulla } = this.state;
    if (this.state.associazioneDocRiunione) {
      joinDocMeeting = this.state.associazioneDocRiunione;
      joinDocMeeting['id_utente_ult_modif'] = user.id_utente;
      joinDocMeeting['data_ult_modif'] = moment(new Date()).format();
      joinDocMeeting['data_creazione'] = moment(new Date()).format();
      documento['associazioneDocRiunione'] = joinDocMeeting;
      abilitaAnnulla = false;
    } else if (this.state.riunioneChanged) {
      abilitaAnnulla = false;
    } else if (documenti?.current?.argomentoAssociato) {
      documento['associazioneDocRiunione'] = documenti.current.argomentoAssociato;
    }

    this.setState({
      riunioneChanged: false,
      abilitaAnnulla: abilitaAnnulla,
      closeAlert: false,
      submitted: true,
    });

    this.props.dispatch(documentiActions.save(documento, coordinate, user.ruoli, changeType));
  }

  getCoordinateFromTag(
    coordinate,
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
    PDF_WIDTH,
    PDF_HEIGHT,
    idDocumento,
    user
  ) {
    coordinate.forEach((tag) => {
      const pdfCoord = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
        x: tag.left,
        y: tag.top,
      });
      tag.coordinata_x = pdfCoord.x;
      tag.coordinata_y = pdfCoord.y;

      tag.id_documento = idDocumento;
      tag.utente_ult_modif = user.nome + ' ' + user.cognome;
      tag.larghezza_rettangolo = 145;
      tag.altezza_rettangolo = 38;

      delete tag.id;
      delete tag.tipo;
      delete tag.firmatario;
      delete tag.id_coordinata;
      delete tag.left;
      delete tag.top;
    });

    return coordinate;
  }

  getDefaultCoordinate(defaultCoordinates, percorsi, id_documento, user) {
    let result = [];
    for (let i = 0; i < percorsi.length; i++) {
      let tag = {
        id_documento,
        utente_ult_modif: user.nome + ' ' + user.cognome,
        larghezza_rettangolo: 145,
        altezza_rettangolo: 38,
        id_utente: percorsi[i].id_utente_owner,
        id_contatto: percorsi[i].id_contatto_owner,
      };
      const pdfCoord = this.getCoordinateByUser(defaultCoordinates, percorsi[i]);

      if (pdfCoord) {
        tag.pagina = pdfCoord.pagina;
        tag.coordinata_x = pdfCoord.x;
        tag.coordinata_y = pdfCoord.y;

        result.push(tag);
      }
    }

    return result;
  }

  getCoordinateByUser(defaultCoordinates, percorso) {
    let result;
    if (percorso.id_utente_owner || percorso.id_contatto_owner)
      for (let i = 0; i < defaultCoordinates.length; i++) {
        if (defaultCoordinates[i].ordinamento === percorso.ordinamento)
          result = {
            x: defaultCoordinates[i].coordinata_x,
            y: defaultCoordinates[i].coordinata_y,
            pagina: defaultCoordinates[i].pagina,
          };
      }
    return result;
  }

  handleSubmitAssociated() {
    const { documenti, user } = this.props;
    let joinDocMeeting = undefined;
    let idDocumento = this.getFormFieldValue('id');
    let documento = {
      id_documento: idDocumento,
    };
    let abilitaAnnulla = this.state.abilitaAnnulla;
    if (this.state.associazioneDocRiunione) {
      joinDocMeeting = this.state.associazioneDocRiunione;
      joinDocMeeting['id_utente_ult_modif'] = user.id_utente;
      joinDocMeeting['data_ult_modif'] = moment(new Date()).format();
      joinDocMeeting['data_creazione'] = moment(new Date()).format();
      documento['associazioneDocRiunione'] = joinDocMeeting;
      abilitaAnnulla = false;
    } else if (this.state.riunioneChanged) {
      abilitaAnnulla = false;
    } else if (documenti?.current?.argomentoAssociato) {
      documento['associazioneDocRiunione'] = documenti.current.argomentoAssociato;
    }

    this.setState({
      riunioneChanged: false,
      abilitaAnnulla: abilitaAnnulla,
      closeAlert: false,
      submitted: true,
    });

    const { coordinate } = documenti.current;

    this.props.dispatch(documentiActions.save(documento, coordinate, user.ruoli));
  }

  handleUpdateMetadata() {
    let impegno_spesa = this.getFormFieldValue('impegno_spesa');
    let idDocumento = this.getFormFieldValue('id');
    const { t } = this.props;
    if (impegno_spesa) {
      const metadata = { impegno_spesa };

      this.props.dispatch(documentiActions.updateMetadata(idDocumento, metadata, false));
    } else {
      this.props.dispatch(alertActions.error('compileSpendingCommitment'));
      return;
    }
  }

  handleSelectedUOInternoProtocollo = (selectedValue, fieldName) => {
    if (typeof selectedValue.id_unita_organizzativa === 'undefined') {
      selectedValue.id_unita_organizzativa = '-1';
    }

    this.setState({
      selectUtentiDisabled: false,
      id_uo_rif_interno_protocollo: selectedValue.id_unita_organizzativa,
      uo_rif_interno_protocollo: selectedValue.path,
      selectedUtente: undefined,
    });
  };

  handleSelectedUORichiedente = (selectedValue, fieldName) => {
    if (typeof selectedValue.id_unita_organizzativa === 'undefined') {
      selectedValue.id_unita_organizzativa = null;
    }

    this.setState({
      selectUtentiDisabled: false,
      id_uo_richiedente: selectedValue.id_unita_organizzativa,
      utente_uo_richiedente: selectedValue.path,
      selectedUtente: undefined,
    });
  };

  handleSelectedUOOrigine = (selectedValue, fieldName) => {
    this.setState({
      selectUtentiDisabled: false,
      id_uo_created: selectedValue.id_unita_organizzativa,
      id_uo_rif_interno_protocollo: selectedValue.id_unita_organizzativa,
      uo_rif_interno_protocollo: selectedValue.path,
      utente_uo_origine: selectedValue.path,
      selectedUtente: undefined,
    });
  };

  handleSelectedUtente = (selectedValue) => {
    this.setState({ selectedUtente: selectedValue });
  };

  //CAMUSI inizio 23072020
  openDettaglioPercorsoModalForm() {
    const { documenti } = this.props;

    this.setState({
      showDettaglioPercorsoModalForm: true,
    });

    let raccoglitoreCorrente = this.state.id_raccoglitore
      ? this.state.id_raccoglitore
      : documenti.current && documenti.current.id_raccoglitore
      ? documenti.current.id_raccoglitore
      : undefined;
    if (raccoglitoreCorrente)
      this.props.dispatch(raccoglitoriActions.getRaccoglitoreById(raccoglitoreCorrente));
  }

  handleCloseDettaglioPercorsoModalForm() {
    this.setState({
      showDettaglioPercorsoModalForm: false,
    });
  }
  //CAMUSI fine 23072020

  basicAlert(pMessage) {
    const { t } = this.props;
    this.setState({
      alertMessage: (
        <SweetAlert danger title={t('misc.warning')}>
          {pMessage}
        </SweetAlert>
      ),
      submitted: false,
    });
  }

  getNoteList(id_documento, note) {
    const { t, user } = this.props;

    let wNoteList =
      typeof note === 'undefined'
        ? []
        : note.map((prop, key) => {
            let wButtonRemove = '';

            if (prop.id_utente === user.id_utente) {
              wButtonRemove = (
                <Tooltip id="tooltip-top" title={t('note.deleteComment')} placement="bottom">
                  <Button
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      margin: '0px',
                      padding: '0px',
                    }}
                    justIcon
                    round
                    simple
                    onClick={() => this.handleRemoveNota(id_documento, prop.id_note_documento)}
                    color="danger"
                  >
                    <Close />
                  </Button>
                </Tooltip>
              );
            }

            return {
              inverted: true,
              badgeColor: 'success',
              badgeIcon: Extension,
              title: (
                <div>
                  {prop.utente ? prop.utente : prop.contatto}
                  {wButtonRemove}
                </div>
              ),
              titleColor: 'success',
              body: <p>{prop.descrizione}</p>,
              footerTitle: (
                <Moment parse="YYYY-MM-DDTHH:mm:ss.msZ" format="DD/MM/YYYY HH:mm:ss">
                  {prop.data_creazione}
                </Moment>
              ),
            };
          });

    if (Authorize.checkAuthorizeFunc('FNCINT')) {
      wNoteList.push({
        inverted: true,
        badgeColor: 'info',
        badgeIcon: Add,
        title: '',
        titleColor: 'info',
        body: (
          <div>
            <Button color="info" onClick={this.openNoteModal.bind(this)}>
              <h5>{t('note.addComment')}</h5>
            </Button>
          </div>
        ),
        footerTitle: '',
      });
    }

    return wNoteList;
  }

  handleSelezioneRiunione(value, changed, enable) {
    this.setState({
      associazioneDocRiunione: value,
      riunioneChanged: changed,
      abilitaAnnulla: enable,
    });
  }

  handleChangeRiunione(enable, change) {
    this.setState({ abilitaAnnulla: enable, riunioneChanged: change });
  }

  getFormFieldValue(pFieldName) {
    const { t, documenti, user } = this.props;
    let wReturn;

    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    } else if (typeof documenti.current !== 'undefined') {
      wReturn = ModelViewHelper.document_ModelToView(documenti.current, t);

      if (pFieldName === 'note') {
        return this.getNoteList(wReturn.id, wReturn[pFieldName]);
      }

      return wReturn[pFieldName];
    }

    if (['note', 'flusso_operativo'].includes(pFieldName)) {
      return [];
    }

    if (
      this.state.id_stato_documento === Constants.documento.stato.inBozza ||
      this.state.id_stato_documento === ''
    ) {
      if (pFieldName === 'id_uo_created' || pFieldName === 'id_uo_rif_interno_protocollo') {
        return this.state[pFieldName];
      }
    } else if (
      this.state.id_stato_documento !== Constants.documento.stato.inBozza &&
      this.state.id_stato_documento !== ''
    ) {
      if (pFieldName === 'id_uo_created' || pFieldName === 'id_uo_rif_interno_protocollo') {
        if (typeof user !== 'undefined' && typeof user.unita_organizzative) {
          if (user.unita_organizzative.length > 1)
            return user.unita_organizzative[user.unita_organizzative.length - 2].id_uo_group;
          else if (user.unita_organizzative.length === 1)
            return user.unita_organizzative[user.unita_organizzative.length - 1].id_uo_group;
        }
      }
    }

    return '';
  }

  static getDerivedStateFromProps = (props, state) => {
    const { documenti } = props;

    //PDF FILE REGEX
    let fileUrlReg = '[^/]+$';
    let uuid = state?.file?.url.match(fileUrlReg)[0];

    //if main pdf has changed reset tags
    if (documenti?.current?.files.length > 0 && documenti?.current?.files[0].uuid !== uuid) {
      state.file = {
        url: wMount + '/api/v1/documents/download/' + documenti.current.files[0].uuid,
        httpHeaders: { ...authHeader() },
      };
    }
    return { ...state };
  };

  onFileChange = (file, idDocumento, tipo_file) => {
    if (idDocumento) this.props.dispatch(documentiActions.getDocumentoById(idDocumento));
    this.setState({
      file,
      tipo_file,
    });
  };

  handleClickTab(active) {
    if (active === 5) this.props.dispatch(documentiActions.getSessionId());
  }

  handleDeleteFlusso(id_documento, idStepFlusso) {
    const { t } = this.props;
    this.setState({
      closeInfo: false,
      sweetAlert: (
        <SweetAlert
          info
          showCancel
          confirmBtnText="Ok"
          confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
          cancelBtnText={t('action.cancel')}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
          title={t('flow.confirmDelete')}
          onConfirm={(e) => {
            this.hideAlert();
            this.props.dispatch(documentiActions.deleteStepById(id_documento, idStepFlusso));
          }}
          onCancel={() => {
            this.hideAlert();
          }}
        />
      ),
    });
  }

  hideAlert() {
    this.setState({
      sweetAlert: undefined,
      closeInfo: true,
      showalert: false,
    });
  }

  verificaPresenzaPercorso(raccoglitori, raccoglitoreCorrente) {
    let esistente = false;
    if (raccoglitori && raccoglitori.items) {
      for (let i = 0; i < raccoglitori.items.length; i++) {
        if (raccoglitori.items[i].id_raccoglitore === raccoglitoreCorrente.value) {
          esistente = true;
        }
      }
    }
    return esistente;
  }

  handleClickOpenRaccoglitore = (idRaccoglitore) => {
    this.setState({
      showRaccoglitore: true,
    });
    this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
  };

  handleClickCloseRaccoglitore = () => {
    const { current } = this.props.raccoglitori;
    this.setState({
      showRaccoglitore: false,
      id_raccoglitore: current?.id_raccoglitore,
    });
  };

  handleSubmitRaccoglitore = () => {
    const { current } = this.props.raccoglitore;
    this.setState({ id_raccoglitore: current?.id_raccoglitore });
  };

  render() {
    const { t, classes, documenti, id, utils, raccoglitori, alert, user, uuid, tema } = this.props;
    const {
      noteModal,
      activeTab,
      sollecitoModal,
      signatureAlert,
      confirm,
      file,
      id_tipo_documentoState,
    } = this.state;
    const {
      idDocumentoState,
      sweetAlert,
      showalert,
      sweetAlertConfirm,
      sweetAlertTitle,
      showRaccoglitore,
    } = this.state;
    let cardColor = tema.theme_color;

    let posFirmaDisabled = true;
    let listaFirmatariEsterni = [];
    let listaFirmatariInterni = [];
    let editableMeeting = false;

    if (raccoglitori?.current?.percorsi?.length > 0) {
      let listExtTmp = getListaFirmatariEsterni(raccoglitori.current.percorsi);
      let listIntTmp = getListaFirmatariInterni(raccoglitori.current.percorsi);
      let lengthInterniVisto = listIntTmp.filter((f) => f.flag_firma_vista === 'V').length;

      //disabilita tab Positionamento Firma se vi son solo firmatari interni con V flag
      if (
        documenti?.current?.files.length > 0 &&
        (listExtTmp.length > 0 ||
          (listIntTmp.length > 0 && listIntTmp.length !== lengthInterniVisto))
      ) {
        posFirmaDisabled = false;
      }

      listaFirmatariEsterni = listExtTmp.map((prop) => ({
        value: capitalizeFirstLetter(prop.contatto_owner),
        label: capitalizeFirstLetter(prop.contatto_owner),
        tipo_firma: prop.tipo_firma,
        id: prop.id_contatto_owner,
        tipo_firmatario: 'Esterno',
      }));

      listaFirmatariInterni = listIntTmp.map((prop) => ({
        value: capitalizeFirstLetter(prop.utente_owner ?? prop.uo_owner),
        label: capitalizeFirstLetter(prop.utente_owner ?? prop.uo_owner),
        id: prop.id_utente_owner ?? prop.id_uo_owner,
        tipo_firmatario: prop.id_utente_owner ? 'Interno' : 'InternoUO',
      }));
    }

    let fileUrl;
    if (uuid) {
      fileUrl = {
        url: wMount + '/api/v1/documents/download/' + uuid,
        httpHeaders: { ...authHeader() },
      };
    }

    let alertMessage = '';
    if (alert && typeof alert.message !== 'undefined') {
      const alertTranslation = `alert.${alert.message}`;
      const message = t(alertTranslation);
      if (alert.type === 'alert-success') {
        alertMessage = (
          <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
            {message}
          </SweetAlert>
        );
      } else if (alert.type === 'alert-danger') {
        alertMessage = (
          <SweetAlert
            danger
            title={t('alert.warning')}
            onConfirm={() => {
              this.handleCloseAlert();
              if (alert.message === 'sessionExpired') {
                this.props.dispatch(userActions.logout());
              }
            }}
          >
            {message}
          </SweetAlert>
        );
      }
    }

    let loading;
    if (documenti?.loading) {
      loading = (
        <div css={overlay}>
          <div css={override}>
            <ClipLoader sizeUnit={'px'} size={80} color={'#3a783d'} loading={documenti.loading} />
          </div>
        </div>
      );
    }

    let title;

    if (typeof id === 'undefined') title = t('document.newDoc');
    else if (documenti?.current && !documenti.loading) {
      title =
        this.getFormFieldValue('descrizione') + ' - (id: ' + this.getFormFieldValue('id') + ')';
    }

    let optionTipoDocumento = [];
    if (typeof utils.tipologia_documento !== 'undefined') {
      optionTipoDocumento = utils.tipologia_documento.map((tipo_documento) => {
        const label = `document.documentType.${tipo_documento.desc_tipo_documento}`;

        return {
          value: tipo_documento.id_tipo_documento,
          label: t(label),
        };
      });
    }

    let optionConfidenzialita = [];
    for (let k in Constants.documento.confidenzialita) {
      optionConfidenzialita.push({
        value: k,
        label: t(`document.confType.${Constants.documento.confidenzialita[k]}`),
      });
    }

    let optionPriorita = [];
    for (let k in Constants.documento.priorita) {
      optionPriorita.push({
        value: k,
        label: t(`document.priorityType.${Constants.documento.priorita[k]}`),
      });
    }

    let optionUO_Utente = [];

    if (
      this.getFormFieldValue('id_stato_documento') === Constants.documento.stato.inBozza ||
      this.getFormFieldValue('id_stato_documento') === ''
    ) {
      if (typeof user !== 'undefined' && typeof user.unita_organizzative !== 'undefined') {
        optionUO_Utente = user.unita_organizzative.map((prop) => ({
          value: prop.id_uo_group,
          label: prop.nome,
        }));
      }
    } else if (
      this.getFormFieldValue('id_stato_documento') !== Constants.documento.stato.inBozza &&
      this.getFormFieldValue('id_stato_documento') !== ''
    ) {
      optionUO_Utente = utils.unita_organizzative.map((prop, key) => {
        return {
          value: prop.id_uo_group,
          label: prop.path,
          dismessa: prop.dismessa,
        };
      });
    }

    let optionRaccoglitoriSearch = [];
    if (typeof raccoglitori.items !== 'undefined') {
      optionRaccoglitoriSearch = raccoglitori.items.map((prop, key) => {
        let option = ModelViewHelper.raccoglitori_ModelToView(prop);
        return {
          value: option.id,
          label: option.nome,
          valido: option.flag_validita,
        };
      });
    }
    if (documenti?.current) {
      let raccoglitoreCorrente = {
        value: documenti.current.id_raccoglitore,
        label: documenti.current.nome_raccoglitore,
        valido: false,
      };
      let idRaccCoincidenti = this.verificaPresenzaPercorso(raccoglitori, raccoglitoreCorrente);
      if (!idRaccCoincidenti) optionRaccoglitoriSearch.push(raccoglitoreCorrente);
    }

    let disabled_impegno_spesa = false;
    if (!Authorize.checkAuthorize(ServiceMatrix.ModifyMetadata)) {
      disabled_impegno_spesa = true;
    }

    let disabled_protocollo = false;
    if (
      this.getFormFieldValue('id_stato_documento') !== Constants.documento.stato.inBozza &&
      this.getFormFieldValue('id_stato_documento') !== ''
    ) {
      disabled_protocollo = true;
    } else {
      if (this.getFormFieldValue('flag_protocollo')) {
        disabled_protocollo = false;
      } else {
        disabled_protocollo = true;
      }
    }

    let wUploadSection = '';
    let fileListSection = '';
    let downloadFile = [];
    let wButtonActionDisabled = true;

    if (documenti?.current?.id_stato_documento === Constants.documento.stato.inBozza) {
      wButtonActionDisabled = false;
    }

    if (documenti?.current?.files.length > 0) {
      if (Authorize.checkAuthorizeFunc('FNCIMS')) {
        disabled_impegno_spesa = false;
      }

      var this_ = this;
      var disableDelete = false;
      documenti.current.files.forEach(function (file, index, array) {
        if (array.length > 1 && file.tipo_file === 'P') disableDelete = true;
        else if (array.length > 1 && file.tipo_file === 'A') disableDelete = false;
        else if (array.length === 1 && file.tipo_file === 'P') disableDelete = false;

        if (wButtonActionDisabled) disableDelete = true;

        let tipo_file = file.tipo_file === 'P' ? t('document.mainFile') : t('document.attachment');
        let fileAction = (
          <Tooltip
            id="tooltip-top"
            title={t('document.fileDelete')}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => this_.handleClickDeleteDocumento(file.id_documento, file.uuid)}
              color="danger"
              disabled={disableDelete}
              className={classes.tcSignTableButton}
            >
              <Close />
            </Button>
          </Tooltip>
        );

        var disableCheck = {};
        if (wButtonActionDisabled) disableCheck = { disabled: true };

        let chooseProtocollo = '';
        if (file.tipo_file !== 'P')
          chooseProtocollo = (
            <Checkbox
              {...disableCheck}
              tabIndex={-1}
              onChange={(e) =>
                this_.handleClickSendProtocollo(file.id_documento, file.uuid, e.target.checked)
              }
              checked={file.send_protocollo}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
              }}
            />
          );

        let row = [
          <Button
            color="primary"
            simple
            className={classes.marginRight + ' ' + classes.tcSignTableButton}
            onClick={() => this_.handleFileDownload(file.uuid, documenti.current)}
          >
            {file.file_name}
          </Button>,
          tipo_file,
          chooseProtocollo,
          fileAction,
        ];
        downloadFile.push(row);
      });

      fileListSection = (
        <Table
          tableHeaderColor="warning"
          tableHead={[
            t('document.fileName'),
            t('document.fileType'),
            t('document.fileSendProt'),
            t('document.fileAction'),
          ]}
          tableData={downloadFile}
          customCellClasses={[classes.left + ' ' + classes.tcSignTableCell, classes.center]}
          customRowClasses={classes.tcSignTableRow}
          customHeadRowClasses={classes.tcSignTableHeadRow}
          customClassesForCells={[0, 1, 2, 3]}
          customHeadCellClasses={[
            classes.tcSignTableHead,
            classes.center + ', ' + classes.tcSignTableHead,
            classes.center + ', ' + classes.tcSignTableHead,
            classes.center + ', ' + classes.tcSignTableHead,
          ]}
          customHeadClassesForCells={[0, 1, 2, 3]}
        />
      );
    } else disabled_impegno_spesa = true;

    if (this.getFormFieldValue('id_stato_documento') !== '' && !wButtonActionDisabled) {
      wUploadSection = (
        <DropzoneComponent
          config={this.componentConfig}
          eventHandlers={this.eventHandlers}
          djsConfig={this.djsConfig}
          dictDefaultMessage={t('document.clickOrDrag')}
          disablePreview={true}
        ></DropzoneComponent>
      );
    }

    let showButtonSalva = Authorize.checkAuthorizeFunc('FNCCMD');
    let wButtonSalva = '',
      isAssociated = true;
    if (
      (this.getFormFieldValue('id_stato_documento') === '' ||
        this.getFormFieldValue('id_stato_documento') === Constants.documento.stato.inBozza) &&
      Authorize.checkAuthorizeFunc('FNCCMD')
    )
      isAssociated = false;

    wButtonSalva = (
      <Button
        color={cardColor}
        onClick={!isAssociated ? () => this.handleSubmit() : () => this.handleSubmitAssociated()}
        disabled={
          !id || (id && documenti.current && raccoglitori.current && !documenti.current.from_import)
            ? false
            : true
        }
      >
        <Save style={{ margin: '0px' }} /> {t('action.save')}
      </Button>
    );

    let wFormDisabled = false;
    if (
      this.getFormFieldValue('id_stato_documento') !== Constants.documento.stato.inBozza &&
      this.getFormFieldValue('id_stato_documento') !== ''
    ) {
      wFormDisabled = true;
    }

    //CAMUSI INIZIO 30072020
    let btnStepPercorso;

    if (
      (documenti?.current && this.state.id_raccoglitore === undefined) ||
      (this.state.id_raccoglitore !== undefined && this.state.id_raccoglitore !== -1)
    ) {
      btnStepPercorso = (
        <Tooltip
          id="tooltip-top"
          title={t('flow.listStep')}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => this.openDettaglioPercorsoModalForm()}
            color="success"
            className={classes.tcsign_percorso}
          >
            <Search />
          </Button>
        </Tooltip>
      );
    }

    let tooltipUnitaOrganizzatrice;
    let uoOrganizzatricePath;
    let uoDiDefaultInCreazione;

    if (utils?.unita_organizzative) {
      //-----------------------------------
      if (this.state.id_uo_created === undefined && documenti.current === undefined) {
        if (typeof user !== 'undefined' && typeof user.unita_organizzative) {
          if (user.unita_organizzative.length > 1)
            uoDiDefaultInCreazione =
              user.unita_organizzative[user.unita_organizzative.length - 2].id_uo_group;
          else if (user.unita_organizzative.length === 1)
            uoDiDefaultInCreazione =
              user.unita_organizzative[user.unita_organizzative.length - 1].id_uo_group;
        }
        for (let x = 0; x < utils.unita_organizzative.length; x++) {
          if (uoDiDefaultInCreazione === utils.unita_organizzative[x].id_uo_group) {
            uoOrganizzatricePath = utils.unita_organizzative[x].path;
            break;
          }
        }
      }
      //...................................
      if (this.state.id_uo_created !== undefined) {
        for (let y = 0; y < utils.unita_organizzative.length; y++) {
          if (this.state.id_uo_created === utils.unita_organizzative[y].id_uo_group) {
            uoOrganizzatricePath = utils.unita_organizzative[y].path;
            break;
          }
        }
      } else {
        if (documenti?.current) {
          for (let i = 0; i < utils.unita_organizzative.length; i++) {
            if (documenti.current.id_uo_created === utils.unita_organizzative[i].id_uo_group) {
              uoOrganizzatricePath = utils.unita_organizzative[i].path;
              break;
            }
          }
        }
      }
    }
    tooltipUnitaOrganizzatrice = (
      <FormControl
        fullWidth
        disabled={wFormDisabled}
        className={classes.selectFormControl + ' ' + classes.tcSignSelectControl}
      >
        <SelectTooltip
          success={this.state.id_uo_createdState === 'success'}
          error={this.state.id_uo_createdState === 'error'}
          label={t('document.labelOriginUO')}
          placeholder={t('document.labelSelectUO')}
          options={optionUO_Utente}
          isClearable={true}
          id={'id_uo_created'}
          isSearchable={true}
          value={this.getFormFieldValue('id_uo_created') || ''}
          onChange={this.handleSelect}
          disabled={wFormDisabled}
        />
      </FormControl>
    );

    if (utils?.unita_organizzative) {
      if (this.state.id_uo_richiedente !== undefined) {
        for (let z = 0; z < utils.unita_organizzative.length; z++) {
          if (this.state.id_uo_richiedente === utils.unita_organizzative[z].id_uo_group) {
            uoRichiedentePath = utils.unita_organizzative[z].path;
            break;
          }
        }
      } else {
        if (documenti?.current) {
          for (let i = 0; i < utils.unita_organizzative.length; i++) {
            if (documenti.current.id_uo_richiedente === utils.unita_organizzative[i].id_uo_group) {
              uoRichiedentePath = utils.unita_organizzative[i].path;
              break;
            }
          }
        }
      }
    }

    const show = Authorize.checkAuthorizeFunc('FNCCMP');

    let wFormRaccoglitore = showRaccoglitore && (
      <RaccoglitoreModalForm
        title={t('flow.new')}
        open={showRaccoglitore}
        keepMounted
        onClose={() => this.handleClickCloseRaccoglitore()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      />
    );

    return (
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        fullScreen
        onEntering={() => this.onFileChange(fileUrl, id, 'P')}
        onClose={this.close}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        {loading}
        {noteModal}
        {sollecitoModal}
        {alertMessage}
        {confirm}
        {sweetAlert}
        {wFormRaccoglitore}
        {showalert && (
          <SweetAlert
            info
            showCancel
            confirmBtnText="Ok"
            confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
            cancelBtnText="Annulla"
            cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
            title={sweetAlertTitle}
            onConfirm={sweetAlertConfirm}
            onCancel={() => this.hideAlert()}
          />
        )}
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <Card style={{ marginBottom: '0px' }}>
            <CardHeader color={cardColor} icon>
              <CardIcon color={cardColor}>
                <Folder />
              </CardIcon>
              <h4 className={classes.cardTitle} style={{ color: '#777' }}>
                {title}
              </h4>
            </CardHeader>
            <CardBody>
              <NavPills
                ref={this.navPills}
                init={activeTab}
                color={cardColor}
                onClick={(active) => this.handleClickTab(active)}
                tabs={[
                  {
                    tabButton: t('document.tabDocument'),
                    tabContent: (
                      <GridContainer style={{ width: '100%', margin: '0px' }}>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                success={idDocumentoState === 'success'}
                                error={idDocumentoState === 'error'}
                                labelText={t('document.labelIdDoc')}
                                id="idDocumento"
                                formControlProps={{ fullWidth: false }}
                                inputProps={{
                                  disabled: true,
                                  onChange: (event) =>
                                    this.change(event, 'idDocumento', 'idDocumento'),
                                  type: 'idDocumento',
                                  value: this.getFormFieldValue('id') || '',
                                }}
                                className={classes.tcSignInputStyle}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                labelText={t('document.labelDocStatus')}
                                id="statoDocumento"
                                formControlProps={{
                                  fullWidth: false,
                                }}
                                inputProps={{
                                  disabled: true,
                                  onChange: (event) =>
                                    this.change(event, 'statoDocumento', 'statoDocumento'),
                                  type: 'statoDocumento',
                                  value: this.getFormFieldValue('desc_stato_documento') || '',
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                labelText={t('document.labelCreatedBy')}
                                id="creato_da"
                                formControlProps={{ fullWidth: false }}
                                inputProps={{
                                  disabled: true,
                                  value: this.getFormFieldValue('utente_originatore') || '',
                                }}
                                className={classes.tcSignInputStyle}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                labelText={t('document.labelDateCreated')}
                                formControlProps={{ fullWidth: false }}
                                inputProps={{
                                  disabled: true,
                                  value: this.getFormFieldValue('data_creazione_str') || '',
                                }}
                                className={classes.tcSignInputStyle}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                success={this.state.descrizioneState === 'success'}
                                error={this.state.descrizioneState === 'error'}
                                labelText={t('document.labelSubject')}
                                id="descrizione"
                                formControlProps={{ fullWidth: true, required: true }}
                                inputProps={{
                                  disabled: wFormDisabled,
                                  onChange: (event) =>
                                    this.change(event, 'descrizione', 'required'),
                                  type: 'descrizione',
                                  value: this.getFormFieldValue('descrizione') || '',
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              {tooltipUnitaOrganizzatrice}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                fullWidth
                                disabled={wFormDisabled}
                                className={
                                  classes.selectFormControl + ' ' + classes.tcSignSelectControl
                                }
                              >
                                <SelectSearch
                                  success={id_tipo_documentoState === 'success'}
                                  error={id_tipo_documentoState === 'error'}
                                  label={t('document.labelDocType')}
                                  placeholder={t('document.labelSelDocType')}
                                  options={optionTipoDocumento}
                                  isClearable={true}
                                  id={'id_tipo_documento'}
                                  isSearchable={false}
                                  value={this.getFormFieldValue('id_tipo_documento') || ''}
                                  onChange={this.handleSelect}
                                  disabled={wFormDisabled}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                              <FormControl
                                fullWidth
                                disabled={wFormDisabled}
                                className={
                                  classes.selectFormControl + ' ' + classes.tcSignSelectControl
                                }
                              >
                                <SelectSearch
                                  success={this.state.id_raccoglitoreState === 'success'}
                                  error={this.state.id_raccoglitoreState === 'error'}
                                  label={t('document.labelPath')}
                                  placeholder={t('document.labelSelPath')}
                                  options={optionRaccoglitoriSearch}
                                  isClearable={true}
                                  id={'id_raccoglitore'}
                                  isSearchable={true}
                                  value={this.getFormFieldValue('id_raccoglitore') || ''}
                                  onChange={this.handleSelect}
                                  disabled={wFormDisabled}
                                  isOptionDisabled={(option) => option.valido === false}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={1} sm={1} md={1}>
                              <DettaglioPercorsoModalForm
                                open={this.state.showDettaglioPercorsoModalForm}
                                onClose={() => this.handleCloseDettaglioPercorsoModalForm()}
                              />
                              {btnStepPercorso}
                              {show && (
                                <Tooltip
                                  id="tooltip-top"
                                  title={t('action.newSignPath')}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => this.handleClickOpenRaccoglitore()}
                                    color="giallo"
                                    className={classes.tcsign_percorso}
                                  >
                                    <Folder />
                                  </Button>
                                </Tooltip>
                              )}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl
                                fullWidth
                                disabled={wFormDisabled}
                                className={
                                  classes.selectFormControl + ' ' + classes.tcSignSelectControl
                                }
                              >
                                <SelectSearch
                                  success={this.state.id_prioritaState === 'success'}
                                  error={this.state.id_prioritaState === 'error'}
                                  label={t('document.labelPriority')}
                                  placeholder={t('document.labelSelPriority')}
                                  options={optionPriorita}
                                  isClearable={true}
                                  id={'id_priorita'}
                                  isSearchable={false}
                                  value={this.getFormFieldValue('id_priorita') || ''}
                                  onChange={this.handleSelect}
                                  disabled={wFormDisabled}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        {fileListSection && (
                          <GridItem xs={12} sm={12} style={{ minHeight: '15vh' }}>
                            {fileListSection}
                          </GridItem>
                        )}
                        <GridItem xs={12} sm={12} style={{ minHeight: '15vh' }}>
                          {wUploadSection}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                          <span className={classes.tc_sign_required_div}>
                            <i>{t('document.labelFieldMandatory')}</i>
                          </span>
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: t('document.tabNote'),
                    tabContent: <Timeline simple stories={this.getFormFieldValue('note')} />,
                  },
                  {
                    tabButton: t('document.tabPath'),
                    tabContent: (
                      <GridContainer style={{ width: '100%' }} direction="column">
                        <GridItem xs={12} sm={12} md={12}>
                          <PercorsoFirma
                            readonly={this.props.readonly}
                            documento={documenti.current}
                            onDelete={(idDocumento, idStepFlusso) =>
                              this.handleDeleteFlusso(idDocumento, idStepFlusso)
                            }
                            onClickSollecito={(id_utente, id_uo, id_contatto) =>
                              this.openSollecitoModal(
                                documenti.current.id_documento,
                                id_utente,
                                id_uo,
                                id_contatto
                              )
                            }
                            onClickBlock={() =>
                              this.bloccaProcessoModal(documenti.current.id_documento)
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: t('document.tabSignPos'),
                    tabDisabled: posFirmaDisabled,
                    tabContent: (
                      <PosizionamentoFirma
                        file={file}
                        currentTab="Posizionamento firma"
                        listaFirmatariEsterni={listaFirmatariEsterni}
                        listaFirmatariInterni={listaFirmatariInterni}
                      />
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          {showButtonSalva && wButtonSalva}
          <Button
            color="info"
            onClick={() => this.handleClose()}
            disabled={!id || (id && documenti.current && raccoglitori.current) ? false : true}
          >
            <Close style={{ margin: '0px' }} /> {t('action.close')}
          </Button>
        </DialogActions>
        {signatureAlert && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Si"
            confirmBtnBsStyle="danger"
            cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
            cancelBtnText="No"
            title={t('misc.closeConfirm')}
            onConfirm={(e) => {
              this.hideSignatureAlert();
              this.close();
            }}
            onCancel={() => this.hideSignatureAlert()}
          >
            <p style={{ textAlign: 'justify', margin: '0 1em' }}>{signatureAlert}</p>
          </SweetAlert>
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    authentication,
    documenti,
    utils,
    raccoglitori,
    posizionamentoFirma,
    error,
    tema,
  } = state;
  const { user } = authentication ?? {};

  return {
    user,
    alert,
    documenti,
    utils,
    raccoglitori,
    posizionamentoFirma,
    error,
    tema,
  };
}

const connectedDocumentoModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(DocumentoModalForm);
export default withSnackbar(connectedDocumentoModalForm);
