import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import { withStyles, CircularProgress } from '@material-ui/core';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import { temaActions, documentiActions, alertActions } from '../../Actions';

const styles = () => ({
  center: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadStyle: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    borderRadius: '5px',
  },
});

class QRCode extends Component {
  state = {
    idDoc: null,
    username: '',
    password: '',
  };

  componentDidMount = () => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let idDoc = params.get('idDocumento');
    this.setState({ idDoc });

    this.props.dispatch(temaActions.getTemaByIdDoc(idDoc));
  };

  onChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  download = () => {
    const { idDoc, username, password } = this.state;
    this.props.dispatch(documentiActions.downloadFromQRCode(idDoc, username, password));
  };

  handleCloseAlert() {
    this.props.dispatch(alertActions.clear());
  }

  render() {
    const { t, username, password } = this.state;
    const { classes, temaColor, loading, alert } = this.props;

    return (
      <div className={classes.center} style={{ backgroundColor: 'f9f7f7' }}>
        {loading ? (
          <CircularProgress size={75} thickness={3} />
        ) : (
          <>
            <div style={{ width: '300px' }} className={classes.downloadStyle}>
              <CustomInput
                labelText="Username"
                id="username"
                formControlProps={{ fullWidth: false }}
                inputProps={{
                  onChange: this.onChange,
                  type: 'text',
                  value: username,
                }}
              />
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{ fullWidth: false }}
                inputProps={{
                  onChange: this.onChange,
                  type: 'password',
                  value: password,
                }}
              />
              <Button
                color={temaColor}
                size="sm"
                style={{ marginTop: '2em' }}
                onClick={this.download}
              >
                Download
              </Button>
            </div>
          </>
        )}
        {alert?.message && (
          <SweetAlert danger title={t('alert.warning')} onConfirm={() => this.handleCloseAlert()}>
            {alert.message}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert } = state;
  const { loading, tema } = state.tema;
  let temaColor = tema?.filter((tema) => tema.chiave === 'tcsign_color')[0].valore;

  return {
    loading,
    temaColor,
    alert,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(QRCode));
