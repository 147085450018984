import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import background from 'assets/img/notFound.jpg';

const styles = {
  ...regularFormsStyle,
};

class NotFoundPage extends React.Component {
  render() {
    const { t, classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.container} style={{ textAlign: 'center' }}>
          <img alt="Page not found" src={background} style={{ width: '50%' }} />
        </div>
      </div>
    );
  }
}

NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

const connectedNotFoundPage = connect(mapStateToProps)(
  withStyles(styles)(withRouter(NotFoundPage))
);
export default connectedNotFoundPage;
