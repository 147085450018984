import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

export const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const STYLES = {
  selectMenuItem: {
    ...customSelectStyle.selectMenuItem,
    '&:hover': {
      backgroundColor: '#eedc00',
      color: '#FFFFFF',
    },
    padding: '6px 10px',
  },
  flexHorizontal: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '20px',
  },
  selectLabel: {
    color: 'yellow',
  },
  outlined: {
    padding: '12px',
  },
};

export const getTypesOptions = (documentiTypes, all) => {
  const formattedDocTypes = documentiTypes.map(({ id_tipo_documento, desc_tipo_documento }) => ({
    value: id_tipo_documento,
    label: desc_tipo_documento,
  }));

  return [{ value: 'all', label: all }, ...formattedDocTypes];
};

export const COMMON_FILTERS = {
  timeframeOptions: (timeframes) => [
    {
      label: timeframes['today'],
      value: 'today',
    },
    {
      label: timeframes['last_week'],
      value: 'last_week',
    },
    {
      label: timeframes['last_month'],
      value: 'last_month',
    },
    {
      label: timeframes['last_year'],
      value: 'last_year',
    },
    {
      label: timeframes['custom'],
      value: 'custom',
    },
  ],
};

export const TIMEFRAME = {
  scopeOptions: (states) => [
    {
      label: states['created'],
      value: 'created',
    },
    {
      label: states['draft'],
      value: 'draft',
    },
    {
      label: states['toSign'],
      value: 'toSign',
    },
    {
      label: states['toView'],
      value: 'toView',
    },
    {
      label: states['completed'],
      value: 'completed',
    },
    {
      label: states['rejected'],
      value: 'rejected',
    },
  ],
};

export const OVERALL = {
  COLORS_MAPPING: {
    1: '#e62e00',
    3: '#FFBB28',
    4: '#9966ff',
    5: '#3366ff',
    9: '#009933',
  },
  StatoDocumento: (states) => ({
    1: states['draft'],
    3: states['rejected'],
    4: states['toSign'],
    5: states['toView'],
    9: states['completed'],
  }),
};

export const COUNTER = {
  scopeOptions: (states) => [
    {
      label: states['created'],
      value: 'created',
    },
    {
      label: states['draft'],
      value: 'draft',
    },
    {
      label: states['toSign'],
      value: 'toSign',
    },
    {
      label: states['toView'],
      value: 'toView',
    },
    {
      label: states['signed'],
      value: 'signed',
    },
    {
      label: states['viewed'],
      value: 'viewed',
    },
    {
      label: states['rejected'],
      value: 'rejected',
    },
  ],
  COLORS_MAPPING: {
    created: '#e62e00',  // rosso
    draft: '#ff9966',    // arancione
    toSign: '#FFBB28',   // viola
    toView: '#009933',   // verde
    signed: '#9966ff',   // verde
    viewed: '#3366ff',   // blu
    rejected: '#003366', // blu scuro
  },
};
