import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Loading from '../ReusableComponents/Loading';
import Chart from './Chart';
import DownloadChart from './DownloadChart';
import { OVERALL } from '../Constants';

const OverallAnalysis = () => {
  const { t } = useTranslation();
  const { totalDocs } = t('analytics', { returnObjects: true }).chartsLabels;
  const states = t('analytics.states', { returnObjects: true });
  const cardColor = useSelector(({ tema }) => tema.theme_color);
  const language = useSelector(({ settings }) => settings.language);
  const { result, loading, timeframe } = useSelector((state) => state.analytics.overall);

  const data = useMemo(
    () =>
      result.map(({ count, id_stato_documento }) => ({
        id_stato_documento,
        label: OVERALL.StatoDocumento(states)[id_stato_documento],
        value: parseInt(count),
      })),
    [language, result]
  );

  return (
    <Card>
      <CardHeader style={{ width: 'fit-content' }} color={cardColor}>
        <Typography
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            fontSize: '0.65rem',
          }}
        >
          {totalDocs}
        </Typography>
      </CardHeader>
      <CardBody
        style={{
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        {loading && data.length === 0 ? (
          <Loading />
        ) : data.length > 0 ? (
          <div style={{ width: '100%', height: '100%' }}>
            <Chart data={data} />
            <div style={{ opacity: 0, position: 'absolute' }}>
              <DownloadChart data={data} timeframe={timeframe} />
            </div>
          </div>
        ) : (
          <NoData />
        )}
      </CardBody>
    </Card>
  );
};
{
}

const NoData = () => {
  const { t } = useTranslation();
  const { noData } = t('analytics', { returnObjects: true }).helpers;

  return (
    <div
      style={{
        width: '100%',
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>{noData}</Typography>
    </div>
  );
};

export default OverallAnalysis;
