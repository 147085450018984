import { authHeader } from '../Helpers';

export const settingsService = {
  getMaintenance,
  setMaintenance,
};

let wMount;
if (process.env.NODE_ENV === 'production') {
  wMount = process.env.REACT_APP_SERVICES_URL;
} else {
  wMount = process.env.REACT_APP_AUTH_URL;
}

function getMaintenance() {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
  };

  let url = wMount + '/api/v1/settings/maintenance';

  return fetch(url, requestOptions).then(handleResponse);
}

function setMaintenance(value) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ value }),
  };

  let url = wMount + '/api/v1/settings/maintenance';

  return fetch(url, requestOptions).then(handleResponse);
}

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.Result.Code !== 'E100') {
        const error = data && data.Result && data.Result.Message;
        return Promise.reject(error);
      }
    }
    return data;
  });
};
