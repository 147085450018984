import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import AsyncSearch from 'Custom/AsyncSearch.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { utilsActions, userActions } from '../../Actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { userService } from '../../Services/user.oauth2.service';

const styles = () => ({
  ...extendedFormsStyle,
  selectLabel: {
    fontSize: '14px',
    color: '#AAA !important',
    top: '8px',
  },
});

class SelectUtenti extends React.Component {
  state = {
    id_utente: '',
    id_current_uo: undefined,
  };

  componentDidUpdate(prevProps) {
    const { id_unita_organizzativa, disabled, utils, users } = this.props;
    const { id_current_uo } = this.state;

    if (!disabled && !utils.loading && id_current_uo !== id_unita_organizzativa) {
      this.setState({ id_current_uo: id_unita_organizzativa });
      this.props.dispatch(utilsActions.getUtentiByUnitaOrganizzativa(id_unita_organizzativa));
    }
    if (users.selectedUser !== prevProps.users.selectedUser) {
      if (users.selectedUser) {
        this.setState({
          id_utente: users.selectedUser,
          selectedUtente: {
            id_utente: users.selectedUser,
            nome_utente: users.selectedUser.nome + ' ' + users.selectedUser.cognome,
          },
        });

        this.props.onChange({
          id_utente: users.selectedUser,
          nome_utente: users.selectedUser.nome + ' ' + users.selectedUser.cognome,
        });
      }
    }
  }

  handleSelect = (event) => {
    const { value, label } = event.target;
    this.setState({ id_utente: value });
    if (value) this.props.dispatch(userActions.getUtenteById(value.id_utente));
    this.props.dispatch(userActions.setSelectedUser(value));

    this.props.onChange({
      id_utente: value,
      nome_utente: label,
    });
  };

  loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      return callback([]);
    }
    var optionRubrica = [];

    userService
      .filteredUser(inputValue)
      .then((users) => {
        if (typeof users !== 'undefined') {
          optionRubrica = users.map((user) => ({
            value: user,
            label: user.utente,
          }));
        }
        callback(optionRubrica);
      })
      .catch((error) => {
        console.log(error);
        callback(optionRubrica);
      });
  };

  render() {
    const { t, classes, utils, id_unita_organizzativa, users } = this.props;

    let optionUtenti = [];
    if (utils.utenti !== undefined && id_unita_organizzativa !== undefined) {
      utils.utenti.forEach((prop) => {
        if (prop.dismessa === false) {
          optionUtenti.push({
            value: prop,
            label: prop.utente,
          });
        }
      });
    } else {
      if (users.itemsByUo !== undefined) {
        users.itemsByUo.forEach((prop) => {
          if (prop.dismessa === false)
            optionUtenti.push({
              value: prop,
              label: prop.nome + ' ' + prop.cognome,
            });
        });
      }
    }

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl fullWidth disabled={this.props.disabled} className={classes.selectFormControl}>
          <AsyncSearch
            label={t('document.labelUser')}
            placeholder={t('document.labelSelectUser')}
            loadOptions={this.loadOptions}
            options={optionUtenti}
            isClearable={true}
            id={'id_utente'}
            isSearchable={true}
            value={users.selectedUser}
            onChange={this.handleSelect}
          />
        </FormControl>
      </form>
    );
  }
}

SelectUtenti.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { utils, users } = state;
  return {
    utils,
    users,
  };
}

const connectedSelectUnitaOrganizzative = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(SelectUtenti);
export default connectedSelectUnitaOrganizzative;
