import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'components/CustomButtons/Button.jsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import Close from '@material-ui/icons/Close';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { DialogTitle } from '@material-ui/core';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  ...extendedFormsStyle,
  ...regularFormsStyle,
  tcSignInputStyle: {
    width: '100%',
  },
};

const initialState = {
  username: '',
  password: '',
  usernameState: undefined,
  passwordState: undefined,
};

class ArchiveModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  handleClose() {
    this.setState(initialState);
    this.props.onClose();
  }

  getFormFieldValue(pFieldName) {
    const { documenti } = this.props;
    if (documenti?.archiviazione) {
      const { archiviazione } = documenti;
      if (archiviazione)
        if (pFieldName === 'file_archivio') {
          return archiviazione.rapporto_versamento.file_archivio._text;
        } else if (pFieldName === 'id_pdv') {
          return archiviazione.rapporto_versamento.id_pdv._text;
        } else if (pFieldName === 'riferimentoTemporale') {
          return archiviazione.rapporto_versamento.riferimentoTemporale._text;
        } else if (pFieldName === 'tipo_doc') {
          return archiviazione.tipo_doc;
        }
    }
    return '';
  }

  render() {
    const { t, classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        style={{ zIndex: 99999 }}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        PaperProps={{ style: { maxWidth: '95vw', width: '40vw', height: '48vh' } }}
      >
        <DialogTitle>Dati pacchetto di versamento:</DialogTitle>
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <GridContainer
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={t('documentArchive.labelFileName')}
                id="file_archivio"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  disabled: true,
                  onChange: (event) => this.change(event, 'nomeDocumento', 'nomeDocumento'),
                  type: 'text',
                  value: this.getFormFieldValue('file_archivio') || '',
                }}
                className={classes.tcSignInputStyle}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={t('documentArchive.labelPdv')}
                id="id_pdv"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  onChange: (event) => this.change(event, 'pdv', 'pdv'),
                  type: 'text',
                  value: this.getFormFieldValue('id_pdv') || '',
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={t('documentArchive.labelDataArchiviazione')}
                id="riferimentoTemporale"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  disabled: true,
                  value: this.getFormFieldValue('riferimentoTemporale') || '',
                }}
                className={classes.tcSignInputStyle}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={t('documentArchive.labelClasseDocumentale')}
                id="tipo_doc"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  disabled: true,
                  value: this.getFormFieldValue('tipo_doc') || '',
                }}
                className={classes.tcSignInputStyle}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          <Button color="info" onClick={() => this.handleClose()}>
            <Close /> {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { documenti } = state;

  return {
    documenti,
  };
}

const connectedArchiveModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(ArchiveModalForm);
export default connectedArchiveModalForm;
