import { analyticsConstants } from '../Constants';

const docsDetailsInitial = {
  filters: {},
  sorting: [{ id: 'id', desc: false }],
  pagination: { pageSize: 10, pageIndex: 1 },
  result: [],
};

const initialState = {
  reports: { result: [] },
  filteredUsers: [],
  chartsRef: { overall: null, timeframe: null, counter: null },
  overall: {
    options: { timeframe: 'last_week', dateRange: { from: '', to: '' }, tipo_documento: 'all' },
    result: [],
  },
  timeframe: {
    options: { scope: 'created' },
    result: [],
  },
  counter: { options: { scope: 'signed', user: null }, result: null },
  docsDetails: docsDetailsInitial,
};

export function analytics(state = initialState, { payload, type }) {
  const { overall, timeframe, counter, chartsRef, docsDetails, reports } = state;

  switch (type) {
    // get filtered users by uo visibility
    case analyticsConstants.GET_FILTERED_USERS_SUCCESS:
      return { ...state, filteredUsers: payload };

    // set chart ref
    case analyticsConstants.SET_CHART_REF:
      const { chartName, ref } = payload;
      return { ...state, chartsRef: { ...chartsRef, [chartName]: ref } };

    // overall analysis
    case analyticsConstants.SET_OVERALL_OPTIONS_REQUEST:
      return { ...state, overall: { ...overall, options: { ...overall.options, ...payload } } };
    case analyticsConstants.GET_OVERALL_ANALYSIS_REQUEST:
      return { ...state, overall: { ...overall, loading: true } };
    case analyticsConstants.GET_OVERALL_ANALYSIS_SUCCESS:
      return {
        ...state,
        overall: { ...overall, ...payload, loading: false },
      };
    case analyticsConstants.GET_OVERALL_ANALYSIS_FAILURE:
      return { ...state, overall: { ...overall, loading: false } };

    // timeframe analysis
    case analyticsConstants.SET_TIMEFRAME_OPTIONS_REQUEST:
      return {
        ...state,
        timeframe: { ...timeframe, options: { ...timeframe.options, ...payload } },
      };
    case analyticsConstants.GET_TIMEFRAME_ANALYSIS_REQUEST:
      return { ...state, timeframe: { ...timeframe, loading: true } };
    case analyticsConstants.GET_TIMEFRAME_ANALYSIS_SUCCESS:
      return {
        ...state,
        timeframe: { ...timeframe, ...payload, loading: false },
      };
    case analyticsConstants.GET_TIMEFRAME_ANALYSIS_FAILURE:
      return { ...state, timeframe: { ...timeframe, loading: false } };

    // counter analysis
    case analyticsConstants.SET_COUNTER_OPTIONS_REQUEST:
      return { ...state, counter: { ...counter, options: { ...counter.options, ...payload } } };
    case analyticsConstants.GET_COUNTER_ANALYSIS_REQUEST:
      return { ...state, counter: { ...counter, loading: true } };
    case analyticsConstants.GET_COUNTER_ANALYSIS_SUCCESS:
      return {
        ...state,
        counter: { ...counter, ...payload, loading: false },
      };
    case analyticsConstants.GET_COUNTER_ANALYSIS_FAILURE:
      return { ...state, counter: { ...counter, loading: false } };

    // overall docs details
    case analyticsConstants.GET_ANALYSIS_DOCS_DETAILS_REQUEST:
      return { ...state, docsDetails: { ...state.docsDetails, loading: true } };
    case analyticsConstants.GET_ANALYSIS_DOCS_DETAILS_SUCCESS:
      return {
        ...state,
        docsDetails: { ...state.docsDetails, result: payload.data, loading: false },
        allData: payload.allData ? payload.allData : state.allData,
      };
    case analyticsConstants.GET_ANALYSIS_DOCS_DETAILS_FAILURE:
      return { ...state, docsDetails: { ...state.docsDetails, loading: false } };

    // set pagination
    case analyticsConstants.SET_PAGINATION:
      return {
        ...state,
        docsDetails: { ...docsDetails, pagination: { ...docsDetails.pagination, ...payload } },
      };
    // set sorting
    case analyticsConstants.SET_DOCS_SORTING:
      return {
        ...state,
        docsDetails: { ...docsDetails, sorting: payload },
      };
    // set filters
    case analyticsConstants.SET_FILTERS:
      return { ...state, docsDetails: { ...docsDetails, filters: payload } };

    // set docsDetails
    case analyticsConstants.SET_DOCS_DETAILS:
      return { ...state, docsDetails: { ...docsDetails, ...payload } };

    // reset docsDetails
    case analyticsConstants.RESET_DOCS_DETAILS:
      return { ...state, docsDetails: docsDetailsInitial, allData: undefined };

    // reports
    case analyticsConstants.FIND_ALL_REPORTS_REQUEST:
      return { ...state, reports: { ...reports, loading: true } };
    case analyticsConstants.FIND_ALL_REPORTS_SUCCESS:
      return { ...state, reports: { loading: false, result: payload } };
    case analyticsConstants.FIND_ALL_REPORTS_FAILURE:
      return { ...state, reports: { loading: false, result: [] } };

    // default
    default:
      return state;
  }
}
