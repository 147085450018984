import React, { useState, Fragment } from 'react';
import { useDrop } from 'react-dnd';
import { withStyles } from '@material-ui/core/styles';
import TagFirmatario from './TagFirmatario';
import { withSnackbar } from 'notistack';
import { SIGNATURE_BOX_WIDTH, SIGNATURE_BOX_HEIGHT } from '../../Helpers';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';

const style = () => ({
  overlayCanvas: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 50,
  },
  hoverOverlayCanvas: {
    '&:hover': {
      cursor: 'crosshair',
    },
  },
});

const TagPositioning = React.memo((props) => {
  const { classes, tags, pagina, statoDocumento, currentFlusso } = props;
  const { canvasRef, currentTab } = props;
  const { CANVAS_WIDTH, CANVAS_HEIGHT, firmatario, updateTags, enqueueSnackbar } = props;
  const [wrapperClickEnabled, setWrapperClickEnabled] = useState(true);
  const { tipo_firma, id_contatto, id_utente, id_uo } = firmatario;

  const { t } = useTranslation();
  const useLocalDrop = () => {
    const [, dropTarget] = useDrop({
      accept: 'TAG',
      drop(item, monitor) {
        const offset = monitor.getDifferenceFromInitialOffset();
        const newTags = JSON.parse(JSON.stringify(tags));
        let notOverlayTag = true;

        let currentTag = newTags.filter((tag) => tag.id === item.id)[0];

        let top = currentTag.top + offset.y;
        let left = currentTag.left + offset.x;

        if (top <= 0) top = 1;
        else if (top + SIGNATURE_BOX_HEIGHT >= CANVAS_HEIGHT) {
          top = CANVAS_HEIGHT - SIGNATURE_BOX_HEIGHT;
        }

        if (left <= 0) left = 1;
        else if (left + SIGNATURE_BOX_WIDTH >= CANVAS_WIDTH)
          left = CANVAS_WIDTH - SIGNATURE_BOX_WIDTH;

        newTags.forEach((tag) => {
          if (tag.id !== item.id) {
            if (
              top > tag.top - SIGNATURE_BOX_HEIGHT &&
              top < tag.top + SIGNATURE_BOX_HEIGHT &&
              left > tag.left - SIGNATURE_BOX_WIDTH &&
              left < tag.left + SIGNATURE_BOX_WIDTH &&
              tag.pagina === pagina
            )
              notOverlayTag = false;
          }
        });

        if (notOverlayTag) {
          newTags.forEach((tag) => {
            if (tag.id === item.id) {
              tag.top = top;
              tag.left = left;
            }
          });
          updateTags(newTags);
        } else
          enqueueSnackbar(t('misc.noOverlapSign'), {
            variant: 'warning',
          });
      },
    });

    return (elem) => {
      canvasRef.current = elem;
      dropTarget(canvasRef);
    };
  };

  const addTag = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    let notOverlayTag = true;
    let newTags = JSON.parse(JSON.stringify(tags));

    if (firmatario.label) {
      const canAddTag =
        (id_contatto && tipo_firma.enable_fea) ||
        (id_contatto &&
          tipo_firma.enable_feq &&
          tags.filter((tag) => tag.id_contatto === id_contatto).length === 0) ||
        (id_utente && tags.filter((tag) => tag.id_utente === id_utente).length === 0) ||
        (id_uo && tags.filter((tag) => tag.id_uo === id_uo).length === 0)
          ? true
          : false;

      if (canAddTag) {
        let top = offsetY;
        let left = offsetX;

        //canvas border check
        if (CANVAS_HEIGHT - top < SIGNATURE_BOX_HEIGHT) top = CANVAS_HEIGHT - SIGNATURE_BOX_HEIGHT;

        if (CANVAS_WIDTH - left < SIGNATURE_BOX_WIDTH) left = CANVAS_WIDTH - SIGNATURE_BOX_WIDTH;

        //overlay tag check
        newTags.forEach((tag) => {
          if (
            top > tag.top - SIGNATURE_BOX_HEIGHT &&
            top < tag.top + SIGNATURE_BOX_HEIGHT &&
            left > tag.left - SIGNATURE_BOX_WIDTH &&
            left < tag.left + SIGNATURE_BOX_WIDTH &&
            tag.pagina === pagina
          )
            notOverlayTag = false;
        });

        if (notOverlayTag) {
          const nuovoTag = {
            id: uuidv4(),
            top,
            left,
            pagina,
            firmatario: firmatario.label,
          };
          if (firmatario.id_utente) nuovoTag.id_utente = firmatario.id_utente;
          else if (firmatario.id_contatto) nuovoTag.id_contatto = firmatario.id_contatto;
          else if (firmatario.id_uo) nuovoTag.id_uo = firmatario.id_uo;

          newTags.push(nuovoTag);
          updateTags(newTags);
        } else
          enqueueSnackbar(t('misc.noOverlapSign'), {
            variant: 'warning',
          });
      } else
        enqueueSnackbar(t('misc.noPosMultipleSign'), {
          variant: 'warning',
        });
    } else
      enqueueSnackbar(t('document.selectSignatory'), {
        variant: 'warning',
      });
  };

  const removeTag = (ev, index) => {
    ev.stopPropagation();
    const newTags = [...tags];
    newTags.splice(index, 1);

    updateTags(newTags);
    setWrapperClickEnabled(true);
  };

  const showSnackbar = () => {
    let message;
    if (!firmatario.label) message = t('document.selectSignatory');
    enqueueSnackbar(message, {
      variant: 'info',
    });
  };

  const statoAndTabAllowed =
    statoDocumento === 1 ||
    (statoDocumento === 2 && ['Firma', 'ViewerFirma', 'Percorsi di firma'].includes(currentTab));

  return (
    <div
      ref={useLocalDrop()}
      className={`${classes.overlayCanvas} ${firmatario.label ? classes.hoverOverlayCanvas : null}`}
      style={{ width: CANVAS_WIDTH, height: CANVAS_HEIGHT }}
      onClick={
        firmatario && wrapperClickEnabled && statoAndTabAllowed
          ? (event) => addTag(event)
          : statoAndTabAllowed
          ? (event) => showSnackbar(event)
          : null
      }
    >
      {tags.length > 0
        ? tags.map((tag, index) => {
            const { top, left, id_flusso_op, id, id_coordinata } = tag;

            const newTag = !id_coordinata;
            const oldTag =
              id_coordinata &&
              currentFlusso?.id_flusso_operativo === id_flusso_op &&
              !['Percorsi di firma', 'Posizionamento firma'].includes(currentTab);

            const isEditable =
              statoDocumento === 1 || (statoDocumento === 2 && (newTag || oldTag)) ? true : false;

            if (pagina === tag.pagina) {
              return (
                <Fragment key={index}>
                  <TagFirmatario
                    id={id}
                    firmatario={tag.firmatario}
                    isEditable={isEditable}
                    removeTag={(ev) => removeTag(ev, index)}
                    onMouseOver={() => setWrapperClickEnabled(false)}
                    onMouseLeave={() => setWrapperClickEnabled(true)}
                    coords={{ top, left }}
                  />
                </Fragment>
              );
            } else return null;
          })
        : null}
    </div>
  );
});

export default withSnackbar(withStyles(style)(TagPositioning));
