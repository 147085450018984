import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { settingsActions } from '../../Actions';

import itFlag from 'assets/img/flags/IT.png';
import enFlag from 'assets/img/flags/US.png';

const languages = [
  {
    id: 'it',
    title: 'Italian',
    flag: itFlag,
  },
  {
    id: 'en',
    title: 'English',
    flag: enFlag,
  },
];

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentLanguageId = i18n.language.split('-')[0];
  console.log('LINGUA CORRENTE: ', currentLanguageId);
  const currentLanguage = languages.find((lng) => lng.id === currentLanguageId);

  const [menu, setMenu] = useState(null);

  const langMenuClick = ({ currentTarget }) => setMenu(currentTarget);

  const langMenuClose = () => setMenu(null);

  useEffect(() => {
    const lng = sessionStorage.getItem('lng');
    if (lng) i18n.changeLanguage(JSON.parse(lng).id);
    else sessionStorage.setItem('lng', JSON.stringify(currentLanguage));

    dispatch(
      settingsActions.setSettings({ language: lng ? JSON.parse(lng).id : currentLanguage.id })
    );
  }, []);

  const handleLanguageChange = (lng) => {
    sessionStorage.setItem('lng', JSON.stringify(lng));
    i18n.changeLanguage(lng.id);
    dispatch(settingsActions.setSettings({ language: lng.id }));
    langMenuClose();
  };

  return (
    <>
      <Button className="h-40 w-64" onClick={langMenuClick}>
        <img className="mx-4 min-w-20" src={currentLanguage.flag} alt={currentLanguage.title} />

        <Typography className="mx-4 font-semibold uppercase" color="textSecondary">
          {currentLanguage.id}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {languages.map((lng) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="min-w-40">
              <img className="min-w-20" src={lng.flag} alt={lng.title} />
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguageSwitcher;
