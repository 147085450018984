import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
import Test from '@material-ui/icons/LaptopMac';

// core components
import HeaderLinks from './TCSignHeaderLinks';
import Button from 'components/CustomButtons/Button.jsx';

import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';
var environment = process.env.REACT_APP_ENVIRONMENT;
function Header({ ...props }) {
  function makeBrand() {
    //var name;
    //props.routes.map((prop, key) => {
    //  if (prop.collapse) {
    //    prop.views.map((prop, key) => {
    //      if (prop.path === props.location.pathname) {
    //        name = prop.name;
    //      }
    //      return null;
    //    });
    //  }
    //  if (prop.path === props.location.pathname) {
    //    name = prop.name;
    //  }
    //  return null;
    //});
    //return name;
    return '';
  }

  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>

        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button
            href="#"
            className={classes.title}
            color="transparent"
            children={makeBrand()}
          ></Button>
          {environment === 'collaudo' && (
            <Chip
              color="secondary"
              avatar={
                <Avatar>
                  <Test />
                </Avatar>
              }
              label="Libro Firma ambiente di TEST"
              className={'etichetta_test'}
            />
          )}
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp>
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
};

export default withStyles(headerStyle)(Header);
