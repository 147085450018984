import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Manager, Popper } from 'react-popper';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Dashboard from '@material-ui/icons/Dashboard';

// core components
import Button from 'components/CustomButtons/Button.jsx';

import headerLinksStyle from 'assets/jss/material-dashboard-pro-react/components/headerLinksStyle';

class HeaderLinks extends React.Component {
  state = {
    open: false,
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes, rtlActive } = this.props;
    const { open } = this.state;
    /*const searchButton =
      classes.top +
      " " +
      classes.searchButton +
      " " +
      classNames({
        [classes.searchRTL]: rtlActive
      });*/
    const dropdownItem =
      classes.dropdownItem +
      ' ' +
      classNames({
        [classes.dropdownItemRTL]: rtlActive,
      });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    return (
      <div className={wrapper}>
        {/*<CustomInput
          rtlActive={rtlActive}
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: rtlActive ? "بحث" : "Search",
            inputProps: {
              "aria-label": rtlActive ? "بحث" : "Search",
              className: classes.searchInput
            }
          }}
        />
            <Button
          color="white"
          aria-label="edit"
          justIcon
          round
          className={searchButton}
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button>*/}
        <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Dashboard
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp>
            <span className={classes.linkText}>{rtlActive ? 'لوحة القيادة' : 'Dashboard'}</span>
          </Hidden>
        </Button>
        <Manager className={managerClasses}>
          <Popper
            placement="bottom-start"
            eventsEnabled={open}
            className={
              classNames({ [classes.popperClose]: !open }) + ' ' + classes.pooperResponsive
            }
          >
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grow in={open} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.dropdown}>
                  <MenuList role="menu">
                    <MenuItem onClick={this.handleClose} className={dropdownItem}>
                      {rtlActive
                        ? 'إجلاء أوزار الأسيوي حين بل, كما'
                        : 'Mike John responded to your email'}
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} className={dropdownItem}>
                      {rtlActive ? 'شعار إعلان الأرضية قد ذلك' : 'You have 5 new tasks'}
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} className={dropdownItem}>
                      {rtlActive ? 'ثمّة الخاصّة و على. مع جيما' : "You're now friend with Andrew"}
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} className={dropdownItem}>
                      {rtlActive ? 'قد علاقة' : 'Another Notification'}
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} className={dropdownItem}>
                      {rtlActive ? 'قد فاتّبع' : 'Another One'}
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp>
            <span className={classes.linkText}>{rtlActive ? 'الملف الشخصي' : 'Profile'}</span>
          </Hidden>
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

export default withStyles(headerLinksStyle)(HeaderLinks);
