import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
// @material-ui/core components
import { Dialog, DialogContent, DialogActions, Slide, withStyles } from '@material-ui/core/';
import Button from 'components/CustomButtons/Button.jsx';
import { ClassOutlined, Close } from '@material-ui/icons';
import GridItem from 'components/Grid/GridItem';
import RicercaUtente from 'Custom/Utilities/RicercaUtente';
//import TabellaUtenti from 'Custom/Utilities/TabellaUtenti';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import TabellaUtenti from 'Custom/Utilities/TabellaUtenti';
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const overlay = css`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

const styles = () => ({
  ...extendedFormsStyle,
  noPadding: {
    padding: '0px !important',
  },
  override: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    'margin-top': '-50px',
    'margin-left': '-50px',
    width: '100px',
    height: '100px',
  },
  overlay: {
    display: 'block' /* Hidden by default */,
    position: 'fixed' /* Stay in place */,
    'z-index': 1 /* Sit on top */,
    left: 0,
    top: 0,
    width: '100%' /* Full width */,
    height: '100%' /* Full height */,
    overflow: 'auto' /* Enable scroll if needed */,
    'background-color': 'rgb(0, 0, 0)' /* Fallback color */,
    'background-color': 'rgba(18, 37, 19, 0.35)' /* Black w/ opacity */,
  },
});

const SearchOimModal = React.memo((props) => {
  const { t } = useTranslation();
  const { classes, onClose, users } = props;

  let loading;
  if (users?.loading) {
    loading = (
      <div className={classes.overlay}>
        <div className={classes.override}>
          <ClipLoader sizeUnit={'px'} size={80} color={'#3a783d'} />
        </div>
      </div>
    );
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      PaperProps={{ style: { maxWidth: '95vw', width: '80vw', height: '92.5vh' } }}
    >
      {loading}
      <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
        <GridItem xs={12}>
          <RicercaUtente />
          <TabellaUtenti onClose={onClose} />
        </GridItem>
      </DialogContent>
      <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
        <Button color="info" onClick={() => onClose()}>
          <Close style={{ margin: '0px' }} /> {t('action.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default withStyles(styles)(SearchOimModal);
