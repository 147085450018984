import React, { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import moment from 'moment';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { RiFileExcel2Fill } from 'react-icons/ri';

import FilteredDocsList from './FilteredDocsList';
import AnalysisFilters from './AnalysisFilters';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { Authorize } from 'Helpers/model-view.js';
import { analyticsActions } from 'Actions';
import { Constants } from 'Helpers/model-view.js';

const Transition = (props) => <Slide direction="down" {...props} />;

const styles = () => ({
  ...extendedFormsStyle,
  noPadding: { padding: '0px !important' },
});

const ChartDetails = memo(({ classes, closeDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { exportExcel } = t('action', { returnObjects: true });
  const { documentsDetails } = t('analytics', { returnObjects: true });
  const { close } = t('action', { returnObjects: true });

  const tableRef = useRef();

  const result = useSelector(({ analytics }) => analytics.docsDetails.result);
  const data = useSelector(({ analytics }) => analytics.allData);

  const exportToExcel = () => {
    if (tableRef.current) {
      const { columns } = tableRef.current.state;
      const columnsToExport = columns.filter(({ Header }) => !['', 'Actions'].includes(Header));

      const dataToExport = data.map((d) => {
        const dataObj = {};

        columnsToExport.forEach(({ Header, accessor }) => {
          dataObj[Header] =
            typeof accessor === 'function'
              ? d['data_creazione']
              : accessor === 'id'
              ? d['id_documento']
              : accessor === 'priorita'
              ? t(`document.priorityType.${d[accessor] === 'A' ? 'Urgente' : 'Standard'}`)
              : d[accessor]; // else normal behavior
        });

        return dataObj;
      });

      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Export documenti');
      XLSX.writeFile(workbook, `${documentsDetails}_${moment().format('DD/MM/YYYY')}.xlsx`, {
        compression: true,
      });
    }
  };

  const handleClose = () => {
    dispatch(analyticsActions.resetDocsDetails());
    closeDetails();
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      PaperProps={{ style: { maxWidth: '95vw', width: '95vw', height: '92.5vh' } }}
    >
      <DialogTitle>{documentsDetails}</DialogTitle>
      <DialogContent>
        <GridContainer style={{ padding: '1.5em 0' }}>
          <GridItem xs={12}>
            <AnalysisFilters />
            <FilteredDocsList tableRef={tableRef} />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
        {Authorize.checkAuthorizeFunc('FNCDWL') && (
          <Button disabled={result.length === 0} onClick={exportToExcel} color="success">
            <RiFileExcel2Fill />
            {exportExcel}
          </Button>
        )}
        <Button color="info" onClick={handleClose}>
          <Close style={{ margin: '0px' }} /> {close}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default withStyles(styles)(ChartDetails);
