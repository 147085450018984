import { filter } from 'lodash';
import { authHeader } from '../Helpers';
import { tokenHelper } from '../Helpers/token.js';

var wMount;
if (process.env.NODE_ENV === 'production') {
  wMount = process.env.REACT_APP_SERVICES_URL;
} else {
  wMount = process.env.REACT_APP_USR_URL;
}

export const userService = {
  token,
  login,
  logout,
  register,
  getAll,
  getUtenteById,
  update,
  editSignature,
  deleteSignature,
  getRuoliUtente,
  deleteUtenteById,
  saveNotifiche,
  attivaDelegato,
  saveDelegati,
  getUserCompanies,
  getAllCompanies,
  getThemeCss,
  getThemeImage,
  getThemeColor,
  getTenantByName,
  getTenantTheme,
  filteredUser,
  search,
  createNewUser,
  getVisibleUsers,
};

function saveNotifiche(idUtente, notifiche) {
  let wMethod = 'POST';
  let url = wMount + '/api/v1/utente/notifica/' + idUtente;

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(notifiche),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function token(code, history) {
  console.log(code);
  tokenHelper.getAuthorize(code).then((token) => {
    let user = token.access_token.user;
    user['password'] = '';
    if (token) {
      localStorage.setItem('user', JSON.stringify(user));
    }

    history.push('/auth');
  });
}

function login(username, password, selectedCompany) {
  return tokenHelper.getToken(username, password, selectedCompany).then((token) => {
    let user = token.access_token.user;
    //user['password'] = password;
    user['company'] = null;
    if (selectedCompany) {
      user['company'] = selectedCompany;
    }
    if (token) {
      localStorage.setItem('user', JSON.stringify(user));
    }

    return user;
  });
}

function logout() {
  localStorage.removeItem('underMaintenance');
  localStorage.removeItem('token');
  localStorage.removeItem('user');

  window.location.reload();
}

function getRuoliUtente() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/utente/ruoli/getAll';

  return fetch(url, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/utente/';

  return fetch(url, requestOptions).then(handleResponse);
}

function getUtenteById(idUtente) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/utente/' + idUtente;

  return fetch(url, requestOptions).then(handleResponse);
}

function getVisibleUsers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/utente/utentiVisibili';

  return fetch(url, requestOptions).then(handleResponse);
}

function register(user, isInConfig) {
  let wMethod = 'POST';
  let url = wMount + '/api/v1/utente/';
  if (typeof user.id_utente !== 'undefined') {
    wMethod = 'PUT';
    if (isInConfig) url += user.id_utente;
    else url += 'updateProfilo/' + user.id_utente;
  }

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function editSignature(firma) {
  let wMethod = 'POST';
  let url = wMount + '/api/v1/digitalsignature/';
  if (typeof firma.id_digital_signature !== 'undefined') {
    wMethod = 'PUT';
    url += firma.id_digital_signature;
  }

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(firma),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function attivaDelegato(idDelegato, isAttivo) {
  let wMethod = 'PUT';
  let url = wMount + '/api/v1/utente/delegati/attivaDelegati';
  let requestBody = {};
  requestBody['id_utente_delegati'] = idDelegato;
  requestBody['attiva'] = isAttivo;

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function saveDelegati(idUtente, delegatiList) {
  let wMethod = 'POST';
  let url = wMount + '/api/v1/utente/delegati/' + idUtente;
  let requestBody = {};
  requestBody['delegati'] = delegatiList;

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteUtenteById(idUtente) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/utente/' + idUtente;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteSignature(idFirma) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/digitalsignature/' + idFirma;

  return fetch(url, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`http://localhost:4000/users/${user.id}`, requestOptions).then(handleResponse);
}

function getUserCompanies(username) {
  const requestOptions = {
    method: 'GET',
  };

  let url = wMount + '/api/v1/utente/company/getUserCompanies?username=' + username;

  return fetch(url, requestOptions).then(handleResponse);
}

function getAllCompanies() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = wMount + '/api/v1/utente/company/companies/getAll';
  return fetch(url, requestOptions).then(handleResponse);
}

function getTenantByName(tenantName) {
  let wMethod = 'GET';
  let url = wMount + '/api/v1/utente/getIdTenant/' + tenantName;

  const requestOptions = {
    method: wMethod,
    headers: authHeader(),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function getTenantTheme(idCompany) {
  let wMethod = 'GET';
  let url = wMount + '/api/v1/utente/getTenantTheme/' + idCompany;

  const requestOptions = {
    method: wMethod,
    headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((theme) => {
      if (theme) {
        const themeCss = userService.getThemeCss(theme, 'tcsignsidebar_css', 'css');
        localStorage.setItem('theme_css', JSON.stringify({ tcsignsidebar: themeCss }));
      }
      return theme;
    });
}

function getThemeCss(themes, key, type) {
  let result;
  if (themes && themes.length > 0) {
    themes.forEach((el) => {
      if (el.chiave === key && el.type_file === type) {
        result = el.valore;
      }
    });
  }
  return result;
}

function getThemeImage(themes, key, type) {
  let result;
  if (themes && themes.length > 0) {
    themes.forEach((el) => {
      if (el.chiave === key && el.type_file === type) {
        result = el.file_content;
      }
    });
  }
  return result;
}

function getThemeColor(themes, key, type) {
  let result;
  if (themes && themes.length > 0) {
    themes.forEach((el) => {
      if (el.chiave === key && el.type_file === type) {
        result = el.valore;
      }
    });
  }
  return result;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text) ? JSON.parse(text) : text;

    if (!response.ok) {
      if (response.status === 401) {
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.Result.Code !== 'E100') {
        if (data.Result.Code === 'E401') {
        }
        const error = data && data.Result && data.Result.Message;
        return Promise.reject(error);
      }
    }

    return data.Data;
  });
}
function filteredUser(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/utente/filteredUsers/' + filter;

  return fetch(url, requestOptions).then(handleResponse);
}

function search(nome, cognome, email) {
  let filter = { nome, cognome, email };
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(filter),
  };

  let url = wMount + '/api/v1/utente/findUserByFilter';

  return fetch(url, requestOptions).then(handleResponse);
}

function createNewUser(utente) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ utente }),
  };

  let url = wMount + '/api/v1/utente/createNewUser';

  return fetch(url, requestOptions).then(handleResponse);
}
