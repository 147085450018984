import { utilsConstants } from '../Constants';

export function utils(state = {}, action) {
  switch (action.type) {
    case utilsConstants.GET_UNITA_ORGANIZZATIVE_REQUEST:
      return {
        ...state,
        loading: true,
        utenti: undefined,
        unita_organizzative: undefined,
      };
    case utilsConstants.GET_UNITA_ORGANIZZATIVE_SUCCESS:
      return {
        ...state,
        unita_organizzative: action.unita_organizzative,
        utenti: undefined,
        loading: false,
      };
    case utilsConstants.GET_UNITA_ORGANIZZATIVE_FAILURE:
      return {
        ...state,
        error: action.error,
        utenti: undefined,
        unita_organizzative: undefined,
        loading: false,
      };
    case utilsConstants.GET_UTENTI_BY_UO_REQUEST:
      return {
        ...state,
        loading: true,
        utenti: undefined,
      };
    case utilsConstants.GET_UTENTI_BY_UO_SUCCESS:
      return {
        ...state,
        utenti: action.uo.utenti,
        loading: false,
      };
    case utilsConstants.GET_UTENTI_BY_UO_FAILURE:
      return {
        ...state,
        error: action.error,
        utenti: undefined,
        loading: false,
      };
    case utilsConstants.GET_UTENTI_RUOLO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_UTENTI_RUOLO_SUCCESS:
      return {
        ...state,
        ruoli_utente: action.ruoli_utente,
        loading: false,
      };
    case utilsConstants.GET_RUOLI_DEFAULT_SUCCESS:
      return {
        ...state,
        ruoli_utente_default: action.ruoli_utente_default,
        loading: false,
      };
    case utilsConstants.GET_UTENTI_RUOLO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.GET_TIPO_DOCUMENTO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_TIPO_DOCUMENTO_SUCCESS:
      return {
        ...state,
        tipologia_documento: action.tipologia_documento,
        loading: false,
      };
    case utilsConstants.GET_TIPO_DOCUMENTO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.GET_TIPO_RACCOGLITORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_TIPO_RACCOGLITORE_SUCCESS:
      return {
        ...state,
        tipologia_raccoglitore: action.tipologia_raccoglitore,
        loading: false,
      };
    case utilsConstants.GET_TIPO_RACCOGLITORE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.GET_STATO_DOCUMENTO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_STATO_DOCUMENTO_SUCCESS:
      return {
        ...state,
        stato_documento: action.stato_documento,
        loading: false,
      };
    case utilsConstants.GET_STATO_DOCUMENTO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.GET_ALL_FUNCTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_ALL_FUNCTION_SUCCESS:
      return {
        ...state,
        funzioni: action.funzioni,
        loading: false,
      };
    case utilsConstants.GET_ALL_FUNCTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.SAVE_SIGNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.SAVE_SIGNER_SUCCESS:
      return {
        ...state,
        contatto_selezionato: action.contatto,
        loading: false,
      };
    case utilsConstants.SAVE_SIGNER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.GET_CONTATTI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case utilsConstants.GET_CONTATTI_SUCCESS:
      return {
        ...state,
        elenco_contatti: action.contatti,
        loading: false,
      };
    case utilsConstants.GET_CONTATTI_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case utilsConstants.SEL_SAVE_CONTACT:
      return {
        ...state,
        current_contact: action.contatto,
      };
    case utilsConstants.UNSEL_SAVE_CONTACT:
      return {
        ...state,
        current_contact: undefined,
      };
    case utilsConstants.SEL_SINGLE_UO:
      return {
        ...state,
        current_uo: action.uo,
      };
    case utilsConstants.UNSEL_SINGLE_UO:
      return {
        ...state,
        current_uo: undefined,
      };
    case utilsConstants.UNSEL_CONTACTS:
      return {
        ...state,
        elenco_contatti: undefined,
      };
    case utilsConstants.GET_FILE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case utilsConstants.GET_FILE_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case utilsConstants.GET_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case utilsConstants.SET_RELOADED_SUCCESS:
      return {
        ...state,
        loading: false,
        reloaded: action.val,
      };
    default:
      return state;
  }
}
