import React from 'react';
import { withStyles } from '@material-ui/core';
import { SIGNATURE_BOX_WIDTH, SIGNATURE_BOX_HEIGHT } from '../../../Helpers';

const style = () => ({
  tagFirmatario: {
    width: SIGNATURE_BOX_WIDTH,
    height: SIGNATURE_BOX_HEIGHT,
    padding: '7px',
    backgroundColor: 'rgba(255, 128, 101, .6)',
    color: 'black',
    position: 'absolute',
    zIndex: 60,
    borderRadius: '3px',
    border: '1px solid black',
  },
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: '12px',
  },
});

const TagFirmatario = ({ classes, firmatario, coords }) => {
  return (
    <div
      className={classes.tagFirmatario}
      style={{
        top: coords.top,
        left: coords.left,
      }}
    >
      <div className={classes.rowWrapper}>
        <p>FIRMATARIO:</p>
      </div>
      <p style={{ fontFamily: 'Caveat', fontSize: '24px' }}>{firmatario ?? ''}</p>
    </div>
  );
};

export default withStyles(style)(TagFirmatario);
