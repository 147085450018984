import { authHeader } from '../Helpers';
import { handleDownload, handleResponse } from '../Helpers/utils.js';

const { NODE_ENV, REACT_APP_SERVICES_URL, REACT_APP_DOC_URL, REACT_APP_USR_URL } = process.env;

const wMount = NODE_ENV === 'production' ? REACT_APP_SERVICES_URL : REACT_APP_DOC_URL;

const getOverallAnalysis = (inputOptions) => {
  const url = wMount + '/api/v1/documents/getOverallAnalysis';

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(inputOptions),
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const getTimeframeAnalysis = (inputOptions) => {
  const url = wMount + '/api/v1/documents/getTimeframeAnalysis';

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(inputOptions),
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const getCounterAnalysis = (inputOptions) => {
  const url = wMount + '/api/v1/documents/getCounterAnalysis';

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(inputOptions),
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const getFilteredUsers = (filtersOptions) => {
  const url = (REACT_APP_SERVICES_URL || REACT_APP_USR_URL) + '/api/v1/utente/getFilteredUsers';

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(filtersOptions),
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const getAnalysisDocsDetails = (filters, pagination, sorting, nextPage) => {
  const url = wMount + '/api/v1/documents/getAnalysisDocsDetails';

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ filters, pagination, sorting, nextPage }),
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const findAllReports = () => {
  const url = wMount + '/api/v1/documents/report/findAllReports';

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const downloadReport = (id_report, filename, type) => {
  const url = wMount + `/api/v1/documents/report/download/${id_report}/${type}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    responseType: 'blob',
  };

  return fetch(url, requestOptions).then((response) => handleDownload(response, filename));
};

export const analyticsService = {
  getAnalysisDocsDetails,
  getOverallAnalysis,
  getTimeframeAnalysis,
  getCounterAnalysis,
  getFilteredUsers,
  findAllReports,
  downloadReport,
};
