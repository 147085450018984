import React, { useState } from 'react';
import { Close, NavigateNext } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button.jsx';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@material-ui/core';
import { TextField, MenuItem, InputLabel, withStyles, RadioGroup, Radio } from '@material-ui/core';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = {
  paper: {
    maxWidth: '500px',
    width: '500px',
  },
  label: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
};

const RaccoglitoreConfig = ({ classes, close, next, ...props }) => {
  const { paper, label } = classes;
  const { t, tipo_percorso, setTipoPercorso, tipoFirma, setTipoFirma } = props;

  const options = [
    { label: t('flow.onlyInt'), value: 'int' },
    { label: t('flow.onlyExt'), value: 'ext' },
    { label: t('flow.bothExtInt'), value: 'mix' },
  ];

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <Dialog open classes={{ paper }}>
      <DialogTitle>{t('flow.settings')}</DialogTitle>
      <DialogContent>
        <InputLabel style={{ fontWeight: 'bold', marginBottom: '1em' }}>
          {t('flow.selectType')}
        </InputLabel>
        <TextField
          fullWidth
          select
          margin="dense"
          variant="outlined"
          SelectProps={{ MenuProps }}
          value={tipo_percorso}
          onChange={({ target: { value } }) => setTipoPercorso(value)}
        >
          {options.map((option) => {
            const { value, label } = option;
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
        <RadioGroup
          aria-label={t('flow.extSign')}
          value={tipoFirma}
          style={{ marginTop: '2em' }}
          onChange={({ target: { value } }) => setTipoFirma(value)}
        >
          <FormControlLabel value="fd" control={<Radio />} label={t('flow.feq')} />
          <FormControlLabel value="fea" control={<Radio />} label={t('flow.fea')} />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color="info" onClick={() => close()}>
          <Close style={{ margin: '0px' }} /> {t('action.close')}
        </Button>
        <Button color="success" muiClasses={{ label }} onClick={() => next()}>
          <NavigateNext style={{ margin: '0px' }} /> {t('action.next')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(withStyles(styles), withTranslation())(RaccoglitoreConfig);
