import React from 'react';
import { connect } from 'react-redux';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { userActions } from '../../Actions';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  ...extendedFormsStyle,
  tcSignTableButton: {
    margin: '0px',
    padding: '0px',
    height: 'auto!important',
  },
  tcSignTableRow: {
    height: '28px!important',
  },
  tcSignTableHeadRow: {
    height: '28px',
  },
  tcSignTableCell: {
    height: '28px!important',
    opacity: '1',
    fontSize: '14px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: '400',
    lineHeight: '1.42857',
  },
  tcSignTableHead: {
    color: '#AAA !important',
    padding: '14px 0px 0px 0px!important',
    fontSize: '11px!important',
    lineHeight: '1rem!important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  tcSignInputStyle: {
    fontSize: '8px',
  },
  selectLabel: {
    fontSize: '14px',
    //textTransform: "uppercase",
    color: '#AAA !important',
    top: '8px',
  },
  tcSignSelectControl: {
    margin: '7px 1px 0px 0px !important',
  },
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '0px',
  },
  tcsign_cardbody: {
    'padding-top': '0',
    'padding-bottom': '0',
  },
  tcsign_switchIconChecked: {
    'border-color': '#AAA',
  },
  tcsign_dialog: {
    'max-width': '700px',
  },
});

const overlay = css`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

class ProfiloNotifiche extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selected_firma: undefined,
      showModalFirma: false,
      notifica_priorita_standard: undefined,
      notifica_priorita_urgente: undefined,
      notifica_riepilogo_giorno: undefined,
      notifica_riepilogo_settimana: undefined,
    };
  }

  handleSubmit() {
    const { user, i18n } = this.props;

    let notifiche = [];
    let notifica_priorita_standard = this.getFormFieldValue('notifica_priorita_standard');
    let notifica_priorita_urgente = this.getFormFieldValue('notifica_priorita_urgente');
    let notifica_riepilogo_giorno = this.getFormFieldValue('notifica_riepilogo_giorno');
    let notifica_riepilogo_settimana = this.getFormFieldValue('notifica_riepilogo_settimana');

    //CAMUSI 08072020 inizio
    if (notifica_priorita_standard) {
      let wCurrent = {
        id_tipologia_notifica: 1,
        priorita: 'B',
        frequenza: '',
        flag_abilitato: true,
      };

      notifiche.push(wCurrent);
    }
    if (notifica_priorita_urgente) {
      let wCurrent = {
        id_tipologia_notifica: 1,
        priorita: 'A',
        frequenza: '',
        flag_abilitato: true,
      };

      notifiche.push(wCurrent);
    }
    //CAMUSI 08072020 fine

    if (notifica_riepilogo_giorno) {
      let wCurrent = {
        id_tipologia_notifica: 2,
        priorita: '',
        frequenza: '1G',
        flag_abilitato: true,
        lang: i18n.language,
      };

      notifiche.push(wCurrent);
    } else if (notifica_riepilogo_settimana) {
      let wCurrent = {
        id_tipologia_notifica: 2,
        priorita: '',
        frequenza: '1S',
        flag_abilitato: true,
        lang: i18n.language,
      };

      notifiche.push(wCurrent);
    }

    this.props.dispatch(userActions.saveNotifiche(user.id_utente, notifiche));
  }

  getFormFieldValue(pFieldName) {
    const { users } = this.props;

    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    }

    let wReturn = {};

    wReturn.notifica_priorita_standard = false;
    wReturn.notifica_priorita_urgente = false;
    wReturn.notifica_riepilogo_settimana = false;
    wReturn.notifica_riepilogo_giorno = false;

    //CAMUSI 11062020 inizio
    let wNotifiche = '';

    if (users && users.current) {
      wNotifiche = users.current.notifiche;
    }
    //CAMUSI 11062020 fine

    //let wNotifiche = user.notifiche; //ORIGINALE CAMUSI 11062020

    if (typeof wNotifiche !== 'undefined' && wNotifiche !== null && wNotifiche !== []) {
      for (var i = 0; i < wNotifiche.length; i++) {
        if (wNotifiche[i].id_tipologia_notifica === 1 && wNotifiche[i].priorita === 'A') {
          wReturn.notifica_priorita_urgente = true;
        } else if (wNotifiche[i].id_tipologia_notifica === 1 && wNotifiche[i].priorita === 'B') {
          wReturn.notifica_priorita_standard = true;
        } else if (wNotifiche[i].id_tipologia_notifica === 2 && wNotifiche[i].frequenza === '1G') {
          wReturn.notifica_riepilogo_giorno = true;
        } else if (wNotifiche[i].id_tipologia_notifica === 2 && wNotifiche[i].frequenza === '1S') {
          wReturn.notifica_riepilogo_settimana = true;
        }
      }
    }

    return wReturn[pFieldName];
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    const { value, checked } = event.target;

    let wState = {
      stateName: value,
    };

    //CAMUSI 08072020 inizio
    if (stateName === 'notifica_priorita_standard') {
      wState = {
        notifica_priorita_standard: checked,
      };

      // if (checked) {
      //     wState["notifica_priorita_urgente"] = false;
      // }
    }
    if (stateName === 'notifica_priorita_urgente') {
      wState = {
        notifica_priorita_urgente: checked,
      };

      // if (checked) {
      //     wState["notifica_priorita_standard"] = false;
      // }
    }
    //CAMUSI 08072020 fine

    if (stateName === 'notifica_riepilogo_settimana') {
      wState = {
        notifica_riepilogo_settimana: checked,
      };

      if (checked) {
        wState['notifica_riepilogo_giorno'] = false;
      }
    } else if (stateName === 'notifica_riepilogo_giorno') {
      wState = {
        notifica_riepilogo_giorno: checked,
      };

      if (checked) {
        wState['notifica_riepilogo_settimana'] = false;
      }
    }

    this.setState(wState);
  }

  render() {
    const { t, classes, users, tema } = this.props;
    let cardColor = tema.theme_color;
    let loading;
    if (users && users.loading) {
      loading = (
        <div css={overlay}>
          <div css={override}>
            <ClipLoader sizeUnit={'px'} size={80} color={'#3a783d'} loading={users.loading} />
          </div>
        </div>
      );
    }

    let alertMessage = '';
    if (alert && typeof alert.message !== 'undefined') {
      const message = t(`alert.${alert.message}`);
      if (alert.type === 'alert-success') {
        alertMessage = (
          <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
            {message}
          </SweetAlert>
        );
      } else if (alert.type === 'alert-danger') {
        alertMessage = (
          <SweetAlert
            danger
            title={t('alert.warning')}
            onConfirm={() => {
              this.handleCloseAlert();
              if (alert.message === 'sessionExpired') {
                this.props.dispatch(userActions.logout());
              }
            }}
          >
            {message}
          </SweetAlert>
        );
      }
    }

    return (
      <div>
        {loading}
        {alertMessage}
        <GridContainer style={{ width: '100%' }}>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              style={{ marginTop: '18px' }}
              control={
                <Switch
                  color="primary"
                  checked={this.getFormFieldValue('notifica_priorita_standard')}
                  onChange={(event) =>
                    this.change(event, 'notifica_priorita_standard', 'notifica_priorita_standard')
                  }
                  classes={{}}
                />
              }
              classes={{
                label: classes.selectLabel,
              }}
              label={t('user.notifications.standard')}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              style={{ marginTop: '18px' }}
              control={
                <Switch
                  color="primary"
                  checked={this.getFormFieldValue('notifica_priorita_urgente')}
                  onChange={(event) =>
                    this.change(event, 'notifica_priorita_urgente', 'notifica_priorita_urgente')
                  }
                  classes={{}}
                />
              }
              classes={{
                label: classes.selectLabel,
              }}
              label={t('user.notifications.urgent')}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              style={{ marginTop: '18px' }}
              control={
                <Switch
                  color="primary"
                  checked={this.getFormFieldValue('notifica_riepilogo_giorno')}
                  onChange={(event) =>
                    this.change(event, 'notifica_riepilogo_giorno', 'notifica_riepilogo_giorno')
                  }
                  classes={{}}
                />
              }
              classes={{
                label: classes.selectLabel,
              }}
              label={t('user.notifications.oneDay')}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              style={{ marginTop: '18px' }}
              control={
                <Switch
                  color="primary"
                  checked={this.getFormFieldValue('notifica_riepilogo_settimana')}
                  onChange={(event) =>
                    this.change(
                      event,
                      'notifica_riepilogo_settimana',
                      'notifica_riepilogo_settimana'
                    )
                  }
                  classes={{}}
                />
              }
              classes={{
                label: classes.selectLabel,
              }}
              label={t('user.notifications.oneWeek')}
            />
          </GridItem>
          {/** per il profilo utente non è necessaria la verifica della funzionalità */}
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color={cardColor}
              className={classes.updateProfileButton}
              onClick={() => this.handleSubmit()}
            >
              {t('action.save')}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { utils, authentication, users, tema } = state;
  const { user } = authentication ?? {};

  return {
    utils,
    user,
    users,
    tema,
  };
}

const connectedProfiloNotifiche = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(ProfiloNotifiche);
export default connectedProfiloNotifiche;
