import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import RicercaIndicizzata from './RicercaIndicizzata.jsx';
import TabellaRisultati from './TabellaRisultati.jsx';
import GroupBySidebar from './GroupBySidebar.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = () => ({
  ...extendedFormsStyle,
  noPadding: {
    padding: '0px !important',
  },
});

const RisultatiRicerca = React.memo((props) => {
  const { t } = useTranslation();
  const { searchResults } = t('research', { returnObjects: true });
  const { close } = t('action', { returnObjects: true });
  const { classes, resultFuncClass, closeRisultati } = props;
  const { readOnly, closeFileViewer, handleClickOpenRaccoglitore } = resultFuncClass;
  const { handleClickOpenDocumento, handleClickOpenFile } = resultFuncClass;

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      PaperProps={{ style: { maxWidth: '95vw', width: '95vw', height: '92.5vh' } }}
    >
      <DialogTitle>{searchResults}</DialogTitle>
      <DialogContent>
        <GridContainer style={{ padding: '1.5em 0' }}>
          <GridItem xs={2} className={classes.noPadding}>
            <GroupBySidebar />
          </GridItem>
          <GridItem xs={10}>
            <RicercaIndicizzata mascheraRisultati />
            <TabellaRisultati
              readOnly={readOnly}
              closeFileViewer={closeFileViewer}
              handleClickOpenRaccoglitore={handleClickOpenRaccoglitore}
              handleClickOpenDocumento={handleClickOpenDocumento}
              handleClickOpenFile={handleClickOpenFile}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
        <Button color="info" onClick={() => closeRisultati()}>
          <Close style={{ margin: '0px' }} /> {close}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default withStyles(styles)(RisultatiRicerca);
