import { analyticsConstants } from '../Constants';
import { analyticsService } from 'Services';
import { alertActions } from './index';

const setChartRef = (payload) => {
  const request = () => ({
    type: analyticsConstants.SET_CHART_REF,
    payload,
  });

  return (dispatch) => dispatch(request());
};

const setOverallOptions = (options) => {
  const request = () => ({
    type: analyticsConstants.SET_OVERALL_OPTIONS_REQUEST,
    payload: options,
  });

  return (dispatch) => dispatch(request());
};

const setTimeframeOptions = (options) => {
  const request = () => ({
    type: analyticsConstants.SET_TIMEFRAME_OPTIONS_REQUEST,
    payload: options,
  });

  return (dispatch) => dispatch(request());
};

const setCounterOptions = (options) => {
  const request = () => ({
    type: analyticsConstants.SET_COUNTER_OPTIONS_REQUEST,
    payload: options,
  });

  return (dispatch) => dispatch(request());
};

const getOverallAnalysis = (inputOptions) => {
  const request = () => ({ type: analyticsConstants.GET_OVERALL_ANALYSIS_REQUEST });

  const success = (payload) => ({ type: analyticsConstants.GET_OVERALL_ANALYSIS_SUCCESS, payload });

  const failure = (error) => ({ type: analyticsConstants.GET_OVERALL_ANALYSIS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    analyticsService.getOverallAnalysis(inputOptions).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const getTimeframeAnalysis = (inputOptions) => {
  const request = () => ({ type: analyticsConstants.GET_TIMEFRAME_ANALYSIS_REQUEST });

  const success = (payload) => ({
    type: analyticsConstants.GET_TIMEFRAME_ANALYSIS_SUCCESS,
    payload,
  });

  const failure = (error) => ({ type: analyticsConstants.GET_TIMEFRAME_ANALYSIS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    analyticsService.getTimeframeAnalysis(inputOptions).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const getCounterAnalysis = (inputOptions) => {
  const request = () => ({ type: analyticsConstants.GET_COUNTER_ANALYSIS_REQUEST });

  const success = (payload) => ({
    type: analyticsConstants.GET_COUNTER_ANALYSIS_SUCCESS,
    payload,
  });

  const failure = (error) => ({ type: analyticsConstants.GET_COUNTER_ANALYSIS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    analyticsService.getCounterAnalysis(inputOptions).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const getFilteredUsers = () => {
  const request = () => ({ type: analyticsConstants.GET_FILTERED_USERS_REQUEST });

  const success = (payload) => ({
    type: analyticsConstants.GET_FILTERED_USERS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: analyticsConstants.GET_FILTERED_USERS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    analyticsService.getFilteredUsers().then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const getAnalysisDocsDetails = (filters, nextPage) => {
  const request = () => ({ type: analyticsConstants.GET_ANALYSIS_DOCS_DETAILS_REQUEST });

  const success = (payload) => ({
    type: analyticsConstants.GET_ANALYSIS_DOCS_DETAILS_SUCCESS,
    payload,
  });

  const failure = (error) => ({
    type: analyticsConstants.GET_ANALYSIS_DOCS_DETAILS_FAILURE,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    const { pagination, sorting } = getState().analytics.docsDetails;

    analyticsService.getAnalysisDocsDetails(filters, pagination, sorting, nextPage).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const setDocsDetails = (payload) => {
  const request = () => ({
    type: analyticsConstants.SET_DOCS_DETAILS,
    payload,
  });

  return (dispatch) => dispatch(request());
};

const setFilters = (payload) => {
  const request = () => ({
    type: analyticsConstants.SET_FILTERS,
    payload,
  });

  return (dispatch) => dispatch(request());
};

const setPagination = (pageIndex, pageSize) => {
  const payload = { pageIndex };
  if (pageSize) payload.pageSize = pageSize;

  const request = () => ({
    type: analyticsConstants.SET_PAGINATION,
    payload,
  });

  return (dispatch, getState) => {
    dispatch(request());
    const { filters } = getState().analytics.docsDetails;

    dispatch(getAnalysisDocsDetails(filters, true));
  };
};

const setSorting = (payload) => {
  const request = () => ({
    type: analyticsConstants.SET_DOCS_SORTING,
    payload,
  });

  return (dispatch, getState) => {
    dispatch(request());
    const { filters } = getState().analytics.docsDetails;

    dispatch(getAnalysisDocsDetails(filters, true));
  };
};

const resetDocsDetails = () => {
  const request = () => ({ type: analyticsConstants.RESET_DOCS_DETAILS });

  return (dispatch) => dispatch(request());
};

const findAllReports = () => {
  const request = () => ({ type: analyticsConstants.FIND_ALL_REPORTS_REQUEST });
  const success = (payload) => ({ type: analyticsConstants.FIND_ALL_REPORTS_SUCCESS, payload });
  const failure = (error) => ({ type: analyticsConstants.FIND_ALL_REPORTS_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    analyticsService.findAllReports().then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

const downloadReport = (id_report, filename, type) => {
  const request = () => ({ type: analyticsConstants.DOWNLOAD_REPORT_REQUEST });
  const success = () => ({ type: analyticsConstants.DOWNLOAD_REPORT_SUCCESS });
  const failure = (error) => ({ type: analyticsConstants.DOWNLOAD_REPORT_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    analyticsService.downloadReport(id_report, filename, type).then(
      () => dispatch(success()),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const analyticsActions = {
  setChartRef,
  getOverallAnalysis,
  getTimeframeAnalysis,
  getCounterAnalysis,
  setOverallOptions,
  setTimeframeOptions,
  setCounterOptions,
  getFilteredUsers,
  getAnalysisDocsDetails,
  setPagination,
  setSorting,
  setFilters,
  setDocsDetails,
  resetDocsDetails,
  findAllReports,
  downloadReport,
};
