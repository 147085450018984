import { userConstants } from '../Constants';
import { userService } from '../Services/user.oauth2.service.js';
import { uoService } from '../Services/unita_organizzative.service';
import { profiloService } from '../Services/profili.service';
import { alertActions } from './';
import { utilsActions } from './';
import { documentiActions } from './';
import { settingsActions } from './';

export const userActions = {
  token,
  resetUtente,
  resetProfilo,
  resetError,
  login,
  logout,
  register,
  saveUtente,
  getAll,
  getUtenteById,
  deleteUtenteById,
  editSignature,
  deleteSignature,
  saveNotifiche,
  getUtenteLoggedIn,
  getAllUO,
  getAllProfili,
  deleteUObyId,
  deleteProfiloById,
  getUOById,
  getProfiloById,
  saveUO,
  saveProfilo,
  attivaDelegato,
  saveDelegati,
  addEditFirma,
  getUserCompanies,
  getTenantByName,
  getTenantTheme,
  filteredUser,
  setSelectedUser,
  resetSelectedUser,
  setFilter,
  search,
  createNewUser,
  resetSearchResult,
  getVisibleUsers,
};

function resetUtente() {
  return { type: userConstants.USERS_RESET };
}

function resetProfilo() {
  return { type: userConstants.PROFILI_RESET };
}

function resetError() {
  return { type: userConstants.ERROR_RESET };
}

function token(code) {
  userService.token(code);
}

function attivaDelegato(id, idUtenteDelegato, isAttivo) {
  return (dispatch) => {
    dispatch(request());

    userService.attivaDelegato(idUtenteDelegato, isAttivo).then(
      (idUpdate) => {
        dispatch(success(idUpdate));
        dispatch(userActions.getAll());
        dispatch(userActions.getUtenteById(id));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.ATTIVA_DELEGATO_REQUEST };
  }
  function success(idUpdate) {
    return { type: userConstants.ATTIVA_DELEGATO_SUCCESS, idUpdate };
  }
  function failure(error) {
    return { type: userConstants.ATTIVA_DELEGATO_FAILURE, error };
  }
}

function getUtenteById(idUtente) {
  return (dispatch) => {
    dispatch(request());

    userService.getUtenteById(idUtente).then(
      (user) => dispatch(success(user)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USERS_GET_REQUEST };
  }
  function success(user) {
    return { type: userConstants.USERS_GET_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USERS_GET_FAILURE, error };
  }
}

function getUtenteLoggedIn() {
  return (dispatch) => {
    dispatch(request());

    let wCurrentUser = JSON.parse(localStorage.getItem('user'));

    userService.getUtenteById(wCurrentUser.id_utente).then(
      (user) => {
        //localStorage.setItem('user', JSON.stringify(user));
        wCurrentUser.firma = user.firma;
        localStorage.setItem('user', JSON.stringify(wCurrentUser));
        dispatch(updateLoggedUser(wCurrentUser));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.USERS_GET_REQUEST };
  }
  function failure(error) {
    return { type: userConstants.USERS_GET_FAILURE, error };
  }
  function updateLoggedUser(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
}

function deleteUtenteById(idUtente) {
  return (dispatch) => {
    dispatch(request());

    userService.deleteUtenteById(idUtente).then(
      (utente) => {
        dispatch(success(utente));
        dispatch(userActions.getAll());
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_UTENTE_REQUEST };
  }
  function success(utente) {
    return { type: userConstants.DELETE_UTENTE_SUCCESS, utente };
  }
  function failure(error) {
    return { type: userConstants.DELETE_UTENTE_FAILURE, error };
  }
}

function deleteSignature(idFirma) {
  return (dispatch) => {
    dispatch(request());

    userService.deleteSignature(idFirma).then(
      (firma) => {
        dispatch(userActions.getUtenteLoggedIn());
        dispatch(success(firma));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_FIRMA_REQUEST };
  }
  function success(firma) {
    return { type: userConstants.DELETE_FIRMA_SUCCESS, firma };
  }
  function failure(error) {
    return { type: userConstants.DELETE_FIRMA_FAILURE, error };
  }
}

function login(username, password, selectedCompany, history) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password, selectedCompany).then(
      (user) => {
        dispatch(success(user));
        //1 = ADMIN - 0 = !ADMIN
        const isAdmin = user.ruoli.filter((ruolo) => ruolo.cod_ruolo == 'ADMIN').length === 1;
        if (!isAdmin) {
          dispatch(settingsActions.getMaintenance(false, history));
        } else history.push('/auth');
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(documentiActions.resetDelegante());
    dispatch(logoutSuccess());
  };
  function logoutSuccess() {
    userService.logout();
    return { type: userConstants.LOGOUT };
  }
}

function addEditFirma(value) {
  return { type: userConstants.ADD_EDIT_FIRMA, value };
}

function getUserCompanies(username) {
  return (dispatch) => {
    dispatch(request());

    userService.getUserCompanies(username).then(
      (companies) => dispatch(success(companies)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_COMPANIES_REQUEST };
  }
  function success(companies) {
    return { type: userConstants.GET_USER_COMPANIES_SUCCESS, companies };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_COMPANIES_FAILURE, error };
  }
}

function editSignature(firma) {
  return (dispatch) => {
    dispatch(request(firma));

    userService.editSignature(firma).then(
      (firma) => {
        dispatch(userActions.getUtenteLoggedIn());
        dispatch(success(firma));
        dispatch(alertActions.success('Operazione eseguita con successo'));
        console.log('firma', firma);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.SAVE_FIRMA_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SAVE_FIRMA_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SAVE_FIRMA_FAILURE, error };
  }
}

function saveNotifiche(idUtente, notifiche) {
  return (dispatch) => {
    dispatch(request(notifiche));

    userService.saveNotifiche(idUtente, notifiche).then(
      (user) => {
        let wCurrentUser = JSON.parse(localStorage.getItem('user'));
        if (wCurrentUser.id_utente === idUtente) {
          wCurrentUser.notifiche = notifiche;

          localStorage.setItem('user', JSON.stringify(wCurrentUser));
          dispatch(updateLoggedUser(wCurrentUser));
        }

        dispatch(success(notifiche));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function updateLoggedUser(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function success(notifiche) {
    return { type: userConstants.REGISTER_SUCCESS, notifiche };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function saveUO(uo) {
  return (dispatch) => {
    dispatch(request(uo));

    uoService.saveUO(uo).then(
      (uo) => {
        dispatch(success(uo));
        dispatch(utilsActions.getUnitaOrganizzative());
        dispatch(userActions.getAllUO());
        dispatch(userActions.getUOById(uo.id_uo_group));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(uo) {
    return { type: userConstants.UO_SAVE_REQUEST, uo };
  }
  function success(uo) {
    return { type: userConstants.UO_SAVE_SUCCESS, uo };
  }
  function failure(error) {
    return { type: userConstants.UO_SAVE_FAILURE, error };
  }
}

//CAMUSi 02072020 inizio
function saveProfilo(profilo) {
  return (dispatch) => {
    dispatch(request(profilo)); //VERIFICARE

    profiloService.saveProfilo(profilo).then(
      (profilo) => {
        dispatch(success(profilo)); //VERIFICARE
        dispatch(userActions.getAllProfili());
        dispatch(userActions.getProfiloById(profilo.id_ruolo)); //VERIFICARE
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(profilo) {
    return { type: userConstants.PROFILO_SAVE_REQUEST, profilo };
  }
  function success(profilo) {
    return { type: userConstants.PROFILO_SAVE_SUCCESS, profilo };
  }
  function failure(error) {
    return { type: userConstants.PROFILO_SAVE_FAILURE, error };
  }
}

function saveUtente(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user, true).then(
      (user) => {
        dispatch(success(user));
        dispatch(userActions.getAll());
        dispatch(userActions.getUtenteById(user));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function saveDelegati(idUtente, delegatiList) {
  return (dispatch) => {
    dispatch(request());

    userService.saveDelegati(idUtente, delegatiList).then(
      (esito) => {
        dispatch(success(esito));
        dispatch(userActions.getVisibleUsers());
        dispatch(userActions.getUtenteById(idUtente));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.SAVE_DELEGATI_REQUEST };
  }
  function success(esito) {
    return { type: userConstants.SAVE_DELEGATI_SUCCESS, esito };
  }
  function failure(error) {
    return { type: userConstants.SAVE_DELEGATI_FAILURE, error };
  }
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    let wEditedUser = user;
    userService.register(user).then(
      (user) => {
        let wCurrentUser = JSON.parse(localStorage.getItem('user'));
        if (wCurrentUser.id_utente === user.id_utente) {
          wCurrentUser.nome = wEditedUser.nome;
          wCurrentUser.cognome = wEditedUser.cognome;
          wCurrentUser.telefono = wEditedUser.telefono;
          wCurrentUser.email = wEditedUser.email;
          wCurrentUser.codice_fiscale = wEditedUser.codice_fiscale;

          localStorage.setItem('user', JSON.stringify(wCurrentUser));
          dispatch(updateLoggedUser(wCurrentUser));
        }

        dispatch(success(user));
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function updateLoggedUser(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getVisibleUsers() {
  return (dispatch) => {
    dispatch(request());

    userService.getVisibleUsers().then(
      (users) => dispatch(success(users)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USERS_BY_UO_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_USERS_BY_UO_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_USERS_BY_UO_FAILURE, error };
  }
}

function getAllUO() {
  return (dispatch) => {
    dispatch(request());

    uoService.getAll().then(
      (items) => dispatch(success(items)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UO_GETALL_REQUEST };
  }
  function success(items) {
    return { type: userConstants.UO_GETALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.UO_GETALL_FAILURE, error };
  }
}

function getAllProfili() {
  return (dispatch) => {
    dispatch(request());

    profiloService.getAll().then(
      (profili) => dispatch(success(profili)), //VERIFICARE "profili" ci stava "users"
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.PROFILI_GETALL_REQUEST };
  }
  function success(profili) {
    return { type: userConstants.PROFILI_GETALL_SUCCESS, profili };
  } //VERIFICARE "profili" ci stava "users"
  function failure(error) {
    return { type: userConstants.PROFILI_GETALL_FAILURE, error };
  }
}

function getUOById(idUO) {
  return (dispatch) => {
    dispatch(request());

    uoService.getUOById(idUO).then(
      (uo) => dispatch(success(uo)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UO_GET_REQUEST };
  }
  function success(uo) {
    return { type: userConstants.UO_GET_SUCCESS, uo };
  }
  function failure(error) {
    return { type: userConstants.UO_GET_FAILURE, error };
  }
}

function getProfiloById(idProfilo) {
  return (dispatch) => {
    dispatch(request());

    profiloService.getProfiloById(idProfilo).then(
      (profilo) => dispatch(success(profilo)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.PROFILO_GET_REQUEST };
  }
  function success(profilo) {
    return { type: userConstants.PROFILO_GET_SUCCESS, profilo };
  }
  function failure(error) {
    return { type: userConstants.PROFILO_GET_FAILURE, error };
  }
}

function deleteUObyId(idUO) {
  return (dispatch) => {
    dispatch(request());

    uoService.deleteUObyId(idUO).then(
      (uo) => {
        dispatch(success(uo));
        dispatch(userActions.getAllUO());
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UO_DELETE_REQUEST };
  }
  function success(uo) {
    return { type: userConstants.UO_DELETE_SUCCESS, uo };
  }
  function failure(error) {
    return { type: userConstants.UO_DELETE_FAILURE, error };
  }
}

function deleteProfiloById(idProfilo) {
  return (dispatch) => {
    dispatch(request());

    profiloService.deleteProfiloById(idProfilo).then(
      (profilo) => {
        dispatch(success(profilo));
        dispatch(userActions.getAllProfili());
        dispatch(alertActions.success('Operazione eseguita con successo'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.PROFILO_DELETE_REQUEST };
  }
  function success(profilo) {
    return { type: userConstants.PROFILO_DELETE_SUCCESS, profilo };
  }
  function failure(error) {
    return { type: userConstants.PROFILO_DELETE_FAILURE, error };
  }
}
//CAMUSI 02072020 fine

function getTenantByName(tenantName) {
  return (dispatch) => {
    dispatch(request());
    userService.getTenantByName(tenantName).then(
      (tenant) => {
        dispatch(userActions.getTenantTheme(tenant));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_ID_TENANT_REQUEST };
  }
  function failure(error) {
    return { type: userConstants.GET_ID_TENANT_FAILURE, error };
  }
}

function getTenantTheme(tenant) {
  return (dispatch) => {
    dispatch(request());
    userService.getTenantTheme(tenant.id_company).then(
      (theme) => {
        dispatch(success({ theme: theme, company: tenant }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_TENANT_THEME_REQUEST };
  }
  function success(tenant) {
    return { type: userConstants.GET_TENANT_THEME_SUCCESS, tenant };
  }
  function failure(error) {
    return { type: userConstants.GET_TENANT_THEME_FAILURE, error };
  }
}

function filteredUser(filter) {
  return (dispatch) => {
    dispatch(request());
    userService.filteredUser(filter).then(
      (users) => dispatch(success(users)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_FILTERED_USERS_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GET_FILTERED_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GET_FILTERED_USERS_FAILURE, error };
  }
}

function setSelectedUser(user) {
  return { type: userConstants.SET_SELECTED_USER, user };
}

function resetSelectedUser() {
  return { type: userConstants.RESET_SELECTED_USER };
}

function resetSearchResult() {
  return { type: userConstants.RESET_SEARCH_RESULT };
}

function setFilter(name, value) {
  return { type: userConstants.SET_FILTER, name, value };
}

function search(nome, cognome, email) {
  const request = () => ({ type: userConstants.SEARCH_USER_REQUEST });
  const success = (trovati) => ({
    type: userConstants.SEARCH_USER_SUCCESS,
    trovati,
  });
  const failure = (error) => ({ type: userConstants.SEARCH_USER_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.search(nome, cognome, email).then(
      (results) => {
        dispatch(success(results));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function createNewUser(user) {
  const request = () => ({ type: userConstants.CREATE_USER_REQUEST });
  const success = (user) => ({
    type: userConstants.CREATE_USER_SUCCESS,
    user,
  });
  const failure = (error) => ({ type: userConstants.CREATE_USER_FAILURE, error });

  return (dispatch) => {
    dispatch(request());
    userService.createNewUser(user).then(
      (newUser) => {
        dispatch(success(newUser));
        dispatch(userActions.getVisibleUsers());
        dispatch(utilsActions.getUnitaOrganizzative());
        dispatch(userActions.setSelectedUser(extractUserForSelect(newUser)));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function extractUserForSelect(user) {
  let result = {
    codice_fiscale: user.codice_fiscale,
    cognome: user.cognome,
    dismessa: user.dismessa,
    email: user.email,
    id_unita_organizzative: user.unita_organizzative[0].id_uo_group,
    id_utente: user.id_utente,
    ids_uo_visibili: user.ids_uo_visibili,
    nome: user.nome,
    telefono: user.telefono,
    unita_organizzative: user.unita_organizzative[0].nome,
    username: user.username,
    utente: user.nome + ' ' + user.cognome,
  };
  return result;
}
