import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'components/CustomButtons/Button.jsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import Close from '@material-ui/icons/Close';
import SignDocument from '@material-ui/icons/BorderColor';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { DialogTitle } from '@material-ui/core';

import FormValidationHelper from '../../Helpers/form-validation.js';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  ...extendedFormsStyle,
  ...regularFormsStyle,
};

const initialState = {
  username: '',
  password: '',
  usernameState: undefined,
  passwordState: undefined,
};

class VistoModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  handleClose() {
    this.setState(initialState);
    this.props.onClose();
  }

  handleSubmit() {
    let usernameState = FormValidationHelper.validateField('required', this.state.username, 1);
    let passwordState = FormValidationHelper.validateField('required', this.state.password, 1);

    this.setState({
      usernameState: usernameState,
      passwordState: passwordState,
    });

    if (usernameState === 'error' || passwordState === 'error') {
      return;
    } else {
      let returnData = {
        username: this.state.username,
        password: this.state.password,
      };

      this.props.onSubmit(returnData);

      this.handleClose();
    }
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    const { id, value } = event.target;

    this.setState({ [id]: value });
    switch (type) {
      case 'required':
        this.setState({
          [stateName + 'State']: FormValidationHelper.validateField(
            'required',
            event.target.value,
            1
          ),
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { t, classes } = this.props;

    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        style={{ zIndex: 99999 }}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle>Inserisci i dati di autenticazione per vistare i documenti:</DialogTitle>
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                success={this.state.usernameState === 'success'}
                error={this.state.usernameState === 'error'}
                labelText="Username"
                id="username"
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  onChange: (event) => this.change(event, 'username', 'required'),
                  type: 'username',
                  value: this.state.username,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                success={this.state.passwordState === 'success'}
                error={this.state.passwordState === 'error'}
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  onChange: (event) => this.change(event, 'password', 'required'),
                  type: 'password',
                  value: this.state.password,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          <Button color="success" onClick={() => this.handleSubmit()}>
            <SignDocument /> Vista
          </Button>
          <Button color="info" onClick={() => this.handleClose()}>
            <Close /> {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, raccoglitori, utils } = state;

  return {
    authentication,
    alert,
    raccoglitori,
    utils,
  };
}

const connectedVistoModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(VistoModalForm);
export default connectedVistoModalForm;
