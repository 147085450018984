import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Filters from './Filters';

const Toolbar = () => {
  const { t } = useTranslation();
  const { generalFilters } = t('analytics', { returnObjects: true });
  const cardColor = useSelector(({ tema }) => tema.theme_color);

  return (
    <Card>
      <CardHeader style={{ width: 'fit-content' }} color={cardColor}>
        <Typography
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            fontSize: '0.65rem',
          }}
        >
          {generalFilters}
        </Typography>
      </CardHeader>
      <CardBody>
        <Filters />
      </CardBody>
    </Card>
  );
};

export default Toolbar;
