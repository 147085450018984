import React from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';

// @material-ui/core components
import { Paper, withStyles } from '@material-ui/core';

// @material-ui/icons
import CheckDocument from '@material-ui/icons/AssignmentTurnedIn';
import SignDocument from '@material-ui/icons/BorderColor';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';

// custom components
import RaccoglitoreModalForm from '../../Custom/Raccoglitore/RaccoglitoreModalForm.jsx';
import DocumentoModalForm from '../../Custom/Documento/DocumentoModalForm.jsx';
import FileModalForm from '../../Custom/Documento/FileModalForm.jsx';
import ListaDocumenti from 'Custom/Documento/ListaDocumenti.jsx';
import FilterSwitch from 'Custom/Documento/FilterSwitch.jsx';
import RicercaIndicizzata from 'Custom/Documento/Ricerca/RicercaIndicizzata.jsx';
import SelectSearch from 'Custom/SelectSearch.jsx';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  raccoglitoriActions,
  utilsActions,
  documentiActions,
  alertActions,
  userActions,
  searchActions,
} from '../../Actions';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const overlay = css`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

const styles = {
  ...extendedFormsStyle,
  tc_sign_btn_confirm: {
    'background-color': '#4caf50 !important',
  },
  tcsign_delegante: {
    padding: '20px!important',
    marginBottom: '50px',
  },
};

const size = process.env.REACT_APP_SEARCH_SIZE;
class DocumentsTables extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      documento: undefined,
      uuidDocumento: undefined,
      showFile: false,
      showRaccoglitore: false,
      showDocumento: false,
      selectedDocumento: undefined,
      selectedRaccoglitore: undefined,
      filters: [false, false],
    };
  }

  componentDidMount() {
    localStorage.removeItem('filters');
    this.getData();
    let ambito = this.getAmbito();
    let checked = ambito === 'Archive' ? true : false;
    this.props.dispatch(searchActions.setAmbito(ambito, checked));
  }

  getData() {
    const { user } = this.props;
    let wFilter = JSON.parse(localStorage.getItem('filters'));
    let ambito = this.getAmbito();

    if (wFilter === null) {
      wFilter = {
        query: {
          must: [],
          range: {},
        },
        ambito,
        from: 0,
        size,
        sort: [
          {
            data_ult_modif: { order: 'desc' },
          },
        ],
      };
    }
    this.props.dispatch(documentiActions.getAll(wFilter, user.ruoli));
    if (user?.ruoli && user.ruoli.filter(({ cod_ruolo }) => cod_ruolo === 'ADMIN').length > 0)
      this.props.dispatch(raccoglitoriActions.getAll());
    this.props.dispatch(utilsActions.getUnitaOrganizzative());
    this.props.dispatch(utilsActions.getTipologiaDocumento());
    this.props.dispatch(utilsActions.getTipologiaRaccoglitore());
    this.props.dispatch(utilsActions.getStatoDocumento());
    //this.props.dispatch(userActions.getAll());
  }

  getAmbito() {
    const { pathname } = this.props.location;
    if (pathname === '/auth/mydocuments') return 'Created';
    else if (pathname === '/auth/flagged') return 'Attentioned';
    else if (pathname === '/auth/refused') return 'Rejected';
    else if (pathname === '/auth/signedchecked') return 'Signed';
    else if (pathname === '/auth/delegated') return 'Delegati';
    else if (pathname === '/auth/incoming') return 'Incoming';
    else if (pathname === '/auth/archive') return 'Archive';
    else if (pathname === '/auth/deleted') return 'Deleted';
    else if (pathname === '/auth/extdocuments') return 'External';

    return '';
  }

  handleClickFilter = (event, index) => {
    const { checked } = event.target;

    const filters = [false, false];
    filters[index] = checked;

    this.setState({
      filters,
    });

    this.handleClickSearch(filters);
  };

  handleClickSearch = (switchVal) => {
    const { user } = this.props;
    let filters = {
      query: {
        must: [],
        range: {},
      },
      ambito: '',
      from: 0,
      size,
      sort: [
        {
          data_ult_modif: { order: 'desc' },
        },
      ],
    };

    filters.ambito = this.getAmbito();

    const must = [];

    //parameter coming from handleClickFilter
    if (switchVal) {
      if (switchVal[0]) {
        must.push({
          term: { id_stato_flusso_op: 3 },
        });
      } else if (switchVal[1]) {
        must.push({
          term: { id_stato_flusso_op: 4 },
        });
      }
    }
    filters.query.must = must;

    this.props.dispatch(documentiActions.search(filters, user.ruoli));
  };

  checkViewToolbar = () => {
    const { pathname } = this.props.location;
    return !(
      pathname === '/auth/flagged' ||
      pathname === '/auth/refused' ||
      pathname === '/auth/signedchecked' ||
      pathname === '/auth/delegated' ||
      pathname === '/auth/incoming' ||
      pathname === '/auth/deleted'
    );
  };

  handleClickOpenDocumento = (idDocumento, uuid) => {
    this.setState({
      showDocumento: true,
      selectedDocumento: idDocumento,
      uuidDocumento: uuid,
    });
  };

  closeFileViewer = () => {
    this.setState({
      showFile: false,
    });
  };

  handleClickOpenRaccoglitore = (idRaccoglitore) => {
    this.setState({
      showRaccoglitore: true,
      selectedRaccoglitore: idRaccoglitore,
    });
    this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
  };

  handleClickOpenFile = (doc) => {
    const { documenti } = this.props;

    if (doc.id_riunione && doc.id_argomento) {
      /*this.props.dispatch(documentiActions.getMeetingDetails(doc.id_riunione))
            this.props.dispatch(documentiActions.getTopicDetails(doc.id_riunione, doc.id_argomento))*/
      this.props.dispatch(
        documentiActions.getMeetingDetails(documenti.sessionId, doc.id_riunione, doc.id_argomento)
      );
    }
    console.log('doc :>> ', doc);
    this.setState({
      showFile: true,
      uuidDocumento: doc.uuid,
      documento: doc,
    });
  };

  handleCloseAlert = () => {
    this.props.dispatch(alertActions.clear());
  };

  handleClickAction = (documento) => {
    if (documento.id_stato_flusso === 1) {
      this.setState({
        showModalFirma: true,
        selectedDocuments: [documento.id],
      });
    } else if (documento.id_stato_flusso === 2) {
      this.setState({
        showModalVisto: true,
        selectedDocuments: [documento.id],
      });
    }
  };

  handleClickCloseRaccoglitore = () => {
    this.setState({
      showRaccoglitore: false,
      selectedRaccoglitore: undefined,
    });
  };

  handleClickCloseDocumento = () => {
    this.setState({
      showDocumento: false,
      selectedDocumento: undefined,
    });
  };

  handleClickCloseFile() {
    this.setState({
      showFile: false,
      selectedDocumento: undefined,
    });
    this.props.dispatch(documentiActions.getTaskList());
  }

  initUnitaOrganizzative(listUO) {
    return listUO.map((prop, key) => {
      if (typeof prop.children === 'undefined') return prop;
      else return this.initUnitaOrganizzative(prop.children);
    });
  }

  handleClickFlagDocumento(idDocumento, isAttenzionato) {
    this.props.dispatch(
      documentiActions.impostaAttenzionato({
        id: idDocumento,
        attenzionato: !isAttenzionato,
      })
    );
  }

  handleSelectDelegato(event) {
    const { value, label, other } = event.target;
    console.log('Utente delegante selezionato ' + value);
    this.props.dispatch(documentiActions.addDelegante(value, label, other));
    this.handleClickSearch();
  }

  render() {
    const { t, classes, documenti, alert, user, tema } = this.props;
    const { documento, uuidDocumento, showDocumento, selectedDocumento, showFile } = this.state;
    const { selectedRaccoglitore, showRaccoglitore, filters } = this.state;

    let cardColor = tema.theme_color;

    const resultFuncClass = {
      readOnly: this.checkViewToolbar(),
      closeFileViewer: this.closeFileViewer,
      handleClickOpenRaccoglitore: this.handleClickOpenRaccoglitore,
      handleClickOpenDocumento: this.handleClickOpenDocumento,
      handleClickOpenFile: this.handleClickOpenFile,
    };

    let alertMessage = '';

    if (alert?.message) {
      const alertTranslation = `alert.${alert.message}`;
      const message = t(alertTranslation);
      if (alert.type === 'alert-success') {
        alertMessage = (
          <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
            {message}
          </SweetAlert>
        );
      } else if (alert.type === 'alert-danger') {
        alertMessage = (
          <SweetAlert
            danger
            title={t('alert.warning')}
            onConfirm={() => {
              this.handleCloseAlert();
              if (alert.message === 'sessionExpired') {
                this.props.dispatch(userActions.logout());
              }
            }}
          >
            {message}
          </SweetAlert>
        );
      }
    }

    let loading;
    if (documenti?.loading) {
      loading = (
        <div css={overlay}>
          <div css={override}>
            <ClipLoader sizeUnit={'px'} size={80} color={'#3a783d'} loading={documenti.loading} />
          </div>
        </div>
      );
    }

    let wFormRaccoglitore = showRaccoglitore && (
      <RaccoglitoreModalForm
        title={
          !selectedRaccoglitore
            ? t('flow.new')
            : 'Id ' + t('flow.title') + ': ' + selectedRaccoglitore
        }
        open={showRaccoglitore}
        id={selectedRaccoglitore}
        keepMounted
        onClose={() => this.handleClickCloseRaccoglitore()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      />
    );

    let wFormDocumento = showDocumento && (
      <DocumentoModalForm
        title={
          !selectedDocumento
            ? t('document.newDoc')
            : 'Id ' + t('document.doc') + ': ' + selectedDocumento
        }
        id={selectedDocumento}
        readonly={this.checkViewToolbar()}
        uuid={uuidDocumento}
        onClose={() => this.handleClickCloseDocumento()}
      />
    );

    let wFormFile = showFile && (
      <FileModalForm
        uuid={uuidDocumento}
        documento={documento}
        listType="items"
        onClose={() => this.handleClickCloseFile()}
        onSubmitSign={() => this.handleClickAction(documento)}
        onSubmitCheck={() => this.handleClickAction(documento)}
      />
    );

    let delegaOption = [];
    let utenteDelegatiRow;
    if (user?.utente_deleganti?.length > 0) {
      utenteDelegatiRow = _.find(user.utente_deleganti, function (o) {
        return o.tipo_delega === 'V' || o.tipo_delega === 'E';
      });
      if (utenteDelegatiRow)
        for (var i = 0; i < utenteDelegatiRow.length; i++) {
          var option = {};
          var labelDelega =
            utenteDelegatiRow[i].tipo_delega === 'E' ? t('document.byDelegation') : '';
          option['label'] = utenteDelegatiRow[i].utente + labelDelega;
          option['value'] = utenteDelegatiRow[i].id_utente;
          option['other'] = utenteDelegatiRow[i].tipo_delega;
          delegaOption.push(option);
        }
    }

    let delega =
      delegaOption.length > 0 ? (
        <Paper className={classes.tcsign_delegante}>
          <SelectSearch
            label={t('document.verifyOnBehalf')}
            placeholder={t('delegates.selectUser')}
            options={delegaOption}
            isClearable={true}
            id={'id_utente_delegante'}
            isSearchable={true}
            value={documenti.deleganteSelected.id_delegante}
            onChange={(e) => this.handleSelectDelegato(e)}
          />
        </Paper>
      ) : null;

    const switchStyle = {
      width: '24px',
      height: '24px',
      marginRight: '5px',
      marginLeft: '5px',
    };

    const switchList = [
      {
        title: t('document.signed'),
        color: 'success',
        icon: <SignDocument style={switchStyle} />,
        switchColor: 'green',
        checked: filters[0],
        onChange: (event) => this.handleClickFilter(event, 0),
      },
      {
        title: t('document.viewed'),
        color: 'info',
        icon: <CheckDocument style={switchStyle} />,
        switchColor: 'blue',
        checked: filters[1],
        onChange: (event) => this.handleClickFilter(event, 1),
      },
    ];

    return (
      <div>
        {delega}
        <GridContainer>
          {loading}
          {alertMessage}
          {wFormRaccoglitore}
          {wFormDocumento}
          {wFormFile}
          <RicercaIndicizzata resultFuncClass={resultFuncClass} />
          {this.props.location.pathname === '/auth/signedchecked' &&
            switchList.map((filter) => {
              const { title, color, icon, checked, onChange, switchColor } = filter;
              return (
                <FilterSwitch
                  key={title}
                  color={color}
                  title={title}
                  icon={icon}
                  switchColor={switchColor}
                  checked={checked}
                  onChange={onChange}
                />
              );
            })}
          <ListaDocumenti
            readOnly={this.checkViewToolbar()}
            temaColor={cardColor}
            closeFileViewer={this.closeFileViewer}
            handleClickOpenRaccoglitore={this.handleClickOpenRaccoglitore}
            handleClickOpenDocumento={this.handleClickOpenDocumento}
            handleClickOpenFile={this.handleClickOpenFile}
          />
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, documenti, tema } = state;
  return {
    user: authentication?.user ?? {},
    alert,
    documenti,
    tema,
  };
}

const connectedDocumentsGrid = compose(
  connect(mapStateToProps),
  withTranslation(),
  withStyles(styles)
)(DocumentsTables);
export default connectedDocumentsGrid;
