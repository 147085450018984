import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ClipLoader } from 'react-spinners';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import { Search, Folder, Close } from '@material-ui/icons';
import Check from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import RaccoglitoreModalForm from '../../Custom/Raccoglitore/RaccoglitoreModalForm.jsx';

// assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx';

import Tooltip from '@material-ui/core/Tooltip';

import { raccoglitoriActions, utilsActions, alertActions, userActions } from '../../Actions';
import { ModelViewHelper, Authorize } from 'Helpers/model-view.js';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  sweetAlertStyle,
  notificationsStyle,
  tcsign_action: {
    //"padding": "0px!important",
    //"margin": "0px!important",
    width: '20px',
    minWidth: '20px',
  },
};

const overlay = css`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

class RaccoglitoriTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      showRaccoglitore: false,
      selectedRaccoglitore: undefined,
    };
  }

  getData() {
    this.props.dispatch(raccoglitoriActions.getAll());
    this.props.dispatch(utilsActions.getUnitaOrganizzative());
    this.props.dispatch(utilsActions.getTipologiaRaccoglitore());
  }

  componentDidMount() {
    this.getData();
  }

  handleClickDeleteRaccoglitore(idRaccoglitore) {
    const { t } = this.props;
    this.setState({
      closeInfo: false,
      alert: (
        <SweetAlert
          info
          showCancel
          confirmBtnText="Ok"
          confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
          cancelBtnText={t('action.cancel')}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
          title={t('flow.wantDelete')}
          onConfirm={(e) => {
            this.hideAlert();
            this.props.dispatch(raccoglitoriActions.deleteById(idRaccoglitore));
          }}
          onCancel={() => {
            this.hideAlert();
          }}
        ></SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({
      alert: undefined,
      closeInfo: true,
    });
  }

  handleClickOpenRaccoglitore(idRaccoglitore) {
    var x = [];
    x['showRaccoglitore'] = true;
    x['selectedRaccoglitore'] = idRaccoglitore;
    this.setState(x);
    this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
  }

  handleClickCloseRaccoglitore() {
    var x = [];
    x['showRaccoglitore'] = false;
    x['selectedRaccoglitore'] = undefined;
    this.setState(x);
  }

  handleCloseAlert() {
    this.props.dispatch(alertActions.clear());
  }

  render() {
    const { t, classes, raccoglitori, alert, tema } = this.props;
    let cardColor = tema.theme_color;
    let listaRaccoglitori = [];
    if (typeof raccoglitori.items !== 'undefined') {
      listaRaccoglitori = raccoglitori.items;
    }

    let alertMessage = '';
    if (alert?.message) {
      const alertTranslation = `alert.${alert.message}`;
      const message = t(alertTranslation);
      if (alert.type === 'alert-success') {
        alertMessage = (
          <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
            {message}
          </SweetAlert>
        );
      } else if (alert.type === 'alert-danger') {
        alertMessage = (
          <SweetAlert
            danger
            title={t('alert.warning')}
            onConfirm={() => {
              this.handleCloseAlert();
              if (alert.message === 'sessionExpired') {
                this.props.dispatch(userActions.logout());
              }
            }}
          >
            {message}
          </SweetAlert>
        );
      }
    }

    let loading;
    if (raccoglitori && raccoglitori.loading) {
      loading = (
        <div css={overlay}>
          <div css={override}>
            <ClipLoader
              sizeUnit={'px'}
              size={80}
              color={'#3a783d'}
              loading={raccoglitori.loading}
            />
          </div>
        </div>
      );
    }

    let wToolbarCreate = '';
    if (Authorize.checkAuthorizeFunc('FNCCMP', 3)) {
      wToolbarCreate = (
        <div>
          <Button
            color={cardColor}
            size="sm"
            style={{ float: 'right' }}
            onClick={() => this.handleClickOpenRaccoglitore()}
          >
            <Check style={{ margin: '0px' }} /> {t('flow.newOne')}
          </Button>
        </div>
      );
    }

    let wFormRaccoglitore =
      this.state.showRaccoglitore === true ? (
        <RaccoglitoreModalForm
          title={
            typeof this.state.selectedRaccoglitore === 'undefined'
              ? t('flow.new')
              : t('flow.title') + ': ' + this.state.selectedRaccoglitore
          }
          id={this.state.selectedRaccoglitore}
          open={this.state.showRaccoglitore}
          keepMounted
          onClose={() => this.handleClickCloseRaccoglitore()}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        />
      ) : null;

    let wRaccoglitoriData = listaRaccoglitori.map((prop, key) => {
      let wButtonRemoveFolder = '';
      if (Authorize.checkAuthorizeFunc('FNCEPR', 3)) {
        wButtonRemoveFolder = (
          <Tooltip
            id="tooltip-top"
            title={t('flow.delete')}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => this.handleClickDeleteRaccoglitore(wReturn.id)}
              color="danger"
              className={classes.tcsign_action}
            >
              <Close />
            </Button>
          </Tooltip>
        );
      }

      let wButtonViewFolder = '';
      if (Authorize.checkAuthorizeFunc('FNCVPR', 3)) {
        wButtonViewFolder = (
          <Tooltip
            id="tooltip-top"
            title={t('flow.view')}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => this.handleClickOpenRaccoglitore(wReturn.id)}
              color="info"
              className={classes.tcsign_action}
            >
              <Search />
            </Button>
          </Tooltip>
        );
      }

      let wReturn = ModelViewHelper.raccoglitori_ModelToView(prop);
      wReturn.actions = (
        // we've added some custom button actions
        <div className="actions-right">
          {wButtonViewFolder}
          {wButtonRemoveFolder}
        </div>
      );
      return wReturn;
    });

    return (
      <GridContainer>
        {this.state.alert}
        {alertMessage}
        {loading}
        {wFormRaccoglitore}
        <GridItem xs={12}>
          <Card>
            <CardHeader color={cardColor} icon>
              <CardIcon color={cardColor}>
                <Folder />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('flow.list')}</h4>
              {wToolbarCreate}
            </CardHeader>
            <CardBody>
              <ReactTable
                data={wRaccoglitoriData}
                filterable
                columns={[
                  {
                    Header: 'ID',
                    accessor: 'id',
                    maxWidth: 60,
                  },
                  {
                    Header: t('flow.name'),
                    accessor: 'nome',
                    filterMethod: (filter, row) =>
                      row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                  },
                  {
                    Header: t('flow.description'),
                    accessor: 'descrizione',
                    filterMethod: (filter, row) =>
                      row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                  },
                  //{
                  //    Header: "Tipo",
                  //    accessor: "tipo_percorso",
                  //    filterMethod: (filter, row) =>
                  //    row[filter.id].toUpperCase().includes((filter.value.toUpperCase())),
                  //},
                  //{
                  //    Header: "Data Creazione",
                  //    accessor: "data_creazione",
                  //    filterMethod: (filter, row) =>
                  //        row[filter.id].toUpperCase().includes((filter.value.toUpperCase())),
                  //    maxWidth: 180
                  //},
                  {
                    Header: t('flow.modifyDate'),
                    accessor: 'data_ult_modif',
                    filterMethod: (filter, row) =>
                      row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                  },
                  {
                    Header: t('flow.actions'),
                    accessor: 'actions',
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={50}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                striped
                hover
                condensed
                pagination
                insertRow
                deleteRow
                search
                // Text
                previousText={t('table.previous')}
                nextText={t('table.next')}
                loadingText={t('table.loadingTxt')}
                noDataText={t('flow.noFlow')}
                pageText={t('table.next')}
                ofText={t('table.of')}
                rowsText={t('table.rows')}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, raccoglitori, users, tema } = state;
  const { user } = authentication ?? {};
  return {
    user,
    alert,
    raccoglitori,
    users,
    tema,
  };
}

const connectedRaccoglitoriGrid = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(RaccoglitoriTables);
export default connectedRaccoglitoriGrid;
