import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
//material components
import { DialogContent, DialogActions, Tooltip, Checkbox, Link } from '@material-ui/core';
import { withStyles, CircularProgress } from '@material-ui/core';
//custom
import Button from 'components/CustomButtons/Button.jsx';
import Footer from '../reusable/Footer';

//icons
import { FaUpload, FaSignature } from 'react-icons/fa';
import { VscChromeClose, VscTextSize } from 'react-icons/vsc';
import { SIGNATURE_BOX_WIDTH, SIGNATURE_BOX_HEIGHT, utils } from '../../../../Helpers';
import { withSnackbar } from 'notistack';

import { documentiActions } from '../../../../Actions';

import DropzoneComponent from 'react-dropzone-component';
import SignatureCanvas from 'react-signature-canvas';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = () => ({
  signatureWrapper: {
    border: '1px dashed black',
    borderRadius: '5px',
    width: SIGNATURE_BOX_WIDTH * 2,
    height: SIGNATURE_BOX_HEIGHT * 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropzone: {
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    borderRadius: '5px',
  },
  dragover: {
    border: '1px solid blue',
  },
  sigImage: {
    width: '100%',
    height: '120px',
    backgroundColor: 'white',
  },
  actionsRow: {
    margin: '0.75em 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  canvasProps: {
    width: SIGNATURE_BOX_WIDTH * 2,
    height: SIGNATURE_BOX_HEIGHT * 2,
    border: '2px solid black',
    borderRadius: '5px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  root: {
    padding: 0,
    marginRight: '5px',
  },
  hoverLink: {
    cursor: 'pointer',
  },
});

const modes = ['upload', 'draw', 'txt', null];

class SetSignature extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dragover: false,
      checked: false,
      uploadModal: null,
      mode: modes[0],
      signature:
        localStorage.getItem('signature') !== 'txt' ? localStorage.getItem('signature') : null,
    };

    this.djsConfig = {
      maxFiles: 1,
      dictDefaultMessage: 'Clicca o trascina il file',
      addRemoveLinks: false,
      acceptedFiles: 'image/jpeg,image/png',
      autoProcessQueue: false,
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview">
          <div className="dz-details"></div>
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress="true"></span>
          </div>
        </div>
      ),
    };

    this.componentConfig = {
      postUrl: '/no-url',
    };

    this.handleDropzone = {
      dragover: () => {
        this.setState({
          dragover: true,
        });
      },

      dragleave: () => {
        this.setState({
          dragover: false,
        });
      },
      drop: () => {
        this.setState({
          dragover: false,
        });
      },
      addedfile: (file) => {
        if (file.size <= 1000000) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            const signature = fileReader.result;
            /*const regex = /(?<=:)(.*)(?=;)/;
                        const fileType = signature.match(regex)[0];*/
            const fileType = utils.base64MimeType(document);

            if (fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/jpeg')
              this.setState({
                signature,
                mode: modes[3],
              });
            else
              this.props.enqueueSnackbar('Il file deve essere di tipo .png o .jpg', {
                variant: 'warning',
              });
          };
        } else
          this.props.enqueueSnackbar('Il file è troppo grande. La grandezza massima è di un 1 MB', {
            variant: 'warning',
          });
      },
    };

    this.sigCanvas = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.documenti.otp && this.props.documenti.otp) this.props.setPhase(2);

    if (!prevProps.documenti.error && this.props.documenti.error)
      this.props.enqueueSnackbar(this.props.documenti.error, {
        variant: 'error',
      });
  };

  changeMode = (index) => {
    this.setState({
      mode: modes[index],
      signature: index === 2 ? 'txt' : this.state.signature,
    });
  };
  clear = () => {
    this.sigCanvas.clear();
  };

  save = () => {
    const signature = this.sigCanvas.getSignaturePad().toDataURL('image/png');
    this.setState({
      signature,
      mode: modes[3],
    });
  };

  onChangeCheckbox = (event) => {
    const { checked } = event.target;
    this.setState({
      checked,
    });
  };

  sendOTP = () => {
    //send email with otp
    const { signature } = this.state;
    const { documenti } = this.props;
    localStorage.setItem('signature', signature);
    this.props.setSignature(signature);
    if (!documenti.otp || documenti.otp.richieste_otp < 3) {
      const hash_code = window.location.search.replace('?hashcode=', '');
      this.props.dispatch(documentiActions.inviaOTP(hash_code));
      this.props.setPhase(2);
    } else {
      this.props.enqueueSnackbar(`Tentativi di richiesta OTP esauriti`, {
        variant: 'warning',
      });
    }
  };

  render() {
    const { t, classes, firmatario, documenti } = this.props;
    const { signature, checked, mode, dragover } = this.state;
    console.log(documenti.loading);
    const componentConfig = this.componentConfig;
    const djsConfig = this.djsConfig;

    return (
      <>
        {documenti?.loading ? (
          <div className={classes.center}>
            <CircularProgress />{' '}
          </div>
        ) : (
          <>
            <DialogActions disableActionSpacing={true} className={classes.actionsRow}>
              <div>
                <Tooltip title="Carica firma">
                  <Button
                    color="primary"
                    disabled={mode === 'upload' || mode === 'draw' ? true : false}
                    size="sm"
                    component="label"
                    onClick={() => this.changeMode(0)}
                  >
                    <FaUpload />
                  </Button>
                </Tooltip>
                <Tooltip title={mode === 'draw' ? t('action.close') : 'Disegna firma'}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => this.changeMode(mode !== 'draw' ? 1 : 3)}
                  >
                    {mode === 'draw' ? <VscChromeClose /> : <FaSignature />}
                  </Button>
                </Tooltip>
                <Tooltip title="Usa firma testuale">
                  <Button
                    color="primary"
                    size="sm"
                    disabled={mode === 'draw' ? true : false}
                    onClick={() => this.changeMode(2)}
                  >
                    <VscTextSize />
                  </Button>
                </Tooltip>
              </div>
              {mode === 'draw' ? (
                <div style={{ fontWeight: 'bold' }}>Disegna la tua firma</div>
              ) : null}
            </DialogActions>
            <DialogContent
              style={{
                padding: 0,
                width: '100%',
                alignSelf: 'center',
              }}
            >
              {mode === 'draw' ? (
                <>
                  <SignatureCanvas
                    ref={(ref) => {
                      this.sigCanvas = ref;
                    }}
                    canvasProps={{
                      className: classes.canvasProps,
                    }}
                  />
                </>
              ) : mode === 'upload' ? (
                <DropzoneComponent
                  className={`${classes.signatureWrapper} ${classes.dropzone} ${
                    dragover ? classes.dragover : null
                  }`}
                  djsConfig={djsConfig}
                  config={componentConfig}
                  eventHandlers={this.handleDropzone}
                />
              ) : (
                <div className={classes.signatureWrapper}>
                  {signature === 'txt' ? (
                    <p style={{ fontSize: '56px', fontFamily: 'Caveat' }}>{firmatario.label}</p>
                  ) : signature ? (
                    <img src={signature} className={classes.sigImage} alt="signature" />
                  ) : null}
                </div>
              )}
            </DialogContent>
            {mode === 'draw' ? (
              <div className={classes.buttonsWrapper}>
                <Button color="primary" size="sm" onClick={this.save}>
                  {t('action.save')}
                </Button>
                <Button color="primary" size="sm" onClick={this.clear}>
                  Clear
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  classes={{
                    root: classes.root,
                  }}
                  checked={checked}
                  onChange={this.onChangeCheckbox}
                  size="small"
                  color="primary"
                />
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  href="http://localhost:3000/serve/mu_tcsign.pdf"
                  style={{ cursor: 'pointer' }}
                  color="secondary"
                >
                  Accetto di essere legalmente vincolato da questo documento e accetto i Termini
                  &amp; Condizioni di firma
                </Link>
              </div>
            )}
            <Footer
              step={1}
              buttonTitle="Conferma"
              buttonDisabledProp={signature && checked}
              onClickButton={this.sendOTP}
            />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { documenti, alert } = state;
  return {
    documenti,
    alert,
  };
}

const connectedSetSignature = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(SetSignature);
export default withSnackbar(connectedSetSignature);
