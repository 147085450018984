import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import Table from 'components/Table/Table.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import Alarm from '@material-ui/icons/Alarm';
import ChangeUser from '@material-ui/icons/TransferWithinAStation';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Add from '@material-ui/icons/AddCircle';
import Block from '@material-ui/icons/BlockOutlined';
import Button from 'components/CustomButtons/Button.jsx';
import FirmatarioModalForm from './FirmatarioModalForm.jsx';
import ContattoEsternoModalForm from './ContattoEsternoModalForm.jsx';
import { documentiActions } from '../../Actions';
import { Authorize, Constants } from 'Helpers/model-view.js';
import { capitalizeFirstLetter } from '../../Helpers';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = () => ({
  ...extendedFormsStyle,
  tcSignTableButton: {
    margin: '0px',
    padding: '0px',
    height: 'auto!important',
  },
  tc_sign_btn_confirm: {
    'background-color': '#4caf50 !important',
  },
  tcSignTableRow: {
    height: '28px!important',
  },
  tcSignTableHeadRow: {
    height: '28px',
  },
  tcSignTableCell: {
    height: '28px!important',
    opacity: '1',
    fontSize: '14px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: '400',
    lineHeight: '1.42857',
  },
  tcSignTableHead: {
    color: '#AAA !important',
    padding: '14px 0px 0px 0px!important',
    fontSize: '11px!important',
    lineHeight: '1rem!important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  tcSignInputStyle: {
    fontSize: '8px',
  },
  selectLabel: {
    fontSize: '14px',
    color: '#AAA !important',
    top: '8px',
  },
  tcSignSelectControl: {
    margin: '7px 1px 0px 0px !important',
  },
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '0px',
  },
  tcsign_cardbody: {
    'padding-top': '0',
    'padding-bottom': '0',
  },
  tcsign_switchIconChecked: {
    'border-color': '#AAA',
  },
  tcsign_dialog: {
    'max-width': '700px',
  },
  tcsign_action: {
    'padding-left': '20px',
    'padding-right': '20px',
  },
});

class PercorsoFirma extends React.Component {
  state = {
    selected_firma: undefined,
    showModalFirma: false,
    showModalFirmatario: false,
    showModalContatto: false,
    flusso_operativo: undefined,
    editStep: undefined,
    closeInfo: false,
  };

  checkIncoming() {
    const { pathname } = this.props.location ?? {};
    const allowedPaths = ['/auth/incoming', '/auth/grid', '/auth/mydocuments'];

    return !pathname || allowedPaths.includes(pathname);
  }

  getFormFieldValue(pFieldName) {
    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    }

    if (
      typeof this.props.documento !== 'undefined' &&
      typeof this.props.documento[pFieldName] !== 'undefined'
    ) {
      return this.props.documento[pFieldName];
    }

    return undefined;
  }

  handleClickEditStep(idStep) {
    this.setState({
      showModalFirmatario: true,
      editStep: idStep,
    });
  }

  handleOpenModalFirmatario() {
    this.setState({ showModalFirmatario: true });
  }

  handleClickDeleteStep(idStepFlusso) {
    const { documento } = this.props;
    this.props.onDelete(documento.id_documento, idStepFlusso);
  }

  handleCloseModalFirmatario() {
    this.setState({ showModalFirmatario: false });
  }

  handleSubmitModalFirmatario(firmatario, coordinate) {
    const { editStep } = this.state;
    const { id_documento } = this.props.documento;
    const { id_utente, id_uo, nome_utente, nome_uo, firma_visto } = firmatario;

    const wFlussoOperativo = this.getFormFieldValue('flusso_operativo');
    const ordinamento = this.getMaxOrdinamento(wFlussoOperativo);

    const stepFlusso = {
      id_uo,
      id_utente,
      coordinate,
      id_documento,
      utente: nome_utente,
      uo: nome_uo,
      ordinamento: ordinamento + 1,
      flag_firma_vista: firma_visto,
      id_flusso_operativo: editStep,
    };

    if (editStep) {
      const currentFlow = wFlussoOperativo.filter(
        ({ id_flusso_operativo }) => id_flusso_operativo === stepFlusso.id_flusso_operativo
      )[0];

      stepFlusso.ordinamento = currentFlow.ordinamento + 1;
      stepFlusso.data_creazione = currentFlow.data_creazione;
    }

    this.props.dispatch(documentiActions.addStep(stepFlusso));
    this.setState({
      showModalFirmatario: false,
      editStep: undefined,
    });
  }

  getMaxOrdinamento(flussi) {
    let maxOrd = 1;
    if (flussi?.length > 0) {
      for (let i = 0; i < flussi.length; i++) {
        if (flussi[i].ordinamento > maxOrd) maxOrd = flussi[i].ordinamento;
      }
    }
    return maxOrd;
  }

  checkViewToolbar() {
    return typeof this.props.readonly !== 'undefined' ? this.props.readonly : false;
  }

  handleClickSollecito(id_utente, id_uo, id_contatto) {
    this.props.onClickSollecito(id_utente, id_uo, id_contatto);
  }

  checkDefaultCoordinates(coordinate) {
    let result = false;
    if (!coordinate || coordinate?.length === 0) {
      result = true;
    } else {
      coordinate.forEach((element) => {
        if (element.default_coord) result = true;
      });
    }
    return result;
  }

  handleClickActionBlock() {
    this.props.onClickBlock();
  }

  hideAlert() {
    this.setState({
      confirmBlock: undefined,
    });
  }

  render() {
    const { showModalFirmatario, editStep } = this.state;
    const { t, classes, documento } = this.props;

    let percorso = [];
    let wFlussoOperativo = this.getFormFieldValue('flusso_operativo');
    let coordinate = this.getFormFieldValue('coordinate');
    let viewPosizionamento = !this.checkDefaultCoordinates(coordinate);
    let tipoPosizionamento = coordinate?.length > 0 ? 'OBB' : 'FAC';

    if (typeof wFlussoOperativo !== 'undefined') {
      percorso = wFlussoOperativo.map((prop, key) => {
        let wReturn = [
          prop.ordinamento,
          prop.utente_owner && prop.utente_owner !== ' '
            ? capitalizeFirstLetter(prop.utente_owner)
            : prop.uo
            ? prop.uo
            : prop.contatto_owner,
          prop.id_contatto ? t('misc.external') : t('misc.internal'),
          prop.utente_owner == null && prop.data_operazione != null
            ? t(`flowStatus.${prop.desc_stato_flusso_op}`) + ' (' + prop.utente_ult_modif + ')'
            : prop.delegato
            ? `${t(`flowStatus.${prop.desc_stato_flusso_op}`)} (${t('misc.delegating')})`
            : t(`flowStatus.${prop.desc_stato_flusso_op}`),
          prop.data_operazione === null || typeof prop.data_operazione === 'undefined' ? (
            ''
          ) : (
            <Moment parse="YYYY-MM-DDTHH:mm:ss.sssZ" format="DD/MM/YYYY HH:mm:ss">
              {prop.data_operazione}
            </Moment>
          ),
        ];

        if (
          typeof documento !== 'undefined' &&
          (documento.id_stato_documento === Constants.documento.stato.rifiutato ||
            documento.id_stato_documento === Constants.documento.stato.sospeso ||
            documento.id_stato_documento === Constants.documento.stato.inLavorazione)
        ) {
          let wButtonDeleteDisabled = true;

          if (
            (prop.desc_stato_flusso_op === 'In attesa di visto' ||
              prop.desc_stato_flusso_op === 'In attesa di firma') &&
            prop.current_step !== 'S'
          ) {
            wButtonDeleteDisabled = false;
          }

          let wButtonSostituisciDisabled = true;
          if (
            prop.desc_stato_flusso_op === 'In attesa di visto' ||
            prop.desc_stato_flusso_op === 'In attesa di firma'
          ) {
            wButtonSostituisciDisabled = false;
          }

          if (this.checkViewToolbar() && Authorize.checkAuthorizeFunc('FNCCMP')) {
            wReturn.push(
              <Tooltip
                id="tooltip-top"
                title={t('flow.removeFromPath')}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  disabled={wButtonDeleteDisabled}
                  onClick={() => this.handleClickDeleteStep(prop.id_flusso_operativo)}
                  color="danger"
                  className={classes.tcsign_action}
                >
                  <Delete />
                </Button>
              </Tooltip>
            );

            wReturn.push(
              <Tooltip
                id="tooltip-top"
                title={t('flow.replaceInPath')}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  disabled={wButtonSostituisciDisabled}
                  onClick={() => this.handleClickEditStep(prop.id_flusso_operativo)}
                  color="info"
                  className={classes.tcsign_action}
                >
                  <ChangeUser />
                </Button>
              </Tooltip>
            );
          }
          if (
            Authorize.checkAuthorizeFunc('FNCSLC') &&
            (prop.desc_stato_flusso_op === 'In attesa di visto' ||
              prop.desc_stato_flusso_op === 'In attesa di firma') &&
            prop.current_step === 'S' &&
            this.checkIncoming()
          ) {
            wReturn.push(
              <Tooltip
                id="tooltip-top"
                title={t('flow.sendReminder')}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  justIcon
                  round
                  simple
                  disabled={wButtonSostituisciDisabled}
                  onClick={() =>
                    this.handleClickSollecito(prop.id_utente, prop.id_uo, prop.id_contatto)
                  }
                  color="warning"
                  className={classes.tcsign_action}
                >
                  <Alarm />
                </Button>
              </Tooltip>
            );
          }
        }

        return wReturn;
      });
    }

    let btnAdd = '';
    let btnBlock = '';
    let { rifiutato, sospeso, inLavorazione, annullato } = Constants.documento.stato;
    let statiAllowed = [rifiutato, sospeso, inLavorazione];

    if (
      documento &&
      statiAllowed.includes(documento.id_stato_documento) &&
      this.checkViewToolbar() &&
      Authorize.checkAuthorizeFunc('FNCCMP')
    ) {
      btnAdd = (
        <Tooltip
          id="tooltip-top"
          title={t('flow.addNewStep')}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            simple
            onClick={this.handleOpenModalFirmatario.bind(this)}
            color="info"
            className={classes.tcsign_action}
          >
            <Add />
            {t('flow.addStep')}
          </Button>
        </Tooltip>
      );
    }

    if (
      documento &&
      this.checkViewToolbar() &&
      Authorize.checkAuthorizeFunc('FNCBPF') &&
      [inLavorazione].includes(documento.id_stato_documento)
    ) {
      btnBlock = (
        <Tooltip
          id="tooltip-top"
          title={t('flow.block')}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            simple
            onClick={(e) => this.handleClickActionBlock(e)}
            color="danger"
            className={classes.tcsign_action}
          >
            <Block />
            {t('flow.block')}
          </Button>
        </Tooltip>
      );
    }

    let percorsoColumnsHead = [
      '#',
      t('flow.name'),
      t('flow.usrType'),
      t('flow.action'),
      t('flow.date'),
    ];
    let percorsoColumns = [0, 1, 2, 3, 4];
    let percorsoColumnsStyle = [
      classes.center,
      classes.center,
      classes.center,
      classes.left,
      classes.right,
    ];
    if (typeof documento !== 'undefined' && documento.id_stato_documento === 3) {
      percorsoColumnsHead = [
        '#',
        t('flow.name'),
        t('flow.usrType'),
        t('flow.action'),
        t('flow.date'),
        t('flow.actions'),
      ];
      percorsoColumns = [0, 1, 2, 3, 4, 5];
      percorsoColumnsStyle = [
        classes.center,
        classes.center,
        classes.center,
        classes.left,
        classes.right,
        classes.center,
      ];
    }

    return (
      <GridContainer style={{ marginTop: '5px' }}>
        <GridItem xs={12} sm={12} md={12}>
          {showModalFirmatario && (
            <FirmatarioModalForm
              selectedIdUnitaOrganizzativa={undefined}
              documento={documento}
              isEdit={editStep}
              tipoPosizionamento={tipoPosizionamento}
              viewPosizionamento={viewPosizionamento}
              open={showModalFirmatario}
              onClose={() => this.handleCloseModalFirmatario()}
              onSubmit={(firmatario, coordinate) =>
                this.handleSubmitModalFirmatario(firmatario, coordinate)
              }
            />
          )}
          <ContattoEsternoModalForm
            open={this.state.showModalContatto}
            onClose={() => this.handleCloseModalContatto()}
            onSubmit={(value) => this.handleSubmitModalContatto(value)}
          />
          <Table
            hover
            tableHeaderColor="warning"
            tableHead={percorsoColumnsHead}
            tableData={percorso}
            customCellClasses={percorsoColumnsStyle}
            customClassesForCells={percorsoColumns}
            customHeadCellClasses={percorsoColumnsStyle}
            customHeadClassesForCells={percorsoColumns}
          />

          <GridContainer style={{ marginTop: '5px' }}>
            <GridItem xs={12} sm={12} md={12}>
              {btnAdd}
              {btnBlock}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { utils, authentication } = state;
  const { user } = authentication ?? {};

  return {
    utils,
    user,
  };
}

const connectedPercorsoFirma = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(withRouter(PercorsoFirma));
export default connectedPercorsoFirma;
//export default withStyles(styles)(SelectUnitaOrganizzativa);
