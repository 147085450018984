import React from 'react';
import { pdfjs } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import swal from '@sweetalert/with-react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Moment from 'react-moment';
import moment from 'moment';
import _ from 'lodash';

// @material-ui/core components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  withStyles,
  Link,
} from '@material-ui/core/';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Tooltip,
} from '@material-ui/core/';

// custom components
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';

// icons
import { Flag, Close, Add } from '@material-ui/icons/';
import OutFlag from '@material-ui/icons/OutlinedFlag';
import SignDocument from '@material-ui/icons/BorderColor';
import Abort from '@material-ui/icons/ThumbDown';
import CheckDocument from '@material-ui/icons/AssignmentTurnedIn';
import Verify from '@material-ui/icons/HowToReg';
import OutVerify from '@material-ui/icons/HowToRegOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { BiTargetLock } from 'react-icons/bi';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { wMount } from '../../Services/documenti.service';

import { documentiActions, posFirmaActions, raccoglitoriActions } from '../../Actions';

import DocViewer from './DocViewer';
import PosizionamentoFirma from '../Utilities/PosizionamentoFirma';
import { capitalizeFirstLetter, Authorize, ModelViewHelper, authHeader } from '../../Helpers';
import { areArraysOfObjectsEqual, getModifiedCoord, toPDFPixels } from '../../Helpers';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

var pathStatic = process.env.REACT_APP_STATIC_FILE;
pdfjs.GlobalWorkerOptions.workerSrc = wMount + '/' + pathStatic + '/pdf.worker.js';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  ...extendedFormsStyle,
  ...regularFormsStyle,
  margin: {
    margin: '10px',
  },
  delegatoBox: {
    fontWeight: '600',
    border: '2px solid #FF9800',
    backgroundColor: '#fff6ab',
    padding: '5px',
    borderRadius: '15px',
    width: '30%',
    margin: '0 auto',
    textAlign: 'center',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
  },
  verifyBoxOK: {
    fontWeight: '600',
    border: '4px solid #6cbfca',
    padding: '5px',
    borderRadius: '15px',
    width: '30%',
    margin: '0 auto',
    textAlign: 'center',
  },
  verifyBoxKO: {
    fontWeight: '600',
    border: '4px solid #ff005a',
    padding: '5px',
    borderRadius: '15px',
    width: '30%',
    margin: '0 auto',
    textAlign: 'center',
  },
};

const initialState = {
  file: null,
  firma_selected: undefined,
  firma_selectedState: false,
  tipo_file: 'P',
  noteModal: null,
  coordinata: {},
  showSave: false,
  currentIndex: null,
};

class FileModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);
    this.state = { ...initialState, documento: this.props.documento };
  }

  componentDidUpdate = (prevProps) => {
    const { t, documenti, listType, enqueueSnackbar, showRisultati, risultati } = this.props;
    const { currentIndex, documento } = this.state;

    if (
      listType === 'tasks' &&
      documenti.current &&
      !_.isEqual(prevProps.documenti.current, documenti.current)
    ) {
      let currentDoc = documenti.tasks.filter(
        (task) => task.id_documento === documenti.current.id_documento
      )[0];
      let documento = ModelViewHelper.document_ModelToView(currentDoc, t);
      this.setState({
        documento,
      });
    } else if (
      showRisultati &&
      currentIndex &&
      documento.id !== risultati[currentIndex].id_documento
    ) {
      let currentDoc = ModelViewHelper.document_ModelToView(risultati[currentIndex], t);
      this.setState({
        documento: currentDoc,
      });
    }

    let coordinata;
    const prevTags = prevProps.posizionamentoFirma?.tags;
    const currentTags = this.props.posizionamentoFirma?.tags;

    if (prevTags && currentTags) {
      if (currentTags.length > prevTags.length) {
        const { id_coordinata } = this.state.coordinata;

        //get new coord
        const newTag = currentTags.filter((tag) => !tag.id_coordinata);
        if (newTag.length > 0) coordinata = newTag[0];

        //check if it was deleted but not saved or also saved
        let wasCoordDeleted =
          documenti.current.coordinate.filter((coord) => coord.id_coordinata === id_coordinata)
            .length === 0;

        //if coord deletion was not saved reassing id_coordinata
        if (Object.keys(this.state.coordinata).length === 1 && !wasCoordDeleted)
          coordinata.id_coordinata = id_coordinata;
        this.setState({
          coordinata,
          showSave: true,
        });

        if (!this.state.showSave)
          enqueueSnackbar(t('misc.saveUpdate'), {
            variant: 'warning',
          });
      } else if (currentTags.length < prevTags.length) {
        let id_coordinata;

        prevTags.forEach((pTag) => {
          let array = currentTags.filter((cTag) => cTag.id_coordinata === pTag.id_coordinata);
          if (array.length === 0) id_coordinata = pTag.id_coordinata;
        });
        coordinata = { id_coordinata };
        this.setState({
          coordinata,
          showSave: true,
        });
        if (!this.state.showSave)
          enqueueSnackbar(t('misc.saveUpdate'), {
            variant: 'warning',
          });
      } else if (!areArraysOfObjectsEqual(prevTags, currentTags)) {
        coordinata = getModifiedCoord(prevTags, currentTags);
        this.setState({
          coordinata,
          showSave: true,
        });
        if (!this.state.showSave)
          enqueueSnackbar(t('misc.saveUpdate'), {
            variant: 'warning',
          });
      }
    }
  };

  handleClose() {
    this.setState(initialState);
    this.props.dispatch(documentiActions.clearAssociatedMeeting());
    this.props.dispatch(documentiActions.resetDocument());
    this.props.dispatch(posFirmaActions.resetPosizionamentoFirma());
    this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
    this.props.onClose();
  }

  handleSubmitSign() {
    this.props.onSubmitSign(true);
  }

  handleSubmitCheck() {
    this.props.onSubmitCheck(true);
  }

  handleSubmitModifyMetadata() {
    this.props.onSubmitMetadata();
  }

  handleSubmitReject() {
    this.props.onSubmitReject();
  }

  handleClickFlagDocumento(idDocumento, isAttenzionato, livello) {
    this.props.onSubmitAttenziona(idDocumento, isAttenzionato, livello);
  }

  handleClickVerifyDocumento(idDocumento, isVerificato, statoVerifica) {
    //this.props.onSubmitVerify(idDocumento, isVerificato,statoVerifica);
    var id_delegante = this.props.documenti.deleganteSelected.id_delegante;
    let statoV = 'Y';
    let aggiungiModifica = true;
    if (statoVerifica && statoVerifica === 'Y') {
      statoV = 'N';
    }
    if (isVerificato && statoVerifica && statoVerifica === 'N') {
      aggiungiModifica = false;
    }
    var verify = {
      id_documento: idDocumento,
      id_utente_delegante: id_delegante,
      verificato: aggiungiModifica,
      stato: statoV,
    };
    if (statoVerifica && statoVerifica === 'Y') {
      this.openNoteModal(verify);
    } else {
      this.dispatchVerifica(this, verify);
    }
  }

  dispatchVerifica(argThis, verify) {
    argThis.props.dispatch(documentiActions.impostaVerificato(verify));
    argThis.setState({ showFile: false });
    argThis.handleClose();
  }

  openNoteModal(verif) {
    const { t } = this.props;
    this.setState({
      noteModal: (
        <SweetAlert
          input
          inputType="textarea"
          showCancel
          allowEscape
          style={{ display: 'block' }}
          title={t('note.addComment')}
          onConfirm={(e) => this.handleAddNota(e, verif, this.dispatchVerifica)}
          onCancel={() => this.closeNoteModal()}
          validationMsg={t('note.verifyComment')}
          confirmBtnText={t('note.save')}
          cancelBtnText={t('note.close')}
          confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
        />
      ),
    });
  }

  handleAddNota(e, v, callback) {
    this.setState({
      noteModal: null,
    });
    v.note = e;
    callback(this, v);
    return e;
  }

  closeNoteModal() {
    this.setState({
      noteModal: null,
    });
  }

  mostraNote(x) {
    const { t } = this.props;
    swal({
      animation: 'slide-from-top',
      title: x.length > 0 ? t('note.docNote') : t('note.noNote'),
      overflow: 'hidden',
      text: x,
      input: 'text',
      type: 'textarea',
      style: { display: 'block' },
      heght: '100px',
      inputType: 'textarea',
      confirmButtonText: 'Cool',
      showConfirmButton: false,
      showCancelButton: true,
    });
  }

  getFormFieldValue(pFieldName) {
    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    }

    if (
      typeof this.props.documento !== 'undefined' &&
      typeof this.props.documento[pFieldName] !== 'undefined'
    ) {
      return this.props.documento[pFieldName];
    }

    return undefined;
  }

  handleClickOpenOtherFile = (index, listaDoc, prevOrNext) => {
    let currentIndex;
    if (prevOrNext === 'prev') currentIndex = index - 1;
    else currentIndex = index + 1;

    const doc = listaDoc[currentIndex];
    this.setState({ currentIndex });

    let url = {
      url: wMount + '/api/v1/documents/download/' + doc.uuid,
      httpHeaders: { ...authHeader() },
    };
    this.onFileChange(url, doc.id_documento, 'P');
  };

  onFileChange = (url, idDocumento, tipo_file) => {
    this.props.dispatch(documentiActions.getDocumentoById(idDocumento));
    this.setState({ file: url, tipo_file, showSave: false });
  };

  savePos = () => {
    const { coordinata, documento } = this.state;
    const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } =
      this.props.posizionamentoFirma?.dimensions ?? {};

    let coord = JSON.parse(JSON.stringify(coordinata));

    if (Object.keys(coordinata).length > 1) {
      coord = JSON.parse(JSON.stringify(coordinata));
      const pdfCoord = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
        x: coord.left,
        y: coord.top,
      });

      if (coord.default_coord) {
        coord.default_coord = false;
      }

      coord.id_documento = documento.id;
      coord.coordinata_x = pdfCoord.x;
      coord.coordinata_y = pdfCoord.y;
      coord.larghezza_rettangolo = 145;
      coord.altezza_rettangolo = 38;

      delete coord.firmatario;
      delete coord.id;
      delete coord.left;
      delete coord.top;
      delete coord.id_contatto;
    }

    this.props.dispatch(documentiActions.updateInsertCoord(coord, documento.id));
  };

  render() {
    const { t, classes, uuid, documenti, listType, raccoglitori } = this.props;
    const { noteModal, file, tipo_file, documento, showSave } = this.state;
    let fileUrl, listaFirmatariInterni, listaFirmatariEsterni;

    if (uuid)
      fileUrl = {
        url: wMount + '/api/v1/documents/download/' + uuid,
        httpHeaders: { ...authHeader() },
      };

    if (documenti?.current) {
      let currFlow = documenti.current.flusso_operativo.filter(
        (flusso) => flusso.current_step === 'S'
      )[0];
      if (currFlow) {
        const { id_utente, utente_owner, uo, id_uo } = currFlow;

        listaFirmatariInterni = [
          {
            value: capitalizeFirstLetter(utente_owner ?? uo),
            label: capitalizeFirstLetter(utente_owner ?? uo),
            id: id_utente ?? id_uo,
            tipo_firmatario: id_utente ? 'Interno' : 'InternoUO',
          },
        ];

        listaFirmatariEsterni = [];
      }
    }

    var showSign = false,
      showCheck = false,
      showAtt = false,
      showReject = false,
      showMetadata = false,
      showVerify = false,
      incoming = false;

    if (this.props.location.pathname === '/auth/incoming') incoming = true;

    if (!incoming && [1, 2].includes(documento.id_stato_flusso)) {
      if (documento?.id_stato_flusso === 1) {
        showSign = Authorize.checkAuthorizeFunc('FNCFDC') ? true : false;
        showCheck = false;
      } else {
        showCheck = Authorize.checkAuthorizeFunc('FNCVDC') ? true : false;
        showSign = false;
      }

      showReject = Authorize.checkAuthorizeFunc('FNCRDC') ? true : false;
      showMetadata = Authorize.checkAuthorizeFunc('FNCIMS') ? true : false;
      showAtt = Authorize.checkAuthorizeFunc('FNCATD') ? true : false;
    }

    if (documenti.deleganteSelected.id_delegante) showVerify = true;

    let wFlag = <OutFlag />;
    let wFlagTitle = t('yourDashboard.markAsReview');
    let wColor = 'success';
    if (documento?.attenzionato) {
      wFlag = <Flag />;
      if (documento.livello_attenzione === 'medio') {
        wColor = 'warning';
      } else if (documento.livello_attenzione === 'alto') {
        wFlagTitle = t('yourDashboard.reviewUnmark');
        wColor = 'danger';
      }
    }
    var utenteInSessione = JSON.parse(localStorage.getItem('user'));

    let wVerify = <OutVerify />;
    let wVerifyTitle = t('yourDashboard.markVerifyPositive');
    let wVerifyHeader = '';
    let colorVerifyBtn = 'info';
    let customStyle;
    let customStyleBorder;
    if (
      documenti?.current?.verificato &&
      (documenti.current.id_utente_delegante === utenteInSessione.id_utente ||
        documenti?.deleganteSelected?.id_delegante === documenti.current.id_utente_delegante)
    ) {
      if (documenti.current.stato === 'N') {
        wVerify = <Verify />;
        colorVerifyBtn = 'white';
        customStyle = { color: '#3f51b5', border: '1px solid', borderColor: '#3f51b5' };
        customStyleBorder = classes.verifyBoxKO;
        wVerifyHeader = `${t('yourDashboard.markVerifyNeg')} ${
          documenti.current.utente_verifica
        } \n${t('il')} ${documenti.current.data_verifica}\n${t('note')}: ${
          documenti.current.note_verifica
        }`;
        wVerifyTitle = t('yourDashboard.verifyUnmark');
      } else {
        wVerify = <Verify />;
        colorVerifyBtn = 'danger';
        customStyleBorder = classes.verifyBoxOK;
        wVerifyHeader = `${t('yourDashboard.markVerifyPos')} ${
          documenti.current.utente_verifica
        } \n${t('il')} ${documenti.current.data_verifica}`;
        wVerifyTitle = t('yourDashboard.markVerifyNegative');
      }
    }

    let wDelegatoHeader = '';

    // let utenteDeleganteRow;
    if (documenti?.current?.flusso_operativo?.length > 0) {
      //     utenteDeleganteRow = _.find(documenti.current.flusso_operativo, function (o) {
      //         return o.id_stato_flusso_op === 8;
      //     });

      if (_.find(documenti.current.flusso_operativo, { delegato: true })) {
        //wDelegatoHeader = 'Documento in delegaaa - delegante : ' + utenteDeleganteRow.utente_owner;
        wDelegatoHeader = t('yourDashboard.delegateDoc') + documenti.current.utente_owner_flusso;
        console.log('Delegato' + wDelegatoHeader);
      }
    }

    let impegno_spesa = '';
    if (documento?.impegno_spesa)
      impegno_spesa = `- ${t('yourDashboard.spending')} : ${documento.impegno_spesa}`;

    let descrizione = '';
    if (documenti?.current?.descrizione)
      descrizione = `${t('yourDashboard.subject')} : ${documenti.current.descrizione}`;

    var allegati = [];
    var principale = [];
    var _this = this;

    let percorso = [];
    if (documenti?.current?.flusso_operativo?.length > 0) {
      percorso = documenti.current.flusso_operativo.map((prop, key) => {
        const { ordinamento, utente_owner, uo, contatto_owner, id_contatto, data_operazione } =
          prop;
        const { desc_stato_flusso_op, utente_ult_modif, delegato } = prop;
        let wReturn = [
          ordinamento,
          utente_owner && utente_owner !== ' ' ? utente_owner : uo ? uo : contatto_owner,
          id_contatto ? t('misc.external') : t('misc.internal'),
          utente_owner == null && data_operazione != null
            ? t(`flowStatus.${desc_stato_flusso_op}`) + ' (' + utente_ult_modif + ')'
            : delegato
            ? `${t(`flowStatus.${desc_stato_flusso_op}`)} (${t('misc.delegating')})`
            : t(`flowStatus.${desc_stato_flusso_op}`),
          !data_operazione ? (
            ''
          ) : (
            <Moment parse="YYYY-MM-DDTHH:mm:ss.sssZ" format="DD/MM/YYYY HH:mm:ss">
              {data_operazione}
            </Moment>
          ),
        ];
        return wReturn;
      });
    }
    let percorsoColumnsHead = [
      '#',
      t('flow.name'),
      t('flow.usrType'),
      t('flow.action'),
      t('flow.date'),
    ];
    let percorsoColumns = [0, 1, 2, 3, 4];
    let percorsoColumnsStyle = [
      classes.center,
      classes.center,
      classes.center,
      classes.left,
      classes.right,
    ];

    if (documenti?.current?.files?.length > 0) {
      documenti.current.files.forEach((file, index) => {
        const buttonToPush = (
          <Button
            key={index}
            color="primary"
            simple
            onClick={() => _this.onFileChange(fileUrl, file.id_documento, file.tipo_file)}
          >
            {file.file_name}
          </Button>
        );

        if (file.tipo_file === 'P') principale.push(buttonToPush);
        else allegati.push(buttonToPush);
      });
    }

    let elencoNote = '';
    let singolaNota = '';

    if (documenti?.current?.note) {
      var lung = documenti.current.note.length;
      var i;
      for (i = 0; i < lung; i++) {
        var dataCreazione = documenti.current.note[i].data_creazione;

        var dataCreazioneFormattata = moment(dataCreazione).format('DD/MM/YYYY HH:mm');

        singolaNota = `${t('misc.author')} ${documenti.current.note[i].utente} \n ${t(
          'misc.dateHour'
        )} ${dataCreazioneFormattata} \n ${t('misc.text')} ${
          documenti.current.note[i].descrizione
        } \n \n`;
        elencoNote = elencoNote + singolaNota;
      }
    }

    let riunione = '';
    if (documenti.dettaglioRiunione) {
      riunione = (
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ textAlign: 'center', background: '#e0f2f1' }}
          >
            <Typography
              className={classes.heading}
              style={{ width: '100%', color: '#464646', fontWeight: '600' }}
            >
              {t('document.meetingAss')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2} lg={6}>
                  <CustomInput
                    labelText={t('document.meeting')}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: true,
                      value: documenti?.dettaglioRiunione?.title ?? '',
                    }}
                    classes={{ input: classes.tcSignInputStyle }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2} lg={3}>
                  <CustomInput
                    labelText={t('misc.author')}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: true,
                      value: documenti?.dettaglioRiunione?.author ?? '',
                    }}
                    classes={{ input: classes.tcSignInputStyle }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2} lg={3}>
                  <CustomInput
                    labelText={t('misc.date')}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: true,
                      value: documenti?.dettaglioRiunione?.date
                        ? moment(documenti.dettaglioRiunione.date).format('DD/MM/YYYY hh:mm')
                        : '',
                    }}
                    classes={{ input: classes.tcSignInputStyle }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ marginBottom: '40px' }}>
                <GridItem xs={12} sm={12} md={2} lg={6}>
                  <CustomInput
                    labelText={t('document.topic')}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: true,
                      value: documenti?.dettaglioArgomento?.title ?? '',
                    }}
                    classes={{ input: classes.tcSignInputStyle }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {documenti?.dettaglioArgomento?.media && (
                    <Link target="_blank" rel="noopener" href={documenti.dettaglioArgomento.media}>
                      {t('document.downloadDoc')}
                    </Link>
                  )}
                </GridItem>
              </GridContainer>
            </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }

    return (
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onEntering={() => this.onFileChange(fileUrl, documento.id, 'P')}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        data-id="viewer"
      >
        {noteModal}

        <DialogTitle>
          {documenti?.current?.id_documento && <p>ID: {documenti.current.id_documento}</p>}
          {descrizione} <span style={{ color: 'blue' }}>{impegno_spesa}</span>
          <div align="right">
            <Tooltip
              id="tooltip-nota"
              title={t('note.docNote')}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                justIcon
                round
                simple
                onClick={() => this.mostraNote(elencoNote)}
                color="primary"
                className={classes.tcsign_action}
              >
                <InfoOutlinedIcon className="material-icons md-80" />
              </Button>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          {principale.length > 0 && (
            <div>
              {t('document.mainDoc')}: {principale}
            </div>
          )}
          {allegati.length > 0 && (
            <div>
              {t('document.attachments')} : {allegati}
            </div>
          )}
          {wVerifyHeader && (
            <div className={customStyleBorder} style={{ whiteSpace: 'pre-line' }}>
              {wVerifyHeader}
            </div>
          )}
          {wDelegatoHeader && (
            <div className={classes.delegatoBox} style={{ whiteSpace: 'pre-line' }}>
              {wDelegatoHeader}
            </div>
          )}
          <br />
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ textAlign: 'center', background: '#e0edf2' }}
            >
              <Typography
                className={classes.heading}
                style={{ width: '100%', color: '#464646', fontWeight: '600' }}
              >
                {t('flow.title')}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table
                style={{ width: '100%' }}
                hover
                tableHeaderColor="warning"
                tableHead={percorsoColumnsHead}
                tableData={percorso}
                customCellClasses={percorsoColumnsStyle}
                customClassesForCells={percorsoColumns}
                customHeadCellClasses={percorsoColumnsStyle}
                customHeadClassesForCells={percorsoColumns}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {riunione}
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel2a-header"
              style={{ textAlign: 'center', background: '#e8f5e9' }}
            >
              <Typography
                className={classes.heading}
                style={{ width: '100%', color: '#464646', fontWeight: '600' }}
              >
                {t('document.doc')}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ width: '100%', flexWrap: 'wrap' }}
              children={
                file && !documenti.loading && !raccoglitori.loading ? (
                  showSign && tipo_file === 'P' ? (
                    <PosizionamentoFirma
                      file={file}
                      handleClickOpenOtherFile={this.handleClickOpenOtherFile}
                      onFileChange={this.onFileChange}
                      listaFirmatariEsterni={listaFirmatariEsterni}
                      listaFirmatariInterni={listaFirmatariInterni}
                      currentTab="ViewerFirma"
                    />
                  ) : (
                    <DocViewer
                      file={file}
                      handleClickOpenOtherFile={this.handleClickOpenOtherFile}
                      onFileChange={this.onFileChange}
                      listType={listType}
                      documento={documento}
                    />
                  )
                ) : (
                  <></>
                )
              }
            />
          </ExpansionPanel>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          {showSave && (
            <Button color="behance" onClick={this.savePos}>
              <BiTargetLock />
              {t('action.savePos')}
            </Button>
          )}
          {showAtt && tipo_file === 'P' && (
            <Button
              onClick={() =>
                this.handleClickFlagDocumento(
                  documento.id,
                  documento.attenzionato,
                  documento.livello_attenzione
                )
              }
              color={wColor}
              className={classes.tcsign_action}
            >
              {wFlag} {wFlagTitle}
            </Button>
          )}
          {showMetadata && tipo_file === 'P' && (
            <Button color="rose" onClick={() => this.handleSubmitModifyMetadata()}>
              <Add /> {t('action.spending')}
            </Button>
          )}
          {showCheck && this.state.tipo_file === 'P' && (
            <Button color="info" onClick={() => this.handleSubmitCheck()}>
              <CheckDocument /> {t('action.view')}
            </Button>
          )}
          {showVerify && tipo_file === 'P' && (
            <Button
              color={colorVerifyBtn}
              style={customStyle}
              onClick={() =>
                this.handleClickVerifyDocumento(
                  documento.id,
                  documento.verificato,
                  documento.stato_verifica
                )
              }
            >
              {wVerify} {wVerifyTitle}
            </Button>
          )}
          {showSign && tipo_file === 'P' && (
            <Button color="success" onClick={() => this.handleSubmitSign()}>
              <SignDocument /> {t('action.sign')}
            </Button>
          )}
          {showReject && tipo_file === 'P' && (
            <Button
              onClick={() => this.handleSubmitReject()}
              color="danger"
              className={classes.tcsign_action}
            >
              <Abort /> {t('action.reject')}
            </Button>
          )}
          <Button onClick={() => this.handleClose()}>
            <Close /> {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { alert, raccoglitori, documenti, posizionamentoFirma } = state;
  const { showRisultati, risultati } = state.search;

  return {
    alert,
    raccoglitori,
    documenti,
    posizionamentoFirma,
    showRisultati,
    risultati,
  };
}

const connectedFileModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(withRouter(FileModalForm));
export default withSnackbar(connectedFileModalForm);
