import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { raccoglitoriActions, documentiActions, alertActions, userActions } from '../../Actions';
import { wMount } from '../../Services/documenti.service';
import { Dialog, DialogContent, DialogActions, Slide } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from 'components/CustomButtons/Button.jsx';
//icons
import Sign from '@material-ui/icons/Create';
import Download from '@material-ui/icons/CloudDownload';
import Reject from '@material-ui/icons/ThumbDownAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import SweetAlert from 'react-bootstrap-sweetalert';
import { FEA, FirmaDigitale, FD_FEA, TagFirmatario } from './ExternalSignature';
import Wrapper from './ExternalSignature/reusable/Wrapper';

import { toScreenPixels, capitalizeFirstLetter, toPDFPixels } from '../../Helpers';
import DocumentNavigator from '../../Custom/Utilities/DocumentNavigator';

const styles = {
  ...extendedFormsStyle,
  ...regularFormsStyle,
  margin: {
    margin: '10px',
  },
  documentWrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
  },
  fakeCanvasWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 50,
  },
};

var pathStatic = process.env.REACT_APP_STATIC_FILE;
pdfjs.GlobalWorkerOptions.workerSrc = wMount + '/' + pathStatic + '/pdf.worker.js';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const initialState = {
  tags: [],
  signature: null,
  firmatario: null,
  otp: '',
  otpState: undefined,
  showNav: false,
  numPages: null,
  pageNumber: 1,
  rifiutaDocumento: undefined,
  CANVAS_WIDTH: undefined,
  CANVAS_HEIGHT: undefined,
  PDF_WIDTH: undefined,
  PDF_HEIGHT: undefined,
  tipoFirmaApposta: null,
  showFirmaModal: false,
  activeTab: 0,
  tipo_firma: null,
  tipo_file: null,
};

class FirmaEsterna extends Dialog {
  constructor(props, context) {
    super(props, context);

    this.state = initialState;
  }

  componentDidMount() {
    let hash_code = this.props.history.location.search.replace('?hashcode=', '');
    this.setState({ hash_code });
    this.props.dispatch(raccoglitoriActions.recuperaDocumento(hash_code));
  }

  componentDidUpdate = (prevProps) => {
    const { tipo_firma } = this.props.documenti?.firmatario_esterno ?? {};
    const { firmatario_esterno } = prevProps.documenti;

    if (tipo_firma && !firmatario_esterno?.tipo_firma) {
      this.setState({
        tipo_firma,
      });
    }
  };

  static getDerivedStateFromProps = (props, state) => {
    const { documenti } = props;

    if (
      (documenti?.firmatario_esterno && !state.file) ||
      state.firmato !== documenti?.firmatario_esterno?.firmato ||
      state.rifiutato !== documenti?.firmatario_esterno?.rifiutato
    ) {
      const { nome, cognome, azienda, id_contatto } = documenti.firmatario_esterno.firmatario;
      let firmatario = cognome ? nome + ' ' + cognome : azienda;

      state.firmato = documenti.firmatario_esterno.firmato;
      state.rifiutato = documenti.firmatario_esterno.rifiutato;
      let principale = documenti.firmatario_esterno.files.filter(
        (file) => file.tipo_file === 'P'
      )[0];
      state.file = {
        url:
          wMount +
          '/api/v1/documents/download/ext/file?hash_code=' +
          state.hash_code +
          '&uuid=' +
          principale.uuid,
      };
      state.tipo_file = 'P';
      state.pageNumber = 1;
      state.numPages = null;

      state.firmatario = { label: capitalizeFirstLetter(firmatario), id_contatto };
    }

    if (state.CANVAS_WIDTH) {
      const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } = state;
      let tags = [];

      documenti.firmatario_esterno.coordinate.forEach((coord) => {
        let screenCoords = toScreenPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
          x: coord.coordinata_x,
          y: coord.coordinata_y,
        });
        tags.push({
          firmatario: state.firmatario.label,
          coords: { top: screenCoords.y, left: screenCoords.x },
          pagina: coord.pagina,
          id_contatto: coord.id_contatto,
        });
      });

      state.tags = tags;
    }

    return state;
  };

  handleSign() {
    this.setState({
      showFirmaModal: true,
    });
  }

  closeFirmaModal = () => {
    this.setState({
      showFirmaModal: false,
    });
  };

  setSignature = (signature) => {
    this.setState({
      signature,
    });
  };

  onDocumentLoadSuccess(pdf) {
    this.setState({ numPages: pdf.numPages, showNav: true });
  }

  goToPrevPage = (pageNumber) => {
    if (pageNumber && pageNumber > 1)
      this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  };

  goToNextPage = (pageNumber, numPages) => {
    if (numPages && numPages !== pageNumber)
      this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
  };

  setPage = (pageNumber) => {
    this.setState({ pageNumber });
  };

  download = () => {
    //const [file] = this.props.documenti.firmatario_esterno.files;
    const { hash_code, file } = this.state;
    let uuid = file.url.split('uuid=')[1];
    this.props.dispatch(documentiActions.downloadExt(hash_code, uuid));
  };

  fixBootstrapModal() {
    if (document && document.querySelector('[data-id=viewer]')) {
      var modalNode = document.querySelector('[data-id=viewer]').querySelector('[tabindex="-1"]');
      if (!modalNode) return;

      modalNode.removeAttribute('tabindex');
      modalNode.classList.add('js-swal-fixed');
    }
  }

  reject = () => {
    this.fixBootstrapModal();
    this.setState({
      rifiutaDocumento: (
        <SweetAlert
          input
          inputType="textarea"
          showCancel
          allowEscape
          style={{ display: 'block' }}
          title="Prego inserire una nota per il rifiuto del documento."
          onConfirm={(e) => {
            const { firmatario_esterno } = this.props.documenti;
            const { firmatario } = firmatario_esterno;
            const { hash_code } = this.state;
            let docObj = {
              id_azione: 4,
              id_contatto: firmatario.id_contatto,
              nome_contatto: firmatario.nome + ' ' + firmatario.cognome,
              id_documento: [firmatario_esterno.files[0].id_documento],
              nota_rifiuto: e,
            };

            this.setState({ rifiutaDocumento: undefined });
            this.props.dispatch(documentiActions.rifiutaDocumentoExt(docObj, hash_code));
          }}
          onCancel={() => this.setState({ rifiutaDocumento: undefined })}
          validationMsg="Digitare il commento nel campo di testo prima di confermare."
          confirmBtnText="{t('action.save')}"
          cancelBtnText="{t('action.close')}"
          confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
        />
      ),
    });
  };

  applySignature = () => {
    const { tags, signature, CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, hash_code } =
      this.state;

    const { firmatario } = this.props.documenti.firmatario_esterno;
    let tagsShallowCopy = [];

    tags.forEach((tag) => tagsShallowCopy.push({ ...tag }));

    tagsShallowCopy.forEach((tag) => {
      let pdfCoords = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
        x: tag.coords.left,
        y: tag.coords.top,
      });
      tag.x = pdfCoords.x;
      tag.y = pdfCoords.y;
      delete tag.coords;
      delete tag.firmatario;
    });

    let signatureObj = {
      id_azione: 8,
      id_company: firmatario.id_company,
      id_contatto: firmatario.id_contatto,
      nome_contatto: firmatario.cognome
        ? firmatario.nome + ' ' + firmatario.cognome
        : firmatario.azienda,
      file: this.props.documenti.firmatario_esterno.files[0],
      coordinate: tagsShallowCopy,
      firma: {},
    };

    if (signature === 'txt') {
      signatureObj.firma.type = 'txt';
      this.props.dispatch(documentiActions.eseguiAzioneExt(signatureObj, hash_code));
    } else {
      signatureObj.firma.type = 'img';
      signatureObj.firma.img_firma = signature;
      this.props.dispatch(documentiActions.eseguiAzioneExt(signatureObj, hash_code));
    }
  };

  getSize = (page) => {
    const PDFView = page._pageInfo.view;
    const PDF_WIDTH = PDFView[2];
    const PDF_HEIGHT = PDFView[3];
    const canvas = document.getElementsByTagName('canvas')[0];
    const CANVAS_WIDTH = canvas.getBoundingClientRect().width;
    const CANVAS_HEIGHT = canvas.getBoundingClientRect().height;

    this.setState({
      CANVAS_WIDTH,
      CANVAS_HEIGHT,
      PDF_WIDTH,
      PDF_HEIGHT,
    });
  };

  onFileChange = (url, idDocumento, tipo_file) => {
    this.props.dispatch(documentiActions.getDocForExternal(idDocumento));
    this.setState({ file: url, tipo_file, pageNumber: 1 });
  };

  handleCloseAlert = () => {
    this.props.dispatch(alertActions.clear());
  };

  render() {
    const { t, classes, documenti, alert } = this.props;
    const { tags, file, showNav, numPages, pageNumber, firmatario, showFirmaModal, hash_code } =
      this.state;
    const { rifiutaDocumento, CANVAS_HEIGHT, CANVAS_WIDTH, tipo_firma, signature, tipo_file } =
      this.state;
    let tagPagina;

    let alertMessage = '';
    if (alert) {
      const message = t(`alert.${alert.message}`);
      if (alert.type === 'alert-success') {
        alertMessage = (
          <SweetAlert success title="Info" onConfirm={this.handleCloseAlert}>
            {message}
          </SweetAlert>
        );
      } else if (alert.type === 'alert-danger') {
        alertMessage = (
          <SweetAlert
            danger
            title={t('alert.warning')}
            onConfirm={() => {
              this.handleCloseAlert();
              if (alert.message === 'sessionExpired') {
                this.props.dispatch(userActions.logout());
              }
            }}
          >
            {message}
          </SweetAlert>
        );
      }
    }

    if (firmatario && tipo_file === 'P') {
      tagPagina = tags.filter((tag) => tag.id_contatto === firmatario.id_contatto)[0]?.pagina;
    }

    let allegati = [];
    let principale;

    if (documenti?.firmatario_esterno?.files?.length > 0) {
      documenti.firmatario_esterno.files.forEach((file, index) => {
        if (file.tipo_file === 'P')
          principale = (
            <Button
              key={index}
              color="primary"
              simple
              onClick={() =>
                this.onFileChange(
                  {
                    url:
                      wMount +
                      '/api/v1/documents/download/ext/file?hash_code=' +
                      hash_code +
                      '&uuid=' +
                      file.uuid,
                  },
                  file.id_documento,
                  file.tipo_file
                )
              }
            >
              {file.file_name}
            </Button>
          );
        else
          allegati.push(
            <Button
              key={index}
              color="primary"
              simple
              onClick={() =>
                this.onFileChange(
                  {
                    url:
                      wMount +
                      '/api/v1/documents/download/ext/file?hash_code=' +
                      hash_code +
                      '&uuid=' +
                      file.uuid,
                  },
                  file.id_documento,
                  file.tipo_file
                )
              }
            >
              {file.file_name}
            </Button>
          );
      });
    }

    return (
      <>
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          fullScreen
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
          data-id="viewer"
        >
          {alertMessage}
          <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel2a-header"
                style={{ textAlign: 'center', background: '#e8f5e9' }}
              >
                <Typography
                  className={classes.heading}
                  style={{ width: '100%', color: '#464646', fontWeight: '600' }}
                >
                  Documento
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ width: '100%', flexWrap: 'wrap' }}>
                <GridContainer style={{ width: '100%', padding: '0 1em' }} direction="column">
                  <div>Doc. principale : {principale}</div>
                  {allegati.length > 0 && <div>Allegati : {allegati}</div>}
                  <GridItem xs={12} sm={12} md={12}>
                    {showNav && (
                      <DocumentNavigator
                        pageNumber={pageNumber}
                        numPages={numPages}
                        goToPrevPage={this.goToPrevPage}
                        goToNextPage={this.goToNextPage}
                        tagPagina={tagPagina}
                        setTagPagina={(page) => this.setPage(page)}
                      />
                    )}
                    <div className={classes.documentWrapper}>
                      <Document
                        file={file}
                        loading={<div>Caricamento PDF in corso...</div>}
                        onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf)}
                      >
                        <Page
                          className="pdf_viewer"
                          pageNumber={pageNumber}
                          width={960}
                          onLoadSuccess={(page) => this.getSize(page)}
                        />
                      </Document>
                      {CANVAS_WIDTH &&
                      CANVAS_HEIGHT &&
                      !documenti.firmatario_esterno.firmato &&
                      tipo_file === 'P' ? (
                        <div
                          className={classes.fakeCanvasWrapper}
                          style={{ width: CANVAS_WIDTH, height: CANVAS_HEIGHT }}
                        >
                          {tags.length > 0
                            ? tags.map((tag, index) => {
                                if (pageNumber === tag.pagina)
                                  return (
                                    <Fragment key={index}>
                                      <TagFirmatario
                                        firmatario={tag.firmatario}
                                        coords={tag.coords}
                                      />
                                    </Fragment>
                                  );
                                else return null;
                              })
                            : null}
                        </div>
                      ) : null}
                    </div>
                    {showNav && (
                      <DocumentNavigator
                        pageNumber={pageNumber}
                        numPages={numPages}
                        goToPrevPage={this.goToPrevPage}
                        goToNextPage={this.goToNextPage}
                        navPosition="bottom"
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button color="success" onClick={this.download}>
              <Download style={{ marginRight: '10px' }} /> Download
            </Button>
            {!documenti?.firmatario_esterno?.firmato && !documenti.firmatario_esterno?.rifiutato ? (
              <>
                <Button color="danger" onClick={this.reject}>
                  <Reject style={{ marginRight: '10px' }} /> Rifiuta
                </Button>
                <Button color="info" onClick={() => this.handleSign()}>
                  <Sign style={{ marginRight: '10px' }} /> Firma
                </Button>
              </>
            ) : null}
          </DialogActions>
        </Dialog>
        {rifiutaDocumento}
        {showFirmaModal ? (
          <Wrapper close={this.closeFirmaModal} height={tipo_firma === 'FD_FEA' ? '485px' : null}>
            {tipo_firma === 'FEA' ? (
              <FEA
                firmatario={firmatario}
                setSignature={this.setSignature}
                applySignature={this.applySignature}
              />
            ) : tipo_firma === 'FD' ? (
              <FirmaDigitale showTitle={true} />
            ) : tipo_firma === 'FD_FEA' ? (
              <FD_FEA
                documenti={documenti}
                firmatario={firmatario}
                signature={signature}
                setSignature={this.setSignature}
                applySignature={this.applySignature}
              />
            ) : null}
          </Wrapper>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { raccoglitori, documenti, alert } = state;
  return {
    raccoglitori,
    documenti,
    alert,
  };
}

const connectedFirmaEsterna = connect(mapStateToProps)(withStyles(styles)(FirmaEsterna));
export default connectedFirmaEsterna;
