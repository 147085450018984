export const settingsConstants = {
  SET_SETTINGS: 'SET_SETTINGS',

  GET_MAINTENANCE_REQUEST: 'GET_MAINTENANCE_REQUEST',
  GET_MAINTENANCE_SUCCESS: 'GET_MAINTENANCE_SUCCESS',
  GET_MAINTENANCE_FAILURE: 'GET_MAINTENANCE_FAILURE',

  SET_MAINTENANCE_REQUEST: 'SET_MAINTENANCE_REQUEST',
  SET_MAINTENANCE_SUCCESS: 'SET_MAINTENANCE_SUCCESS',
  SET_MAINTENANCE_FAILURE: 'SET_MAINTENANCE_FAILURE',
};
