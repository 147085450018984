import { temaService } from '../Services/tema.service';
import { temaConstants } from '../Constants';
import { alertActions } from './';
import { ThemeUtils } from 'Helpers/themeUtils.js';

export const temaActions = {
  getTemaTenant,
  getTemaByIdDoc,
};

function getTemaByIdDoc(idDoc) {
  return (dispatch) => {
    dispatch(request());

    temaService.getTemaByIdDoc(idDoc).then(
      (tema) => dispatch(success(tema)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: temaConstants.GET_TEMA_BY_ID_DOC_REQUEST };
  }
  function success(tema) {
    return { type: temaConstants.GET_TEMA_BY_ID_DOC_SUCCESS, tema };
  }
  function failure(error) {
    return { type: temaConstants.GET_TEMA_BY_ID_DOC_FAILURE, error };
  }
}

function getTemaTenant(nomeTenant) {
  return (dispatch) => {
    dispatch(request());
    temaService.getTemaTenant(nomeTenant).then(
      (tema) => {
        if (localStorage.getItem('theme_tenant') === 'default')
          localStorage.setItem('theme_tenant', tema.tenant_code);

        let theme_color = ThemeUtils.getThemeColor(tema.tenant_theme, 'tcsign_color', 'card_color');
        tema['theme_color'] = theme_color;
        dispatch(success({ tema }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: temaConstants.GET_TEMA_REQUEST };
  }
  function success(value) {
    return { type: temaConstants.GET_TEMA_SUCCESS, value };
  }
  function failure(error) {
    return { type: temaConstants.GET_TEMA_FAILURE, error };
  }
}
