import { settingsConstants } from '../Constants';

const underMaintenance = localStorage.getItem('underMaintenance');
const initialState = underMaintenance ? { underMaintenance: true } : { loading: true };

export function settings(state = initialState, { type, payload, error }) {
  switch (type) {
    case settingsConstants.SET_SETTINGS:
      return {
        ...state,
        ...payload,
      };
    case settingsConstants.GET_MAINTENANCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case settingsConstants.GET_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        underMaintenance: payload,
      };
    case settingsConstants.GET_MAINTENANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error,
      };
    case settingsConstants.SET_MAINTENANCE_REQUEST:
      return { ...state, loading: true };
    case settingsConstants.SET_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        underMaintenance: payload,
      };
    case settingsConstants.SET_MAINTENANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
}
