import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from '@sweetalert/with-react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

// @material-ui/core
import { Tooltip, Checkbox, withStyles } from '@material-ui/core';

// @material-ui/icons
import {
  Search,
  FolderOpen,
  FileCopy,
  AccountBalance,
  InfoOutlined,
  Folder,
  Check,
  Alarm,
} from '@material-ui/icons';
import Document from '@material-ui/icons/InsertDriveFile';
import Delete from '@material-ui/icons/DeleteForever';
import Play from '@material-ui/icons/PlayCircleOutline';
import Euro from '@material-ui/icons/EuroSymbolOutlined';
import File from '@material-ui/icons/ImportContacts';
import Download from '@material-ui/icons/CloudDownload';
import Abort from '@material-ui/icons/Cancel';
import ArchiveIcon from '@material-ui/icons/Archive';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import { ModelViewHelper, Constants, Authorize } from 'Helpers/model-view.js';
import { documentiActions } from '../../Actions';
import ArchiveModalForm from '../../Custom/Documento/ArchiveModalForm.jsx';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = {
  ...extendedFormsStyle,
  tc_sign_btn_confirm: {
    'background-color': '#4caf50 !important',
  },
  tcsign_action: {
    width: '20px',
    minWidth: '20px',
  },
  tcsign_spesa: {
    width: '20px',
    minWidth: '20px',
    cursor: 'default',
  },
};

class ListaDocumenti extends React.Component {
  state = {
    idUtente: JSON.parse(localStorage.getItem('user')).id_utente,
    alert: undefined,
    sweetAlertTitle: undefined,
    sweetAlertConfirm: undefined,
    showModalArchive: false,
    selectedDocuments: [],
  };

  selectRow = (e, index) => {
    this.setState({
      selected: index,
    });
  };

  handleClickOpenFileNote(note_singolo_doc) {
    const { t } = this.props;
    const { author, dateHour, text } = t('misc', { returnObjects: true });

    let elencoNote = '',
      singolaNota = '';
    var lung = note_singolo_doc.length;

    for (var i = 0; i < lung; i++) {
      var dataCreazione = note_singolo_doc[i].data_creazione;
      var dataCreazioneFormattata = moment(dataCreazione).format('DD/MM/YYYY HH:mm');
      singolaNota = `${author}${note_singolo_doc[i].utente} \n ${dateHour}${dataCreazioneFormattata} \n${text}${note_singolo_doc[i].descrizione}\n \n`;
      elencoNote = elencoNote + singolaNota;
    }

    swal({
      animation: 'slide-from-top',
      title: t('note.docNote'),
      overflow: 'hidden',
      text: elencoNote,
      input: 'text',
      type: 'textarea',
      style: { display: 'block' },
      height: '100px',
      inputType: 'textarea',
      confirmButtonText: 'Cool',
      showConfirmButton: false,
      showCancelButton: true,
    });
  }

  handleClickFlagArchive(idDocumento) {
    this.props.dispatch(documentiActions.getArchiviazioneDocumento(idDocumento));
    this.setState({ showModalArchive: true });
  }

  handleCloseModalArchive() {
    this.props.dispatch(documentiActions.resetDatiArchiviazione());
    this.setState({
      showModalArchive: false,
    });
  }

  handleClickStartProcess = (idDocumento) => {
    const { user, t, i18n } = this.props;
    console.log(i18n.language);
    this.setState({
      alert: true,
      sweetAlertTitle: t('alert.startSigningProcess'),
      sweetAlertConfirm: () => {
        this.hideAlert();
        let azione = {
          id_azione: 1,
          id_documento: idDocumento,
          lang: i18n.language,
        };
        this.props.dispatch(
          documentiActions.eseguiAzione(azione, undefined, undefined, user.ruoli)
        );
      },
    });
  };

  handleClickDeleteDocumento = (idDocumento) => {
    const { user, t } = this.props;

    this.setState({
      alert: true,
      sweetAlertTitle: t('alert.deleteDoc'),
      sweetAlertConfirm: () => {
        this.hideAlert();
        this.props.dispatch(documentiActions.deleteById(idDocumento, user.ruoli));
      },
    });
  };

  handleClickCopiaDocumento = (idDocumento) => {
    const { t } = this.props;
    var _this = this;

    this.setState({
      alert: true,
      sweetAlertTitle: t('alert.copy'),
      sweetAlertConfirm: () => {
        this.hideAlert();
        this.props.dispatch(documentiActions.copiaDocumento(idDocumento));

        setTimeout(function () {
          if (_this.props.documenti?.copyDoc)
            _this.props.handleClickOpenDocumento(_this.props.documenti.copyDoc);
        }, 2000);
      },
    });
  };

  handleClickActionRefuse = (documento) => {
    const { user, t, i18n } = this.props;

    this.setState({
      alert: true,
      sweetAlertTitle: t('alert.cancelProcess'),
      sweetAlertConfirm: () => {
        this.hideAlert();
        let azione = {
          id_azione: 6,
          id_documento: documento.id,
          lang: i18n.language,
        };
        this.props.dispatch(
          documentiActions.eseguiAzione(azione, undefined, undefined, user.ruoli)
        );
      },
    });
  };

  hideAlert() {
    this.setState({
      alert: undefined,
      sweetAlertTitle: undefined,
      sweetAlertConfirm: undefined,
    });
  }

  handleFileDownload = (id, documento) => {
    this.props.dispatch(documentiActions.download(id, documento));
  };

  handleClickActionForceRefuse = (documento) => {
    const { user, t, i18n } = this.props;

    this.setState({
      confirm: (
        <SweetAlert
          input
          inputType="textarea"
          showCancel
          allowEscape
          style={{ display: 'block' }}
          title={t('note.insertNoteReject')}
          onConfirm={(e) => {
            let rifiutaDocumento = {
              id_azione: 5,
              id_documento: [documento.id],
              nota_rifiuto: e,
              lang: i18n.language,
            };
            this.hideConfirm();
            this.props.closeFileViewer();
            this.props.dispatch(documentiActions.rifiutaDocumento(rifiutaDocumento, user.ruoli));
          }}
          onCancel={() => this.hideConfirm()}
          validationMsg={t('note.verifyComment')}
          confirmBtnText={t('action.save')}
          cancelBtnText={t('action.close')}
          confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
          cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
        />
      ),
    });
  };

  hideConfirm = () => {
    this.setState({
      confirm: undefined,
    });
  };

  toggleSelectAll() {
    if (this.state.selectedDocuments.length === 0) {
      const { selectedDocuments } = this.state;
      const newChecked = [...selectedDocuments];
      for (let i = 0; i < this.props.documenti.items.length; i++) {
        newChecked.push(this.props.documenti.items[i].id_documento);
      }
      let utentiDaSollecitare = this.utentiDaSollecitare(newChecked);
      this.setState({
        selectedDocuments: newChecked,
        utentiDaSollecitare,
      });
    } else {
      this.setState({
        selectedDocuments: [],
        utentiDaSollecitare: undefined,
      });
    }
  }

  handleClickSelectDocumento(idDocumento) {
    const { selectedDocuments } = this.state;
    console.log('DOCUMENTI SELEZIONATI : ' + selectedDocuments);
    const currentIndex = selectedDocuments.indexOf(idDocumento);
    const newChecked = [...selectedDocuments];

    if (currentIndex === -1) {
      newChecked.push(idDocumento);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let utentiDaSollecitare = this.utentiDaSollecitare(newChecked);
    this.setState({
      selectedDocuments: newChecked,
      utentiDaSollecitare,
    });
  }

  utentiDaSollecitare(selectedDocuments) {
    let utenti = [];
    const { items } = this.props.documenti;
    if (items)
      for (let i = 0; i < items.length; i++) {
        if (selectedDocuments.includes(items[i].id_documento) && items[i].id_stato_documento === 2)
          utenti.push({
            id_documento: items[i].id_documento,
            id_utente: items[i].id_firmatario_corrente,
          });
      }
    return utenti;
  }

  handleClickInviaSollecito = () => {
    const { i18n } = this.props;
    const { utentiDaSollecitare } = this.state;
    this.props.dispatch(
      documentiActions.invioSollecitoMultiplo(utentiDaSollecitare, i18n.language)
    );
    this.setState({ selectedDocuments: [], utentiDaSollecitare: [] });
  };

  render() {
    const {
      selected,
      alert,
      confirm,
      sweetAlertTitle,
      sweetAlertConfirm,
      selectedDocuments,
      utentiDaSollecitare,
    } = this.state;
    const { t, classes, documenti, readOnly, handleClickOpenFile, temaColor, user } = this.props;
    const { handleClickOpenDocumento, handleClickOpenRaccoglitore } = this.props;
    const { inBozza, inLavorazione, rifiutato, sospeso, annullato } = Constants.documento.stato;
    const { startSigningProcess, downloadDoc, viewDetail, copy, newDoc, newSignPath } = t(
      'action',
      {
        returnObjects: true,
      }
    );
    const { sendReminder } = t('flow', {
      returnObjects: true,
    });

    const { deleteDoc, cancelProcess, viewSigningPath, viewDoc, forceRejectDoc } = t('action', {
      returnObjects: true,
    });
    const { previous, next, loadingTxt, page, of, rows, noDoc } = t('table', {
      returnObjects: true,
    });

    const { subject, priority, status, actions, signingPath, firmatarioCorrente, type, date } = t(
      'table.headers',
      {
        returnObjects: true,
      }
    );
    const priorityType = t('document.priorityType', {
      returnObjects: true,
    });
    const docStatus = t('document.status', {
      returnObjects: true,
    });

    let listaDocumenti = documenti.items ?? documenti.tasks ?? [];
    let disabledSollecito = utentiDaSollecitare?.length > 0 ? false : true;

    const btnList = [
      {
        onClick: this.handleClickInviaSollecito,
        icon: <Alarm style={{ margin: '0px', marginRight: '5px' }} />,
        title: sendReminder,
        show: Authorize.checkAuthorizeFunc('FNCSLC'),
        disabled: disabledSollecito,
      },
      {
        onClick: handleClickOpenDocumento,
        icon: <Document style={{ margin: '0px', marginRight: '5px' }} />,
        title: newDoc,
        show: Authorize.checkAuthorizeFunc('FNCCMD'),
      },
      {
        onClick: handleClickOpenRaccoglitore,
        icon: <Folder style={{ margin: '0px', marginRight: '5px' }} />,
        title: newSignPath,
        show: Authorize.checkAuthorizeFunc('FNCCMP'),
      },
    ];

    return (
      <GridItem xs={12}>
        {confirm}
        {alert && (
          <SweetAlert
            info
            showCancel
            confirmBtnText="Ok"
            confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
            cancelBtnText={t('action.cancel')}
            cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
            title={sweetAlertTitle}
            onConfirm={sweetAlertConfirm}
            onCancel={() => {
              this.hideAlert();
            }}
          ></SweetAlert>
        )}
        {
          <ArchiveModalForm
            open={this.state.showModalArchive}
            onClose={() => this.handleCloseModalArchive()}
          />
        }
        <Card>
          <CardHeader color={temaColor} icon>
            <CardIcon color={temaColor}>
              <Document />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t('document.title')}</h4>
            {btnList.map((btn) => {
              const { show, onClick, title, icon, disabled } = btn;
              return (
                show && (
                  <Button
                    color={temaColor}
                    size="sm"
                    style={{ float: 'right' }}
                    onClick={() => onClick()}
                    key={title}
                    disabled={disabled}
                  >
                    {icon} {title}
                  </Button>
                )
              );
            })}
          </CardHeader>
          <CardBody>
            <ReactTable
              getTrProps={(state, rowInfo) => {
                if (rowInfo?.row) {
                  return {
                    onClick: (e) => this.selectRow(e, rowInfo.index),
                    style: {
                      background: rowInfo.index === selected ? '#e8ffdf' : null,
                      border: rowInfo.index === selected ? '1px solid #59ff59' : null,
                    },
                  };
                } else return {};
              }}
              data={listaDocumenti.map((doc) => {
                let wReturn = ModelViewHelper.document_ModelToView(doc, t);
                const { tag_posizionato_est, tag_posizionato_int, file_name } = wReturn;

                //checks per attivare il button Avvia Processo
                let wButtonAvviaDisabled = true;

                //Avvia processo = true - cases:

                // estANDint => percorso misto con tags int e est tutti posizionati
                const estANDint = tag_posizionato_est && tag_posizionato_int;

                // estANDintNULL => percorso esterno con tags esterni posizionati
                const estANDintNULL = tag_posizionato_est && tag_posizionato_int === null;

                // intANDestNULL => percorso interno con tutti gli interni posizionati
                const intANDestNULL = tag_posizionato_int && tag_posizionato_est === null;

                // intNULLestNULL => percorso interno e nessun tag posizionato
                // il percorso interno è l'unico ad avere due casi per attivare un processo
                const intNULLestNULL = tag_posizionato_int === null && tag_posizionato_est === null;

                const tagPosizionatiCheck =
                  estANDint || intNULLestNULL || estANDintNULL || intANDestNULL;

                if (
                  ([inBozza, sospeso].includes(doc.id_stato_documento) ||
                    ([rifiutato].includes(doc.id_stato_documento) &&
                      user?.riavvioRifiuto === 'true')) &&
                  file_name &&
                  tagPosizionatiCheck
                ) {
                  wButtonAvviaDisabled = false;
                }

                const otherPropsBtn = [
                  {
                    prop: 'archive_icon',
                    show: wReturn?.archiviato === '2',
                    title: t('yourDashboard.documentArchived'),
                    btnColor: 'warning',
                    btnDisabled: false,
                    onClick: () => this.handleClickFlagArchive(wReturn.id),
                    icon: <ArchiveIcon />,
                  },
                  {
                    prop: 'spesa_icon',
                    show: wReturn.impegno_spesa,
                    title: t('yourDashboard.spendingPresence'),
                    btnColor: 'rose',
                    btnDisabled: false,
                    onClick: null,
                    icon: <Euro />,
                  },
                  {
                    prop: 'proposta_icon',
                    show: wReturn.proposta_inviata === 'inviata',
                    title: t('yourDashboard.proposalSent'),
                    btnColor: 'info',
                    btnDisabled: false,
                    onClick: null,
                    icon: <AccountBalance />,
                  },
                  {
                    prop: 'note_icon',
                    show: wReturn?.note?.length > 0,
                    title: t('note.docNote'),
                    btnColor: 'success',
                    btnDisabled: false,
                    onClick: () => this.handleClickOpenFileNote(wReturn.note),
                    icon: <InfoOutlined />,
                  },
                ];

                const actionsBtnList = [
                  {
                    show: readOnly && Authorize.checkAuthorizeFunc('FNCAFF'),
                    title: startSigningProcess,
                    btnColor: 'info',
                    btnDisabled: wButtonAvviaDisabled,
                    onClick: () => this.handleClickStartProcess(wReturn.id),
                    icon: <Play />,
                  },
                  {
                    show: Authorize.checkAuthorizeFunc('FNCVWD'),
                    title: viewDoc,
                    btnColor: 'success',
                    btnDisabled: file_name ? false : true,
                    onClick: () => handleClickOpenFile(wReturn),
                    icon: <File />,
                  },
                  {
                    show: Authorize.checkAuthorizeFunc('FNCDWL'),
                    title: downloadDoc,
                    btnColor: 'rose',
                    btnDisabled: file_name ? false : true,
                    onClick: () => this.handleFileDownload(wReturn.uuid, wReturn),
                    icon: <Download />,
                  },
                  {
                    show: Authorize.checkAuthorizeFunc('FNCDDC'),
                    title: viewDetail,
                    btnColor: 'success',
                    btnDisabled: false,
                    onClick: () => handleClickOpenDocumento(wReturn.id, wReturn.uuid),
                    icon: <Search />,
                  },
                  {
                    show: readOnly && Authorize.checkAuthorizeFunc('FNCCDC'),
                    title: copy,
                    btnColor: 'primary',
                    btnDisabled: false,
                    onClick: () => this.handleClickCopiaDocumento(wReturn.id),
                    icon: <FileCopy />,
                  },
                  {
                    show: Authorize.checkAuthorizeFunc('FNCDDC'),
                    title: viewSigningPath,
                    btnColor: 'warning',
                    btnDisabled: false,
                    onClick: () => handleClickOpenRaccoglitore(wReturn.id_raccoglitore),
                    icon: <FolderOpen />,
                  },
                  /*                   {
                    show:
                      Authorize.checkAuthorizeFunc('FNCRDC') &&
                      wReturn.id_stato_documento &&
                      [4, 9].includes(wReturn.id_stato_documento) &&
                      wReturn.id_utente_ult_firmatario === idUtente,
                    title: forceRejectDoc,
                    btnColor: 'danger',
                    btnDisabled: false,
                    onClick: () => this.handleClickActionForceRefuse(wReturn),
                    icon: <Abort2 />,
                  },*/
                  {
                    show: readOnly && Authorize.checkAuthorizeFunc('FNCEPR'),
                    title: cancelProcess,
                    btnColor: 'danger',
                    //vecchia condizione btnDisabled: doc.id_stato_documeno === rifiutato && file_name ? false : true,
                    btnDisabled: doc.id_stato_documento !== inLavorazione,
                    onClick: () => this.handleClickActionRefuse(wReturn),
                    icon: <Abort />,
                  },
                  {
                    show: readOnly && Authorize.checkAuthorizeFunc('FNCEDC'),
                    title: deleteDoc,
                    btnColor: 'danger',
                    btnDisabled: [inBozza, sospeso, annullato].includes(doc.id_stato_documento)
                      ? false
                      : true,
                    onClick: () => this.handleClickDeleteDocumento(wReturn.id),
                    icon: <Delete />,
                  },
                ];

                wReturn.actions = (
                  <div className="actions-center">
                    {actionsBtnList.map((btn) => {
                      const { title, show, btnColor, btnDisabled, onClick, icon } = btn;
                      return (
                        show && (
                          <Tooltip
                            key={uuidv4()}
                            id="tooltip-top"
                            title={title}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button
                              justIcon
                              round
                              simple
                              disabled={btnDisabled}
                              onClick={onClick}
                              color={btnColor}
                              className={classes.tcsign_action}
                            >
                              {icon}
                            </Button>
                          </Tooltip>
                        )
                      );
                    })}
                  </div>
                );

                {
                  otherPropsBtn.map((btn) => {
                    const { title, show, btnColor, btnDisabled, onClick, icon, prop } = btn;
                    wReturn[prop] = show ? (
                      <Tooltip
                        id="tooltip-top"
                        title={title}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          justIcon
                          round
                          simple
                          disabled={btnDisabled}
                          onClick={onClick}
                          color={btnColor}
                          className={classes[onClick ? 'tcsign_action' : 'tcsign_spesa']}
                        >
                          {icon}
                        </Button>
                      </Tooltip>
                    ) : (
                      ''
                    );
                  });
                }

                let selCheckbox = '';
                if (
                  Authorize.checkAuthorizeFunc('FNCFDC') ||
                  Authorize.checkAuthorizeFunc('FNCVDC')
                )
                  selCheckbox = (
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleClickSelectDocumento(wReturn.id)}
                      checked={selectedDocuments.indexOf(wReturn.id) !== -1 ? true : false}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{ checked: classes.checked }}
                    />
                  );
                wReturn.sel = <div>{selCheckbox}</div>;

                return wReturn;
              })}
              filterable
              columns={[
                {
                  Header: () => {
                    return (
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.toggleSelectAll()}
                        checked={selectedDocuments.length > 0 ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                    );
                  },
                  accessor: 'sel',
                  sortable: false,
                  filterable: false,
                  maxWidth: 50,
                },
                {
                  Header: '',
                  accessor: 'archive_icon',
                  maxWidth: 30,
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: '',
                  accessor: 'note_icon',
                  maxWidth: 30,
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: '',
                  accessor: 'proposta_icon',
                  maxWidth: 30,
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: 'ID',
                  accessor: 'id',
                  maxWidth: 50,
                },
                {
                  Header: subject,
                  accessor: 'descrizione',
                  filterMethod: (filter, row) =>
                    row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                },
                {
                  Header: signingPath,
                  accessor: 'nome_raccoglitore',
                  filterMethod: (filter, row) =>
                    row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                },
                {
                  Header: firmatarioCorrente,
                  accessor: 'firmatario_corrente',
                  filterMethod: (filter, row) =>
                    row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                },
                {
                  Header: type,
                  accessor: 'desc_tipo_documento',
                  maxWidth: 130,
                  filterMethod: (filter, row) =>
                    row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                },
                {
                  Header: date,
                  accessor: 'data_ult_modif',
                  maxWidth: 160,
                },
                {
                  Header: priority,
                  accessor: 'priorita',
                  maxWidth: 100,
                  filterMethod: (filter, row) => {
                    if (filter.value === 'all') return true;

                    if (filter.value === 'A') return row[filter.id] === 'Alta';

                    if (filter.value === 'B') return row[filter.id] === 'Standard';

                    return true;
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        paddingBottom: '2px',
                        color: '#555',
                      }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">{t('document.all')}</option>
                      <option value="A">{priorityType['Urgente']}</option>
                      <option value="B">{priorityType['Standard']}</option>
                    </select>
                  ),
                },
                {
                  Header: status,
                  accessor: 'desc_stato_documento',
                  maxWidth: 130,
                  filterMethod: (filter, row) => {
                    if (filter.value === 'all') return true;

                    return row[filter.id] === filter.value;
                  },
                  Cell: (row) => {
                    const color =
                      row.value === t('document.status.Rifiutato')
                        ? '#ff0000'
                        : 'rgba(0, 0, 0, 0.87)';

                    const fontWeight =
                      row.value === t('document.status.Rifiutato') ? 'bold' : '300';

                    return (
                      <span style={{ color, fontWeight, transition: 'all .3s ease' }}>
                        {row.value}
                      </span>
                    );
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        paddingBottom: '2px',
                        color: '#555',
                      }}
                      value={filter ? filter.value : 'all'}
                    >
                      <option value="all">{t('document.all')}</option>
                      <option value="In bozza">{docStatus['In bozza']}</option>
                      <option value="In lavorazione">{docStatus['In lavorazione']}</option>
                      <option value="Rifiutato"> {docStatus['Rifiutato']}</option>
                      <option value="Completato">{docStatus['Completato']}</option>
                      <option value="Sospeso">{docStatus['Sospeso']}</option>
                      <option value="Inviato al protocollo">
                        {docStatus['Inviato al protocollo']}
                      </option>
                      <option value="Cancellato">{docStatus['Cancellato']}</option>
                      <option value="Non protocollato">{docStatus['Non protocollato']}</option>
                    </select>
                  ),
                },
                {
                  Header: 'Solleciti',
                  accessor: 'numeroSolleciti',
                  maxWidth: 120,
                },
                {
                  Header: 'Ultimo sollecito',
                  accessor: 'ultimoSollecito',
                  maxWidth: 160,
                },
                {
                  Header: actions,
                  accessor: 'actions',
                  sortable: false,
                  filterable: false,
                  maxWidth: 220,
                },
              ]}
              defaultPageSize={50}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              striped
              hover
              condensed
              pagination
              insertRow
              deleteRow
              search
              previousText={previous}
              nextText={next}
              loadingText={loadingTxt}
              noDataText={noDoc}
              pageText={page}
              ofText={of}
              rowsText={rows}
            />
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

function mapStateToProps(state) {
  const { documenti, authentication } = state;
  return {
    user: authentication?.user ?? {},
    documenti,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(ListaDocumenti);
