import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core';
import { Search, ExpandLess, ExpandMore } from '@material-ui/icons';

import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from '../../../components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import RicercaAvanzata from './RicercaAvanzata';
import RisultatiRicerca from './RisultatiRicerca';
import TipoRicerca from './TipoRicerca';
import CercaInAmbito from './CercaInAmbito';

import { searchActions } from '../../../Actions';
import { ThemeUtils } from '../../../Helpers/themeUtils';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const styles = {
  ...extendedFormsStyle,
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '1em',
  },
  tcsign_cardbody: {
    'padding-top': '0',
    'padding-bottom': '1em',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

class RicercaIndicizzata extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showRicercaAvanzata: false,
      showRisultatiRicerca: false,
      fulltext: props.filters.fulltext ?? '',
    };
  }

  componentDidMount = () => {
    const { mascheraRisultati, filters } = this.props;
    const { id_documento, nome, data_creazione, firm_vist } = filters;

    const isRicercaAvanzata =
      nome || id_documento || data_creazione?.gte || data_creazione?.lte || firm_vist?.length > 0
        ? true
        : false;

    if (mascheraRisultati && isRicercaAvanzata) this.setState({ showRicercaAvanzata: true });
  };

  toggleRicercaAvanzata = () => {
    this.setState({
      showRicercaAvanzata: !this.state.showRicercaAvanzata,
    });
  };

  onChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });

    value = value.length > 0 ? value : undefined;

    this.props.dispatch(searchActions.setFilter(name, value));
  };

  search = () => {
    const { dispatch } = this.props;

    if (!this.props.mascheraRisultati) this.setState({ showRisultatiRicerca: true });
    dispatch(searchActions.resetRaggruppamenti());
    dispatch(searchActions.newSearch());
  };

  handleKeyPress = (event) => {
    const { nome, id_documento, fulltext, data_creazione, firm_vist } = this.props.filters;
    const disableSearch =
      nome ||
      id_documento ||
      fulltext ||
      data_creazione?.gte ||
      data_creazione?.lte ||
      firm_vist?.length > 0
        ? false
        : true;
    if (event.key === 'Enter' && !disableSearch) this.search();
  };

  closeRisultati = () => {
    this.setState({ showRisultatiRicerca: false, fulltext: '' });
    this.props.dispatch(searchActions.resetSearch());
  };

  resetSearch = () => {
    this.setState({ fulltext: '' });
    this.props.dispatch(searchActions.resetSearch());
  };

  render() {
    const { showRicercaAvanzata, showRisultatiRicerca } = this.state;
    const { classes, resultFuncClass, error, filters, tenantTheme, t } = this.props;
    const { nome, id_documento, fulltext, data_creazione, firm_vist } = filters;
    const temaColor = ThemeUtils.getThemeColor(tenantTheme, 'tcsign_color', 'card_color');

    const { search, freeText, clean, advancedSearch } = t('research', { returnObjects: true });

    const disableSearch =
      nome ||
      id_documento ||
      fulltext ||
      data_creazione?.gte ||
      data_creazione?.lte ||
      firm_vist?.length > 0
        ? false
        : true;

    return (
      <>
        <GridItem xs={12}>
          <Card className={classes.tcsign_card}>
            <CardHeader color={temaColor} icon>
              <CardIcon color={temaColor}>
                <Search />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{search}</h4>
            </CardHeader>
            <CardBody className={classes.tcsign_cardbody}>
              <CustomInput
                labelText={freeText}
                id="fulltext"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: this.onChange,
                  type: 'text',
                  value: this.state.fulltext,
                  onKeyPress: this.handleKeyPress,
                }}
              />
              <div style={{ marginTop: '1em' }} className={classes.flexSpaceBetween}>
                <TipoRicerca />
              </div>
              {showRicercaAvanzata && <RicercaAvanzata search={this.search} />}
            </CardBody>
            <CardFooter className={classes.flexSpaceBetween}>
              <CercaInAmbito />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button color={temaColor} size="sm" onClick={this.search} disabled={disableSearch}>
                  <Search style={{ margin: '0px' }} />
                </Button>
                <Button
                  color={temaColor}
                  size="sm"
                  style={{ marginLeft: '1em' }}
                  onClick={this.toggleRicercaAvanzata}
                >
                  {advancedSearch}
                  {showRicercaAvanzata ? <ExpandLess /> : <ExpandMore />}
                </Button>
                <Button color="danger" size="sm" onClick={this.resetSearch}>
                  {clean}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {showRisultatiRicerca && !error && (
          <RisultatiRicerca
            resultFuncClass={resultFuncClass}
            closeRisultati={this.closeRisultati}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { tipoRicerca, ambito, filters, pagination, sort, error } = state.search;

  return {
    tipoRicerca,
    ambito,
    pagination,
    filters,
    sort,
    error,
    tenantTheme: state.tema.tenant_theme,
  };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(RicercaIndicizzata)));
