import { userConstants } from '../Constants';

export function users(
  state = { filters: {}, trovati: [], pagination: { pageIndex: 0, size: 10 } },
  action
) {
  const { name, value, trovati, error } = action;
  const { filters, pagination } = state;

  switch (action.type) {
    case userConstants.ERROR_RESET:
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case userConstants.USERS_RESET:
      return {
        ...state,
        current: undefined,
        error: undefined,
        loading: false,
      };

    case userConstants.PROFILI_RESET:
      return {
        ...state,
        current: undefined,
        error: undefined,
        loading: false,
      };

    case userConstants.DELETE_FIRMA_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case userConstants.DELETE_FIRMA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case userConstants.DELETE_FIRMA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.ATTIVA_DELEGATO_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case userConstants.ATTIVA_DELEGATO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case userConstants.ATTIVA_DELEGATO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.DELETE_UTENTE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.DELETE_UTENTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.DELETE_UTENTE_FAILURE:
      return {
        ...state,
        items: state.items,
        error: action.error,
        loading: false,
      };
    case userConstants.UO_DELETE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.UO_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.UO_DELETE_FAILURE:
      return {
        ...state,
        items: state.items,
        error: action.error,
        loading: false,
      };

    //CAMUSI 02072020 inizio
    case userConstants.PROFILO_DELETE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.PROFILO_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.PROFILO_DELETE_FAILURE:
      return {
        ...state,
        items: state.items,
        error: action.error,
        loading: false,
      };
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.SAVE_DELEGATI_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case userConstants.SAVE_DELEGATI_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case userConstants.SAVE_DELEGATI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.USERS_GET_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.USERS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items,
        current: action.user,
      };
    case userConstants.USERS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: state.items,
      };
    case userConstants.UO_GET_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.UO_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items,
        current: action.uo,
      };
    case userConstants.UO_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: state.items,
      };

    //CAMUSI 02072020 inizio
    case userConstants.PROFILO_GET_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.PROFILO_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items,
        current: action.profilo,
      };
    case userConstants.PROFILO_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: state.items,
      };
    //CAMUSI 02072020 fine
    case userConstants.UO_SAVE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.UO_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items,
        current: action.uo,
      };
    case userConstants.UO_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: state.items,
      };

    case userConstants.PROFILO_SAVE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.PROFILO_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        profili: state.profili, //items
        current: action.profilo,
      };
    case userConstants.PROFILO_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        profili: state.profili, //items
      };

    case userConstants.UO_GETALL_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.UO_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items,
      };
    case userConstants.UO_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        items: state.items,
        current: undefined,
        error: action.error,
      };
    case userConstants.PROFILI_GETALL_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.PROFILI_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        profili: action.profili,
      };
    case userConstants.PROFILI_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        profili: state.profili,
        current: undefined,
        error: action.error,
      };

    case userConstants.GETALL_REQUEST:
      return { ...state, loading: true, error: undefined };
    case userConstants.GETALL_SUCCESS:
      return { ...state, loading: false, items: action.users };
    case userConstants.GETALL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case userConstants.ADD_EDIT_FIRMA:
      return { ...state, current: { ...state.current, addEditFirma: action.value } };
    case userConstants.SAVE_FIRMA_REQUEST:
      return { ...state, current: { ...state.current, loading: true } };
    case userConstants.SAVE_FIRMA_SUCCESS:
      return { ...state, current: { ...state.current, loading: false, addEditFirma: false } };
    case userConstants.SAVE_FIRMA_FAILURE:
      return { ...state, current: { ...state.current, loading: false } };
    case userConstants.GET_USER_COMPANIES_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.GET_USER_COMPANIES_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: false,
        companies: action.companies,
      };
    case userConstants.GET_USER_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_ALL_COMPANIES_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: false,
        companies: action.companies,
      };
    case userConstants.GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.GET_ID_TENANT_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.GET_ID_TENANT_FAILURE:
      return {
        ...state,
        error: undefined,
        loading: false,
        companies: action.companies,
      };
    case userConstants.GET_TENANT_THEME_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.GET_TENANT_THEME_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: false,
        tenant_theme: action.tenant.theme,
        company: action.tenant.company,
      };
    case userConstants.GET_TENANT_THEME_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.GET_FILTERED_USERS_REQUEST:
      return { ...state, loading: true, error: undefined };
    case userConstants.GET_FILTERED_USERS_SUCCESS:
      return { ...state, loading: false, items: action.users };
    case userConstants.GET_FILTERED_USERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case userConstants.SET_SELECTED_USER:
      return { ...state, selectedUser: action.user, loading: false };
    case userConstants.RESET_SELECTED_USER:
      return { ...state, selectedUser: undefined, loading: false };

    case userConstants.SEARCH_USER_REQUEST:
      return { ...state, fetching: true, error: undefined, loading: true };
    case userConstants.SEARCH_USER_SUCCESS:
      return { ...state, fetching: false, trovati, loading: false };
    case userConstants.SEARCH_USER_FAILURE:
      return { ...state, fetching: false, error, loading: false };

    case userConstants.SET_FILTER:
      return { ...state, filters: { ...filters, [name]: value } };

    case userConstants.CREATE_USER_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.user,
      };
    case userConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.RESET_PAGE_INDEX:
      return { ...state, pagination: { pageIndex: 0, size: state.pagination.size } };
    case userConstants.RESET_SEARCH_RESULT:
      return { ...state, trovati: [] };

    case userConstants.GET_USERS_BY_UO_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case userConstants.GET_USERS_BY_UO_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsByUo: action.users,
      };
    case userConstants.GET_USERS_BY_UO_FAILURE:
      return {
        ...state,
        loading: false,
        itemsByUo: state.itemsByUo,
        current: undefined,
        error: action.error,
      };

    default:
      return state;
  }
}
