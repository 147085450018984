import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { withStyles, Tooltip } from '@material-ui/core/';

const selectStyles = {
  tc_sign_searchSelectLabel: {
    fontSize: '10px',
    color: '#AAA !important',
    top: '8px',
    padding: '0px',
  },
  tooltip: {
    fontSize: '14px',
  },
  control: (styles, state) => ({
    ...styles,
    padding: '0px',
    backgroundColor: 'transparent',
    borderColor: state.isFocused ? '#4caf50' : 'rgb(204, 204, 204)',
    boxShadow: state.isFocused ? `0 0 0 1px "#4caf50"` : '0px',
    borderTopWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderRadius: '0px',
    '&:hover': {
      borderColor: state.isFocused ? '#4caf50' : 'rgb(204, 204, 204)',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? '#43a047' : isFocused ? '#43a047' : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : null,
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '2',
      ':active': {
        backgroundColor: isSelected ? '#43a047' : null,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    padding: '0px',
    backgroundColor: 'white',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.42857',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0px',
  }),
  singleValue: (styles) => ({
    ...styles,
    opacity: '1',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.42857',
  }),
  menuList: (styles) => ({
    ...styles,
    zIndex: '9999',
    position: 'relative',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '9999',
  }),
  placeholder: (styles) => ({
    ...styles,
    top: '13px',
    color: '#AAA !important',
    fontSize: '14px',
    fontWeight: '400',
    padding: '0px',
  }),
};

class SelectSearch extends React.Component {
  handleSelect = (selectedOption) => {
    const { onChange, id } = this.props;
    let event = {
      target: {
        name: id,
        value: undefined,
        label: undefined,
        other: undefined,
        id: undefined,
        tipo_firmatario: undefined,
      },
    };

    if (selectedOption !== null) {
      event.target.value = selectedOption.value;
      event.target.label = selectedOption.label;
      event.target.other = selectedOption.other;
      event.target.id = selectedOption.id;
      event.target.tipo_firmatario = selectedOption.tipo_firmatario;
    }

    onChange(event);
  };

  jsonEqual = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  onKeyDown = (event) => {
    if (this.props.disabled) event.preventDefault();
  };

  selectOption = (value) => {
    const { options } = this.props;
    var _this = this;
    for (let i = 0; i < options.length; i++) {
      if (
        typeof options[i] !== 'undefined' &&
        (options[i].value === value || _this.jsonEqual(options[i].value, value))
      ) {
        return options[i];
      }
    }

    return null;
  };

  render() {
    const { t, label, success, error, classes } = this.props;

    const Control = (props) => (
      <div>
        {label && <label style={selectStyles.tc_sign_searchSelectLabel}>{label}</label>}
        <components.Control {...props} />
      </div>
    );

    const ValueContainer = (props) => {
      const value = props.getValue()[0];
      const title = value ? value.label : '';

      return (
        <Tooltip
          id="tooltip-top"
          title={title}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <div style={{ width: '85%' }}>
            <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
          </div>
        </Tooltip>
      );
    };

    const Option = (props) => {
      let wProp = props;
      if (props.children && props.children.length >= 50) {
        let split = props.children.split('->');
        let label = '';
        if (split.length === 1) {
          label = split[0];
        } else if (split.length >= 2) {
          label = '...' + split[split.length - 2] + '->' + split[split.length - 1];
        }

        wProp = {
          ...props,
          children: label,
        };
      }
      return <components.Option {...wProp} />;
    };

    let currentStyle = {
      ...selectStyles,
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    if (error) {
      currentStyle = {
        ...selectStyles,
        placeholder: (styles) => ({
          ...selectStyles.placeholder,
          color: '#F00 !important',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      };
    } else if (success) {
      currentStyle = {
        ...selectStyles,
        placeholder: (styles) => ({
          ...selectStyles.placeholder,
          color: '#4daf51 !important',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      };
    }
    return (
      <Select
        styles={currentStyle}
        isClearable={!this.props.disabled ? this.props.isClearable : false}
        isSearchable={this.props.isSearchable}
        placeholder={this.props.placeholder}
        options={this.props.options}
        menuPortalTarget={document.body}
        value={this.selectOption(this.props.value)}
        onChange={this.handleSelect}
        components={{ Control, Option, ValueContainer }}
        menuIsOpen={!this.props.disabled ? this.props.menuIsOpen : false}
        isOptionDisabled={this.props.isOptionDisabled}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

SelectSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
};

export default withStyles(selectStyles)(SelectSearch);
