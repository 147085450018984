import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from '../../components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { userActions } from '../../Actions';

import { ThemeUtils } from '../../Helpers/themeUtils';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const styles = {
  ...extendedFormsStyle,
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '1em',
  },
  tcsign_cardbody: {
    'padding-top': '0',
    'padding-bottom': '1em',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

class RicercaUtente extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      nome: '',
      cognome: '',
      email: '',
    };
  }

  onChange = (event) => {
    let { name, value } = event.target;
    const { dispatch } = this.props;
    this.setState({
      [name]: value,
    });
    value = value.length > 0 ? value : undefined;
    if (value?.length >= 2) dispatch(userActions.setFilter(name, value));
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') this.search();
  };

  search = () => {
    const { dispatch } = this.props;
    const { nome, cognome, email } = this.state;
    dispatch(userActions.search(nome, cognome, email));
  };

  resetSearch = () => {
    this.setState({ nome: '', cognome: '', email: '' });
    this.props.dispatch(userActions.resetSearchResult());
  };

  render() {
    const { classes, tenantTheme, filters, t } = this.props;
    const { nome, cognome, email } = filters;
    const temaColor = ThemeUtils.getThemeColor(tenantTheme, 'tcsign_color', 'card_color');

    const disableSearch = nome || cognome || email ? false : true;

    return (
      <>
        <GridItem xs={12} style={{ marginTop: '15px' }}>
          <Card className={classes.tcsign_card}>
            <CardHeader color={temaColor} icon>
              <CardIcon color={temaColor}>
                <Search />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('action.search')}</h4>
            </CardHeader>
            <CardBody className={classes.tcsign_cardbody}>
              <CustomInput
                labelText={t('user.name')}
                id="nome"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: this.onChange,
                  type: 'text',
                  value: this.state.nome,
                  onKeyPress: this.handleKeyPress,
                }}
              />
              <CustomInput
                labelText={t('user.lastName')}
                id="cognome"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: this.onChange,
                  type: 'text',
                  value: this.state.cognome,
                  onKeyPress: this.handleKeyPress,
                }}
              />
              <CustomInput
                labelText={t('user.email')}
                id="email"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: this.onChange,
                  type: 'text',
                  value: this.state.email,
                  onKeyPress: this.handleKeyPress,
                }}
              />
            </CardBody>
            <CardFooter className={classes.flexSpaceBetween}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button color={temaColor} size="sm" onClick={this.search} disabled={disableSearch}>
                  <Search style={{ margin: '0px' }} />
                </Button>
                <Button color="danger" size="sm" onClick={this.resetSearch}>
                  {t('research.clean')}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.users;

  return {
    filters,
    tenantTheme: state.tema.tenant_theme,
  };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(RicercaUtente)));
