import LoginPage from 'views/Pages/TC_LoginPage.jsx';
import RegisterPage from 'views/Pages/RegisterPage.jsx';

// @material-ui/icons
import PersonAdd from '@material-ui/icons/PersonAdd';
import Fingerprint from '@material-ui/icons/Fingerprint';
import UserProfile from '../views/Pages/UserProfile';

const pagesRoutes = [
  {
    path: '/pages/my-profile',
    name: 'My Profile',
    short: 'My Profile',
    mini: 'MP',
    icon: PersonAdd,
    component: UserProfile,
  },
  {
    path: '/pages/login-page',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage,
  },
];

export default pagesRoutes;
