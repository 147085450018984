import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// @material-ui/core
import { Tooltip, withStyles, Button as CoreButton, Slide } from '@material-ui/core';

// @material-ui/icons
import { AccountCircle, CheckCircle } from '@material-ui/icons';

// core components
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import { ModelViewHelper, Constants, Authorize } from 'Helpers/model-view.js';
import { userActions } from '../../Actions';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = {
  ...extendedFormsStyle,
  btn_confirm: {
    'background-color': '#4caf50 !important',
  },
  tcsign_action: {
    width: '20px',
    minWidth: '20px',
  },
  tcsign_spesa: {
    width: '20px',
    minWidth: '20px',
    cursor: 'default',
  },
  actionBar: {
    width: 'auto',
    height: '64px',
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '1px 1px 2px #AAA,-1px -1px 2px #AAA,1px -1px 2px #AAA,-1px 1px 2px #AAA',
    borderRadius: '3px',
    backgroundColor: 'white',
  },
};

class TabellaUtenti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreActions: null,
    };

    this.actionsBarRef = React.createRef();
  }

  toggleActions = (index) => {
    const { showMoreActions } = this.state;
    this.setState({
      showMoreActions: index !== showMoreActions ? index : null,
    });
  };

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside);
  };

  handleClickOutside = (event) => {
    if (
      this.actionsBarRef &&
      !this.actionsBarRef.current?.contains(event.target) &&
      event.target.id !== 'moreActionsIcon'
    ) {
      this.setState({
        showMoreActions: null,
      });
    }
  };

  selectRow = (e, index) => {
    this.setState({
      selected: index,
    });
  };

  handleSelectUser = (selectedUser) => {
    delete selectedUser.actions;
    if (selectedUser) this.props.dispatch(userActions.createNewUser(selectedUser));
    this.props.dispatch(userActions.resetSearchResult());
    this.props.onClose();
  };

  render() {
    const { selected } = this.state;
    const { classes, trovati, fetching, pageSize, pageIndex, tema, t } = this.props;
    const { select } = t('action', {
      returnObjects: true,
    });
    const { previous, next, loadingTxt, page, of, rows, noDoc } = t('table', {
      returnObjects: true,
    });
    const { actions, name, lastName, email, fiscalCode } = t('table.headers', {
      returnObjects: true,
    });

    let cardColor = tema.theme_color;

    let count = trovati ? trovati.length : undefined;

    return (
      <GridItem xs={12}>
        <Card>
          <CardHeader color={cardColor} icon>
            <CardIcon color={cardColor}>
              <AccountCircle />
            </CardIcon>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4 style={{ color: '#3C4858', fontWeight: 300 }}>{t('menu.users')}</h4>
              <p style={{ color: '#3C4858', margin: 0, fontWeight: 'bold' }}>
                {count ?? '...'} {t('research.resultsFound')}
              </p>
            </div>
          </CardHeader>
          <CardBody>
            <ReactTable
              getTrProps={(state, rowInfo) => {
                if (rowInfo?.row) {
                  return {
                    onClick: (e) => this.selectRow(e, rowInfo.index),
                    style: {
                      overflow: 'hidden',
                      position: 'relative',
                      background: rowInfo.index === selected ? '#e8ffdf' : null,
                      border: rowInfo.index === selected ? '1px solid #59ff59' : null,
                    },
                  };
                } else return {};
              }}
              data={trovati.map((usr) => {
                let wReturn = ModelViewHelper.utente_ModelToView(usr, t);

                const actionsBtnList = [
                  {
                    show: true,
                    title: select,
                    btnColor: 'success',
                    btnDisabled: false,
                    onClick: () => this.handleSelectUser(wReturn),
                    icon: <CheckCircle />,
                  },
                ];

                wReturn.actions = (
                  <div className="actions-center">
                    {actionsBtnList.map((btn) => {
                      const { title, show, btnColor, btnDisabled, onClick, icon } = btn;
                      return (
                        show && (
                          <Tooltip
                            key={uuidv4()}
                            id="tooltip-top"
                            title={title}
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button
                              justIcon
                              round
                              simple
                              disabled={btnDisabled}
                              onClick={onClick}
                              color={btnColor}
                              className={classes.tcsign_action}
                            >
                              {icon}
                            </Button>
                          </Tooltip>
                        )
                      );
                    })}
                  </div>
                );

                return wReturn;
              })}
              filterable
              columns={[
                {
                  Header: name,
                  accessor: 'nome',
                  filterable: false,
                },
                {
                  Header: lastName,
                  accessor: 'cognome',
                  filterable: false,
                },
                {
                  Header: fiscalCode,
                  accessor: 'codice_fiscale',
                  filterable: false,
                },
                {
                  Header: email,
                  accessor: 'email',
                  filterable: false,
                },
                {
                  Header: actions,
                  accessor: 'actions',
                  sortable: false,
                  filterable: false,
                  maxWidth: 130,
                },
              ]}
              defaultPageSize={5}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              striped
              hover
              condensed
              insertRow
              deleteRow
              search
              previousText={previous}
              nextText={next}
              loadingText={loadingTxt}
              noDataText={noDoc}
              pageText={page}
              ofText={of}
              rowsText={rows}
            />
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

function mapStateToProps(state) {
  const { tema, users } = state;
  const { trovati, fetching, pagination } = users;
  const { size, pageIndex } = pagination;

  return {
    pageSize: size,
    pageIndex,
    trovati,
    fetching,
    tema,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(TabellaUtenti);
