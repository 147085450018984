export const documentiConstants = {
  RESET_DOCUMENT: 'DOCUMENTS_RESET',

  DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',

  DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',

  DELETE_NOTA_REQUEST: 'DELETE_NOTA_REQUEST',
  DELETE_NOTA_SUCCESS: 'DELETE_NOTA_SUCCESS',
  DELETE_NOTA_FAILURE: 'DELETE_NOTA_FAILURE',

  DELETE_FLUSSO_REQUEST: 'DELETE_FLUSSO_REQUEST',
  DELETE_FLUSSO_SUCCESS: 'DELETE_FLUSSO_SUCCESS',
  DELETE_FLUSSO_FAILURE: 'DELETE_FLUSSO_FAILURE',

  GETALL_REQUEST: 'DOCUMENTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DOCUMENTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DOCUMENTS_GETALL_FAILURE',

  GETDOCUMENT_REQUEST: 'DOCUMENTS_GETDOCUMENT_REQUEST',
  GETDOCUMENT_SUCCESS: 'DOCUMENTS_GETDOCUMENT_SUCCESS',
  GETDOCUMENT_FAILURE: 'DOCUMENTS_GETDOCUMENT_FAILURE',

  GETTASKLIST_REQUEST: 'DOCUMENTS_GETTASKLIST_REQUEST',
  GETTASKLIST_SUCCESS: 'DOCUMENTS_GETTASKLIST_SUCCESS',
  GETTASKLIST_SUCCESS_NO_CLEAN: 'GETTASKLIST_SUCCESS_NO_CLEAN',
  GETTASKLIST_MODAL_SUCCESS: 'GETTASKLIST_MODAL_SUCCESS',
  GETTASKLIST_FAILURE: 'DOCUMENTS_GETTASKLIST_FAILURE',

  GETTASK_COUNT_REQUEST: 'DOCUMENTS_GETTASK_COUNT_REQUEST',
  GETTASK_COUNT_SUCCESS: 'DOCUMENTS_GETTASK_COUNT_SUCCESS',
  GETTASK_COUNT_FAILURE: 'DOCUMENTS_GETTASK_COUNT_FAILURE',

  SAVEDOCUMENT_REQUEST: 'DOCUMENTS_SAVE_REQUEST',
  SAVEDOCUMENT_SUCCESS: 'DOCUMENTS_SAVE_SUCCESS',
  SAVEDOCUMENT_FAILURE: 'DOCUMENTS_SAVE_FAILURE',

  UPLOAD_DOCUMENT_REQUEST: 'DOCUMENTS_UPLOAD_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS: 'DOCUMENTS_UPLOAD_SUCCESS',
  UPLOAD_DOCUMENT_FAILURE: 'DOCUMENTS_UPLOAD_FAILURE',

  DOWNLOAD_DOCUMENT_REQUEST: 'DOCUMENTS_DOWNLOAD_REQUEST',
  DOWNLOAD_DOCUMENT_SUCCESS: 'DOCUMENTS_DOWNLOAD_SUCCESS',
  DOWNLOAD_DOCUMENT_FAILURE: 'DOCUMENTS_DOWNLOAD_FAILURE',

  DOWNLOAD_DOC_QRC_REQUEST: 'DOWNLOAD_DOC_QRC_REQUEST',
  DOWNLOAD_DOC_QRC_SUCCESS: 'DOWNLOAD_DOC_QRC_SUCCESS',
  DOWNLOAD_DOC_QRC_FAILURE: 'DOWNLOAD_DOC_QRC_FAILURE',

  DOCUMENT_FAVORITE_REQUEST: 'DOCUMENT_FAVORITE_REQUEST',
  DOCUMENT_FAVORITE_SUCCESS: 'DOCUMENT_FAVORITE_SUCCESS',
  DOCUMENT_FAVORITE_FAILURE: 'DOCUMENT_FAVORITE_FAILURE',

  SAVE_NOTA_REQUEST: 'NOTA_SAVE_REQUEST',
  SAVE_NOTA_SUCCESS: 'NOTA_SAVE_SUCCESS',
  SAVE_NOTA_FAILURE: 'NOTA_SAVE_FAILURE',

  SAVE_FLUSSO_REQUEST: 'FLUSSO_SAVE_REQUEST',
  SAVE_FLUSSO_SUCCESS: 'FLUSSO_SAVE_SUCCESS',
  SAVE_FLUSSO_FAILURE: 'FLUSSO_SAVE_FAILURE',

  WORKFLOW_ACTION_REQUEST: 'WORKFLOW_ACTION_REQUEST',
  WORKFLOW_ACTION_SUCCESS: 'WORKFLOW_ACTION_SUCCESS',
  WORKFLOW_ACTION_FAILURE: 'WORKFLOW_ACTION_FAILURE',

  UPDATEMETADATA_REQUEST: 'UPDATEMETADATA_REQUEST',
  UPDATEMETADATA_SUCCESS: 'UPDATEMETADATA_SUCCESS',
  UPDATEMETADATA_FAILURE: 'UPDATEMETADATA_FAILURE',

  SOLLECITO_REQUEST: 'SOLLECITO_REQUEST',
  SOLLECITO_SUCCESS: 'SOLLECITO_SUCCESS',
  SOLLECITO_FAILURE: 'SOLLECITO_FAILURE',

  COPYDOCUMENT_REQUEST: 'COPYDOCUMENT_REQUEST',
  COPYDOCUMENT_SUCCESS: 'COPYDOCUMENT_SUCCESS',
  COPYDOCUMENT_FAILURE: 'COPYDOCUMENT_FAILURE',

  SEND_PROTOCOLLO_FILE_REQUEST: 'SEND_PROTOCOLLO_FILE_REQUEST',
  SEND_PROTOCOLLO_FILE_SUCCESS: 'SEND_PROTOCOLLO_FILE_SUCCESS',
  SEND_PROTOCOLLO_FILE_FAILURE: 'SEND_PROTOCOLLO_FILE_FAILURE',

  ADD_DELEGANTE: 'ADD_DELEGANTE',

  DOCUMENT_VERIFY_REQUEST: 'DOCUMENT_VERIFY_REQUEST',
  DOCUMENT_VERIFY_SUCCESS: 'DOCUMENT_VERIFY_SUCCESS',
  DOCUMENT_VERIFY_FAILURE: 'DOCUMENT_VERIFY_FAILURE',

  RESET_DELEGANTE: 'RESET_DELEGANTE',

  CHANGE_DOCUMENT_REQUEST: 'CHANGE_DOCUMENT_REQUEST',
  CHANGE_DOCUMENT_SUCCESS: 'CHANGE_DOCUMENT_SUCCESS',
  CHANGE_DOCUMENT_FAILURE: 'CHANGE_DOCUMENT_FAILURE',

  SESSION_ID_REQUEST: 'SESSION_ID_REQUEST',
  SESSION_ID_SUCCESS: 'SESSION_ID_SUCCESS',
  SESSION_ID_FAILURE: 'SESSION_ID_FAILURE',

  MEETING_REQUEST: 'MEETING_REQUEST',
  MEETING_SUCCESS: 'MEETING_SUCCESS',
  MEETING_FAILURE: 'MEETING_FAILURE',

  MEETING_DETAILS_REQUEST: 'MEETING_DETAILS_REQUEST',
  MEETING_DETAILS_SUCCESS: 'MEETING_DETAILS_SUCCESS',
  MEETING_DETAILS_FAILURE: 'MEETING_DETAILS_FAILURE',

  TOPIC_REQUEST: 'TOPIC_REQUEST',
  TOPIC_SUCCESS: 'TOPIC_SUCCESS',
  TOPIC_FAILURE: 'TOPIC_FAILURE',

  TOPIC_DETAILS_REQUEST: 'TOPIC_DETAILS_REQUEST',
  TOPIC_DETAILS_SUCCESS: 'TOPIC_DETAILS_SUCCESS',
  TOPIC_DETAILS_FAILURE: 'TOPIC_DETAILS_FAILURE',

  CLEAR_MEETING_DETAILS: 'CLEAR_MEETING_DETAILS',

  EMPTY_ITEMS_SUCCESS: 'EMPTY_ITEMS_SUCCESS',

  GET_DOC_FOR_EXTERNAL_REQUEST: 'GET_DOC_FOR_EXTERNAL_REQUEST',
  GET_DOC_FOR_EXTERNAL_SUCCESS: 'GET_DOC_FOR_EXTERNAL_SUCCESS',
  GET_DOC_FOR_EXTERNAL_FAILURE: 'GET_DOC_FOR_EXTERNAL_FAILURE',

  GET_OTP_REQUEST: 'GET_OTP_REQUEST',
  GET_OTP_SUCCESS: 'GET_OTP_SUCCESS',
  GET_OTP_FAILURE: 'GET_OTP_FAILURE',

  GET_VERIFY_OTP_REQUEST: 'GET_VERIFY_OTP_REQUEST',
  GET_VERIFY_OTP_SUCCESS: 'GET_VERIFY_OTP_SUCCESS',
  GET_VERIFY_OTP_FAILURE: 'GET_VERIFY_OTP_FAILURE',

  WORKFLOW_EXT_ACTION_REQUEST: 'WORKFLOW_EXT_ACTION_REQUEST',
  WORKFLOW_EXT_ACTION_SUCCESS: 'WORKFLOW_EXT_ACTION_SUCCESS',
  WORKFLOW_EXT_ACTION_FAILURE: 'WORKFLOW_EXT_ACTION_FAILURE',

  UPLOAD_FIRMA_DIGITALE: 'UPLOAD_FIRMA_DIGITALE',
  UPLOAD_FIRMA_DIGITALE_SUCCESS: 'UPLOAD_FIRMA_DIGITALE_SUCCESS',
  UPLOAD_FIRMA_DIGITALE_FAILURE: 'UPLOAD_FIRMA_DIGITALE_FAILURE',

  UPDATE_INSERT_COORD: 'UPDATE_INSERT_COORD',
  UPDATE_INSERT_COORD_SUCCESS: 'UPDATE_INSERT_COORD_SUCCESS',
  UPDATE_INSERT_COORD_FAILURE: 'UPDATE_INSERT_COORD_FAILURE',

  OTP_REQUEST: 'OTP_REQUEST',
  OTP_SUCCESS: 'OTP_SUCCESS',
  OTP_FAILURE: 'OTP_FAILURE',

  DEFAULT_COORD_REQUEST: 'DEFAULT_COORD_REQUEST',
  DEFAULT_COORD_SUCCESS: 'DEFAULT_COORD_SUCCESS',
  DEFAULT_COORD_FAILURE: 'DEFAULT_COORD_FAILURE',

  MULTIPLE_DOC_INSPECTION_REQUEST: 'MULTIPLE_DOC_INSPECTION_REQUEST',
  MULTIPLE_DOC_INSPECTION_SUCCESS: 'MULTIPLE_DOC_INSPECTION_SUCCESS',
  MULTIPLE_DOC_INSPECTION_FAILURE: 'MULTIPLE_DOC_INSPECTION_FAILURE',

  RESET_COORDINATE_DEFAULT: 'RESET_COORDINATE_DEFAULT',

  GET_ARCHIVIAZIONE_DOCUMENTO_REQUEST: 'GET_ARCHIVIAZIONE_DOCUMENTO_REQUEST',
  GET_ARCHIVIAZIONE_DOCUMENTO_SUCCESS: 'GET_ARCHIVIAZIONE_DOCUMENTO_SUCCESS',
  GET_ARCHIVIAZIONE_DOCUMENTO_FAILURE: 'GET_ARCHIVIAZIONE_DOCUMENTO_FAILURE',

  RESET_DATI_ARCHIVIAZIONE_REQUEST: 'RESET_DATI_ARCHIVIAZIONE_REQUEST',

  INVIO_SOLLECITO_MULTIPLO_REQUEST: 'INVIO_SOLLECITO_MULTIPLO_REQUEST',
  INVIO_SOLLECITO_MULTIPLO_SUCCESS: 'INVIO_SOLLECITO_MULTIPLO_SUCCESS',
  INVIO_SOLLECITO_MULTIPLO_FAILURE: 'INVIO_SOLLECITO_MULTIPLO_FAILURE',
};
