export const raccoglitoriConstants = {
  RACCOGLITORI_GETALL_REQUEST: 'RACCOGLITORI_GETALL_REQUEST',
  RACCOGLITORI_GETALL_SUCCESS: 'RACCOGLITORI_GETALL_SUCCESS',
  RACCOGLITORI_GETALL_FAILURE: 'RACCOGLITORI_GETALL_FAILURE',

  RACCOGLITORI_GET_REQUEST: 'RACCOGLITORI_GET_REQUEST',
  RACCOGLITORI_GET_SUCCESS: 'RACCOGLITORI_GET_SUCCESS',
  RACCOGLITORI_GET_FAILURE: 'RACCOGLITORI_GET_FAILURE',

  RACCOGLITORI_GET_FIRMATARIO_REQUEST: 'RACCOGLITORI_GET_FIRMATARIO_REQUEST',
  RACCOGLITORI_GET_FIRMATARIO_SUCCESS: 'RACCOGLITORI_GET_FIRMATARIO_SUCCESS',
  RACCOGLITORI_GET_FIRMATARIO_FAILURE: 'RACCOGLITORI_GET_FIRMATARIO_FAILURE',

  RACCOGLITORI_SAVE_REQUEST: 'RACCOGLITORI_SAVE_REQUEST',
  RACCOGLITORI_SAVE_SUCCESS: 'RACCOGLITORI_SAVE_SUCCESS',
  RACCOGLITORI_SAVE_FAILURE: 'RACCOGLITORI_SAVE_FAILURE',

  RACCOGLITORI_DELETE_REQUEST: 'RACCOGLITORI_DELETE_REQUEST',
  RACCOGLITORI_DELETE_SUCCESS: 'RACCOGLITORI_DELETE_SUCCESS',
  RACCOGLITORI_DELETE_FAILURE: 'RACCOGLITORI_DELETE_FAILURE',

  RACCOGLITORI_SAVE_PERCORSO_FIRMA_REQUEST: 'RACCOGLITORI_SAVE_PERCORSO_FIRMA_REQUEST',
  RACCOGLITORI_SAVE_PERCORSO_FIRMA_SUCCESS: 'RACCOGLITORI_SAVE_PERCORSO_FIRMA_SUCCESS',
  RACCOGLITORI_SAVE_PERCORSO_FIRMA_FAILURE: 'RACCOGLITORI_SAVE_PERCORSO_FIRMA_FAILURE',

  RECUPERA_DOCUMENTO_REQUEST: 'RECUPERA_DOCUMENTO_REQUEST',
  RECUPERA_DOCUMENTO_SUCCESS: 'RECUPERA_DOCUMENTO_SUCCESS',
  RECUPERA_DOCUMENTO_FAILURE: 'RECUPERA_DOCUMENTO_FAILURE',

  RESET_RACCOGLITORE: 'RACCOGLITORI_RESET',
};
