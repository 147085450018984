import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTable from 'react-table';

// @material-ui/core components
import { withStyles, Tooltip } from '@material-ui/core';
import { HiDocumentReport } from 'react-icons/hi';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Download from '@material-ui/icons/CloudDownload';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { analyticsActions } from '../../Actions';

const styles = () => ({
  ...dashboardStyle,
  ...regularFormsStyle,
  header: {
    paddingTop: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const TCSign_Reports = ({ classes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { previous, next, loadingTxt, page, of, rows, noDoc } = t('table', {
    returnObjects: true,
  });
  const { referenceMonth, name, download } = t('table.headers', {
    returnObjects: true,
  });

  const cardColor = useSelector((state) => state.tema.theme_color);
  const { loading, result } = useSelector(({ analytics }) => analytics.reports);

  useEffect(() => dispatch(analyticsActions.findAllReports()), []);

  const onClick = (id_report, filename, type) =>
    dispatch(analyticsActions.downloadReport(id_report, filename, type));

  const columns = [
    {
      Header: 'ID',
      accessor: 'id_report',
      width: 50,
      minWidth: 50,
    },
    {
      Header: name,
      accessor: 'nome',
      maxWidth: 200,
      filterMethod: (filter, row) =>
        row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
    },
    {
      Header: referenceMonth,
      accessor: 'mese',
      maxWidth: 200,
    },
    {
      Header: () => <div style={{ display: 'flex', alignContent: 'flex-start' }}> {download}</div>,
      accessor: 'actions',
      sortable: false,
      filterable: false,
      maxWidth: 300,
      Cell: ({ original: { id_report, nome_file } }) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            round
            onClick={() => onClick(id_report, nome_file, 'report')}
            color={'rose'}
            style={{ height: '35px' }}
          >
            <Download /> {t('functions.Report')}
          </Button>
          <Button
            round
            onClick={() => onClick(id_report, nome_file, 'vista')}
            color={'rose'}
            style={{ height: '35px' }}
          >
            <Download /> {t('functions.View')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color={cardColor} icon>
            <CardIcon color={cardColor}>
              <HiDocumentReport size={28} />
            </CardIcon>
            <h3 style={{ display: 'inline' }} className={classes.cardTitle}>
              {t('menu.reports')}
            </h3>
          </CardHeader>
          <CardBody style={{ paddingTop: '0px', height: '100%' }}>
            <ReactTable
              resizable={false}
              data={result}
              columns={columns}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              striped
              hover
              condensed
              pagination
              insertRow
              deleteRow
              search
              loading={loading}
              previousText={previous}
              nextText={next}
              loadingText={loadingTxt}
              noDataText={noDoc}
              pageText={page}
              ofText={of}
              rowsText={rows}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(styles)(TCSign_Reports);
