import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en/common.json';
import it from './translations/it/common.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: en },
  it: { translation: it },
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    fallbackLng: 'it',
    supportedLngs: ['it', 'en'],
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    debug: true,
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
