import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import { searchActions } from '../../../Actions';

const TipoRicerca = ({ tipoRicerca, dispatch }) => {
  const { t } = useTranslation();
  const { atLeast, allWords, exactPhrase } = t('research', { returnObjects: true });

  const onChange = (event) => {
    const { value } = event.target;
    dispatch(searchActions.setTipoRicerca(value));
  };

  const tipoRicercaOptions = [
    {
      value: 'or',
      label: atLeast,
    },
    {
      value: 'and',
      label: allWords,
    },
    {
      value: 'phrase',
      label: exactPhrase,
    },
  ];

  return (
    <RadioGroup row aria-label="position" name="tipoRicerca" defaultValue="or" onChange={onChange}>
      {tipoRicercaOptions.map((tipo) => {
        const { value, label } = tipo;
        return (
          <FormControlLabel
            key={label}
            value={value}
            label={label}
            size="small"
            labelPlacement="end"
            control={<Radio color="primary" checked={tipoRicerca === value ? true : false} />}
          />
        );
      })}
    </RadioGroup>
  );
};

const mapStateToProps = (state) => {
  const { tipoRicerca } = state.search;
  return { tipoRicerca };
};

export default connect(mapStateToProps)(TipoRicerca);
