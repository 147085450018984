import React, { useState } from 'react';
import OTPVerification from './OTPVerification';
import SignatureWizard from './SignatureWizard';
import SetSignature from './SetSignature';

const FEA = ({ firmatario, setSignature, applySignature }) => {
  const [phase, setPhase] = useState(1);

  return (
    <div
      style={{
        width: '100%',
        height: '380px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}
    >
      <SignatureWizard step={phase}>
        {phase === 1 ? (
          <SetSignature firmatario={firmatario} setPhase={setPhase} setSignature={setSignature} />
        ) : phase === 2 ? (
          <OTPVerification setPhase={setPhase} applySignature={applySignature} />
        ) : null}
      </SignatureWizard>
    </div>
  );
};

export default FEA;
