import React from 'react';
import { Dialog, Slide, withStyles, Button } from '@material-ui/core';
import { VscChromeClose } from 'react-icons/vsc';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = () => ({
  paperStyle: {
    width: '550px',
    height: '425px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1.5em',
    maxHeight: '490px', //comment this line to make this box responsive
  },
  closeButton: {
    minWidth: 0,
    padding: 0,
    height: 0,
  },
});

const Wrapper = ({ children, classes, height, close }) => {
  return (
    <Dialog
      open={true}
      keepMounted
      TransitionComponent={Transition}
      PaperProps={{
        className: classes.paperStyle,
        style: height ? { height } : null,
      }}
    >
      <Button
        className={classes.closeButton}
        onClick={close}
        style={{ position: 'absolute', top: '1.5em', right: '1.5em', zIndex: 2 }}
      >
        <VscChromeClose />
      </Button>
      {children}
    </Dialog>
  );
};

export default withStyles(styles)(Wrapper);
