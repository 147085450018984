import React, { memo } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const selectStyles = {
  label: ({ isFocused }) => ({
    color: isFocused ? '#0047BB' : '#AAA',
    position: 'absolute',
    backgroundColor: 'white',
    fontSize: '0.75rem',
    top: -8,
    left: 10,
    padding: '0 4px',
    zIndex: 2,
  }),
  control: (styles) => ({
    ...styles,
    padding: '8px 10x',
    width: '200px',
    boxShadow: 'none',
    '&:focus-within': {
      boxShadow: 'none',
      borderWidth: '2px',
      borderColor: '#0047BB',
    },
    '&:hover': {
      borderColor: 'black',
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? '#0047BB' : isFocused ? '#0047BB' : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : null,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '2',
    ':active': {
      backgroundColor: isSelected ? '#07a89e' : null,
    },
  }),
  input: (styles) => ({
    ...styles,
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.42857',
    width: '100%',
    div: { width: '100%' },
  }),
  singleValue: (styles) => ({
    ...styles,
    opacity: '1',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.42857',
  }),
  menuList: (styles) => ({ ...styles, zIndex: 9999, position: 'relative' }),
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
  menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
  placeholder: (styles) => ({
    ...styles,
    color: '#AAA !important',
    fontSize: '14px',
    fontWeight: '400',
  }),
};

const SelectUser = ({ id, name, label, value, placeholder, onChange, ...props }) => {
  const { defaultOptions, error, success, disabled, isOptionDisabled } = props;
  const { isSearchable, isClearable, loadOptions } = props;

  const Control = (props) => (
    <div style={{ position: 'relative' }}>
      {<label style={selectStyles.label(props)}>{label}</label>}
      <components.Control {...props} />
    </div>
  );

  const Option = (props) => {
    const { children } = props;
    const wProp = { ...props };

    if (children?.length >= 50) {
      const split = children.split('->');
      const label =
        split.length === 1
          ? split[0]
          : split.length >= 2
          ? '...' + split[split.length - 2] + '->' + split[split.length - 1]
          : '';

      wProp.children = label;
    }

    return <components.Option {...wProp} />;
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <Tooltip placement="top-start" title={children}>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            {children}
          </div>
        </Tooltip>
      </components.SingleValue>
    );
  };

  const currentStyle = {
    ...selectStyles,
    placeholder: (styles) => ({
      ...styles,
      ...selectStyles.placeholder(),
      color: error ? '#F00 !important' : success ? '#4daf51 !important' : '#AAA !important',
    }),
  };

  return (
    <AsyncSelect
      value={value}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isSearchable}
      styles={currentStyle}
      onChange={onChange}
      components={{ Control, Option, SingleValue }}
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
    />
  );
};

SelectUser.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
};

export default memo(withStyles(selectStyles)(SelectUser));
