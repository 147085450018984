// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const footerStyle = {
  block: {},
  right: {
    margin: '0',
    fontSize: '14px',
    float: 'right!important',
    padding: '0px',
  },
  left: {
    margin: '0',
    fontSize: '14px',
    float: 'left!important',
    display: 'block',
    padding: '0px',
    position: 'absolute',
    bottom: '0',
  },
  center: {
    margin: '0',
    fontSize: '14px',
    padding: '0px',
    position: 'absolute',
    bottom: '20%',
    right: '50%',
  },
  center_mobile: {
    margin: '0',
    fontSize: '12px',
    padding: '0px',
    position: 'absolute',
    bottom: '20%',
    right: '50%',
  },
  footer: {
    bottom: '0',
    borderTop: '1px solid #e7e7e7',
    padding: '0px',
    ...defaultFont,
    zIndex: 4,
    position: 'fixed',
    backgroundColor: '#eee',
    width: 'calc(100% - 260px)',
  },
  footer_mobile: {
    bottom: '0',
    borderTop: '1px solid #e7e7e7',
    paddingBottom: '50px',
    ...defaultFont,
    zIndex: 4,
    position: 'fixed',
    backgroundColor: '#eee',
    width: '100%',
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative',
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative',
  },
  a: {
    color: primaryColor,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto',
  },
  whiteColor: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },
};
export default footerStyle;
