import React from 'react';
import { connect } from 'react-redux';
import { documentiActions, posFirmaActions } from '../../Actions';

// @material-ui/core components
import { Dialog, DialogContent, DialogActions, DialogTitle, FormControl } from '@material-ui/core';
import { Select, Slide, MenuItem, withStyles, InputLabel } from '@material-ui/core';

// icons
import { Close } from '@material-ui/icons';
import SignDocument from '@material-ui/icons/BorderColor';
import { BiTargetLock } from 'react-icons/bi';
import SecurityIcon from '@material-ui/icons/Security';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import FormValidationHelper from '../../Helpers/form-validation.js';
import {
  areArraysOfObjectsEqual,
  capitalizeFirstLetter,
  getModifiedCoord,
  toPDFPixels,
} from '../../Helpers';

import PosizionamentoFirmaModal from '../Utilities/PosizionamentoFirmaModal';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  ...extendedFormsStyle,
  ...regularFormsStyle,
  tcSignTableButton: {
    margin: '0px',
    padding: '0px',
    height: 'auto!important',
  },
  tcSignTableRow: {
    height: '28px!important',
  },
  tcSignTableHeadRow: {
    height: '28px',
  },
  tcSignTableCell: {
    height: '28px!important',
  },
  tcSignTableHead: {
    color: '#AAA !important',
    padding: '14px 0px 0px 0px!important',
    fontSize: '11px!important',
    lineHeight: '1rem!important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  tcSignInputStyle: {
    fontSize: '8px',
  },
  selectLabel: {
    fontSize: '14px',
    //textTransform: "uppercase",
    color: '#AAA !important',
    top: '8px',
  },
  tcSignSelectControl: {
    margin: '7px 1px 0px 0px !important',
  },
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '0px',
  },
  tcsign_cardbody: {
    'padding-top': '0',
    'padding-bottom': '0',
  },
  tcsign_switchIconChecked: {
    'border-color': '#AAA',
  },
  tcsign_dialog: {
    'max-width': '700px',
  },
};

const initialState = {
  username: '',
  password: '',
  otp: '',
  usernameState: undefined,
  passwordState: undefined,
  otpState: undefined,
  firma_selected: undefined,
  firma_selectedState: false,
  showPosFirma: false,
  saveDisabled: true,
  coordinata: {},
};

class FirmaModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  handleClose() {
    this.setState({
      otp: '',
      usernameState: undefined,
      passwordState: undefined,
      otpState: undefined,
      firma_selected: undefined,
      firma_selectedState: false,
    });
    this.props.onClose(this.props.daModale);
  }

  handleSubmit() {
    let otpState = FormValidationHelper.validateField('required', this.state.otp, 1);
    let selectState = this.getFormFieldValue('firma_selected') === '';

    this.setState({
      otpState,
      firma_selectedState: selectState,
    });

    if (otpState === 'error' || selectState === true) {
      return;
    } else {
      let returnData = {
        username: this.state.username,
        id_digital_signature: this.state.id_digital_signature,
        otp: this.state.otp,
      };

      this.props.onSubmit(returnData);

      this.handleClose();
    }
  }

  handleOtpRequest() {
    let selectState = this.getFormFieldValue('firma_selected') === '';
    if (selectState === true) {
      return;
    } else {
      let credentials = {
        username: this.state.username,
      };
      this.props.dispatch(documentiActions.otpRequest(credentials));
    }
  }

  handleSelect = (event) => {
    const { user } = this.props;

    for (var i = 0; i < user.firma.length; i++) {
      if (user.firma[i].id_digital_signature === event.target.value) {
        this.setState({
          firma_selected: event.target.value,
          username: user.firma[i].username,
          id_digital_signature: user.firma[i].id_digital_signature,
        });

        return;
      }
    }
  };

  componentDidMount() {
    const { user } = this.props;
    var selectedValue = this.getFormFieldValue('firma_selected');

    for (var i = 0; i < user.firma.length; i++) {
      if (user.firma[i].id_digital_signature === selectedValue) {
        this.setState({
          username: user.firma[i].username,
          id_digital_signature: user.firma[i].id_digital_signature,
        });
      }
    }
  }

  getFormFieldValue(pFieldName) {
    const { user } = this.props;

    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    }

    if (pFieldName === 'firma_selected') {
      if (user?.firma) {
        for (let i = 0; i < user.firma.length; i++) {
          if (user.firma[i].principale) {
            return user.firma[i].id_digital_signature;
          }
        }
      }
    }

    return '';
  }

  change(event, stateName, type) {
    const { id, value } = event.target;

    this.setState({ [id]: value });
    switch (type) {
      case 'required':
        this.setState({
          [stateName + 'State']: FormValidationHelper.validateField(
            'required',
            event.target.value,
            1
          ),
        });
        break;
      default:
        break;
    }
  }

  openPosizionamentoFirma = () => {
    this.setState({
      showPosFirma: true,
    });
  };

  closePosizionamentoFirma = () => {
    this.setState({
      showPosFirma: false,
      saveDisabled: true,
    });
    this.props.dispatch(posFirmaActions.resetPosizionamentoFirma());
  };

  componentDidUpdate = (prevProps) => {
    let coordinata = {};
    const prevTags = prevProps.posizionamentoFirma?.tags;
    const currentTags = this.props.posizionamentoFirma?.tags;
    const { documenti } = this.props;

    if (prevTags && currentTags) {
      if (currentTags.length > prevTags.length) {
        const { id_coordinata } = this.state.coordinata;
        //get new coord
        const newTag = currentTags.filter((tag) => !tag.id_coordinata);
        if (newTag.length > 0) coordinata = newTag[0];

        //check if it was deleted but not saved or also saved
        let wasCoordDeleted =
          documenti.current.coordinate.filter((coord) => coord.id_coordinata === id_coordinata)
            .length === 0;

        if (Object.keys(this.state.coordinata).length === 1 && !wasCoordDeleted)
          coordinata.id_coordinata = id_coordinata;
        this.setState({
          coordinata,
          saveDisabled: false,
        });
      } else if (currentTags.length < prevTags.length) {
        let id_coordinata;

        prevTags.forEach((pTag) => {
          let array = currentTags.filter((cTag) => cTag.id_coordinata === pTag.id_coordinata);
          if (array.length === 0) id_coordinata = pTag.id_coordinata;
        });
        coordinata = { id_coordinata };
        this.setState({
          coordinata,
          saveDisabled: false,
        });
      } else if (!areArraysOfObjectsEqual(prevTags, currentTags)) {
        coordinata = getModifiedCoord(prevTags, currentTags);
        this.setState({
          coordinata,
          saveDisabled: false,
        });
      }
    }
  };

  savePos = () => {
    const { coordinata } = this.state;
    const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } =
      this.props.posizionamentoFirma?.dimensions ?? {};

    let coord = JSON.parse(JSON.stringify(coordinata));

    if (Object.keys(coordinata).length > 1) {
      coord = JSON.parse(JSON.stringify(coordinata));
      const pdfCoord = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
        x: coord.left,
        y: coord.top,
      });

      if (coord.default_coord) coord.default_coord = false;

      coord.id_documento = this.props.docID;
      coord.coordinata_x = pdfCoord.x;
      coord.coordinata_y = pdfCoord.y;
      coord.larghezza_rettangolo = 145;
      coord.altezza_rettangolo = 38;

      delete coord.tipo;
      delete coord.firmatario;
      delete coord.id;
      delete coord.left;
      delete coord.top;
    }

    this.props.dispatch(documentiActions.updateInsertCoord(coord, this.props.docID));
  };

  render() {
    const { showPosFirma, otp, otpState, firma_selectedState, saveDisabled } = this.state;
    const { t, classes, user, label, docID, uuid, documenti } = this.props;

    let listaFirmatariInterni;

    if (documenti?.current) {
      let currFlow = documenti.current.flusso_operativo.filter(
        (flusso) => flusso.current_step === 'S'
      )[0];
      if (currFlow) {
        const { id_utente, utente_owner, uo, id_uo } = currFlow;

        listaFirmatariInterni = [
          {
            value: capitalizeFirstLetter(utente_owner ?? uo),
            label: capitalizeFirstLetter(utente_owner ?? uo),
            id: id_utente ?? id_uo,
            tipo_firmatario: id_utente ? 'Interno' : 'InternoUO',
          },
        ];
      }
    }

    let optionFirma = '';
    if (typeof user !== 'undefined' && typeof user.firma !== 'undefined') {
      optionFirma = user.firma.map((prop) => {
        return (
          <MenuItem
            key={prop.id_digital_signature}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={prop.id_digital_signature}
          >
            {prop.username}
          </MenuItem>
        );
      });
    }

    let selectError = firma_selectedState;

    return (
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle>
          {t('misc.signModalTitle')} {label}:
        </DialogTitle>
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                className={classes.selectFormControl + ' ' + classes.tcSignSelectControl}
                error={selectError}
              >
                <InputLabel
                  htmlFor="firma_selected"
                  className={classes.selectLabel}
                  error={selectError}
                >
                  {label}
                </InputLabel>
                <Select
                  classes={{ select: classes.select }}
                  value={this.getFormFieldValue('firma_selected')}
                  onChange={this.handleSelect}
                  inputProps={{ name: 'firma_selected', id: 'firma_selected' }}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                    Seleziona...
                  </MenuItem>
                  {optionFirma}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                success={otpState === 'success'}
                error={otpState === 'error'}
                labelText="OTP"
                id="otp"
                formControlProps={{ fullWidth: true, required: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'otp', 'required'),
                  type: 'otp',
                  value: otp,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          {user?.providerFirma === 'infocert' && (
            <Button color="bluNavy" onClick={() => this.handleOtpRequest()}>
              <SecurityIcon /> Richiedi OTP
            </Button>
          )}
          {label === 'firma' && (
            <Button color="success" onClick={this.openPosizionamentoFirma}>
              <BiTargetLock /> Posiziona firma
            </Button>
          )}
          <Button color="success" onClick={() => this.handleSubmit()}>
            <SignDocument /> {label}
          </Button>
          <Button color="info" onClick={() => this.handleClose()}>
            <Close /> {t('action.close')}
          </Button>
        </DialogActions>
        {showPosFirma && documenti.current && (
          <PosizionamentoFirmaModal
            docID={docID}
            uuid={uuid}
            currentTab="Firma"
            listaFirmatariInterni={listaFirmatariInterni}
            saveDisabled={saveDisabled}
            handleClose={this.closePosizionamentoFirma}
            handleSubmit={this.savePos}
          />
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, documenti, posizionamentoFirma } = state;
  const { user } = authentication ?? {};

  return {
    user,
    alert,
    documenti,
    posizionamentoFirma,
  };
}

const connectedFirmaModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(FirmaModalForm);
export default connectedFirmaModalForm;
