import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { documenti } from './documenti.reducer';
import { raccoglitori } from './raccoglitori.reducer';
import { utils } from './utils.reducer';
import { settings } from './settings.reducer';
import { posizionamentoFirma } from './posFirma.reducer';
import { search } from './search.reducer';
import { tema } from './tema.reducer';
import { analytics } from './analytics.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  documenti,
  raccoglitori,
  utils,
  settings,
  posizionamentoFirma,
  search,
  tema,
  analytics
});

export default rootReducer;
