import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import SelectTooltip from './SelectTooltip';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = () => ({
  ...extendedFormsStyle,
  tooltip: {
    fontSize: '18px',
  },
  selectLabel: {
    fontSize: '14px',
    color: '#AAA !important',
    top: '8px',
  },
});

class SelectUnitaOrganizzativa extends React.Component {
  state = {
    id_uo_group: '',
    nome_uo: '',
  };

  componentDidMount() {
    this.setState({
      id_uo_group: '',
      nome_uo: '',
    });
  }

  initialize() {
    this.setState({
      id_uo_group: undefined,
    });
  }

  handleSelect = (event) => {
    const { value, label } = event.target;
    this.setState({
      id_uo_group: value,
    });

    this.props.onChange({
      id_unita_organizzativa: value,
      path: label,
    });
  };

  render() {
    const { t, classes, utils, users, id_utente } = this.props;

    let optionUnitaOrganizzative = [];

    if (id_utente !== undefined) {
      if (users?.current?.unita_organizzative) {
        optionUnitaOrganizzative = users.current.unita_organizzative.map((prop) => {
          return {
            value: prop.id_uo_group,
            label: prop.nome,
            dismessa: prop.dismessa,
          };
        });
      }
    } else {
      if (utils.unita_organizzative !== undefined) {
        optionUnitaOrganizzative = utils.unita_organizzative.map((prop) => {
          return {
            value: prop.id_uo_group,
            label: prop.path,
            dismessa: prop.dismessa,
          };
        });
      }
    }

    let wSelectedElement =
      this.state.id_uo_group !== ''
        ? this.state.id_uo_group
        : this.props.id_uo_group !== ''
        ? this.props.id_uo_group
        : undefined;

    if (this.props.init === true) {
      wSelectedElement = '';
    }

    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <SelectTooltip
          disabled={this.props.disabled}
          success={this.props.success}
          error={this.props.error}
          label={this.props.label === undefined ? t('document.labelUO') : this.props.label}
          placeholder={t('document.labelUO')}
          options={optionUnitaOrganizzative}
          isClearable={true}
          id={'id_uo_group'}
          isSearchable={true}
          value={
            wSelectedElement && wSelectedElement !== ''
              ? wSelectedElement
              : users.current && users.current.unita_organizzative && id_utente
              ? users.current.unita_organizzative[0].id_uo_group
              : undefined
          }
          onChange={this.handleSelect}
          isOptionDisabled={(option) => option.dismessa === true}
        />
      </FormControl>
    );
  }
}

SelectUnitaOrganizzativa.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { utils, users } = state;

  return {
    utils,
    users,
  };
}

const connectedSelectUnitaOrganizzative = compose(
  connect(mapStateToProps),
  withTranslation(),
  withStyles(styles)
)(SelectUnitaOrganizzativa);
export default connectedSelectUnitaOrganizzative;
