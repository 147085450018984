import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { alertActions, utilsActions } from '../../Actions';
import FormValidationHelper from '../../Helpers/form-validation.js';
import { generateOTP } from '../../Helpers/utils';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { withSnackbar } from 'notistack';

// @material-ui/core components
import { Dialog, DialogContent, DialogActions, Slide, withStyles } from '@material-ui/core';
import { Checkbox, FormLabel, FormControl, FormControlLabel } from '@material-ui/core/';
import { Close, Add, Save } from '@material-ui/icons';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SelectSearch from 'Custom/SelectSearch.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  //...extendedFormsStyle,
  ...regularFormsStyle,
};

const initialState = {
  enableAccessCode: false,
  selectedContact: undefined,
  disabledAdd: true,
  disabledField: true,
  disabledBtnSave: true,
};

class ContattoEsternoModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);

    const { tipo_percorso, tipoFirma } = props;

    this.state = {
      ...initialState,
      enable_fea:
        tipo_percorso === 'int' || (['mix', 'ext'].includes(tipo_percorso) && tipoFirma === 'fea'),
      enable_feq: ['mix', 'ext'].includes(tipo_percorso) && tipoFirma === 'fd',
      enable_upload: ['mix', 'ext'].includes(tipo_percorso) && tipoFirma === 'fd',
    };
  }

  handleClose() {
    this.setState(initialState);
    this.resetFields(true);
    this.props.dispatch(utilsActions.unselectSavedContact());
    this.props.onClose();
  }

  getFormFieldValue(pFieldName) {
    const { utils } = this.props;

    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    } else if (pFieldName === 'id_uo_group' && typeof this.state.selectedUO !== 'undefined') {
      return this.state.selectedUO;
    } else if (typeof utils.current_contact !== 'undefined') {
      if (pFieldName === 'id_prefisso') {
        let val = utils.current_contact['prefisso_internazionale'];
        if (val) return parseInt(val.replace('+', ''));
      } else return utils.current_contact ? utils.current_contact[pFieldName] : undefined;
    }
    return '';
  }

  change(event, elemName) {
    const { value } = event.target;
    let changedField = {};
    changedField[elemName] = value;
    this.setState(changedField);
  }

  handleSaveSigner() {
    const { t, utils } = this.props;
    let nomeState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('nome'),
      1
    );
    let cognomeState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('cognome'),
      1
    );
    let aziendaState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('azienda'),
      1
    );
    // Almeno una tra email e pec deve essere valorizzata, segnalo l'errore solo se sono entrambe non valorizzate
    let codiceFiscaleState = this.getFormFieldValue('codice_fiscale')
      ? FormValidationHelper.validateField(
          'codice_fiscale',
          this.getFormFieldValue('codice_fiscale'),
          1
        )
      : undefined;
    // FormValidationHelper.validateField('required', this.getFormFieldValue('codice_fiscale'), 1) &&

    let emailState = FormValidationHelper.validateField(
      'email',
      this.getFormFieldValue('email'),
      1
    );
    let cellulareState = this.getFormFieldValue('cellulare')
      ? FormValidationHelper.validateField('phone', this.getFormFieldValue('cellulare'), 1)
      : undefined;
    let id_uo_group_state = FormValidationHelper.validateField(
      'required-select',
      this.getFormFieldValue('id_uo_group'),
      1
    );

    if (aziendaState === 'success') {
      nomeState = 'success';
      cognomeState = 'success';
    } else if (nomeState === 'success' && cognomeState === 'success') {
      aziendaState = 'success';
    }
    let webState = {
      nomeState,
      cognomeState,
      aziendaState,
      emailState,
      cellulareState,
      codiceFiscaleState,
      id_uo_group_state,
    };

    if (
      nomeState === 'error' ||
      cognomeState === 'error' ||
      aziendaState === 'error' ||
      emailState === 'error' ||
      cellulareState === 'error' ||
      id_uo_group_state === 'error' ||
      codiceFiscaleState === 'error'
    ) {
      this.setState(webState);
      this.props.dispatch(alertActions.error(t('alert.contactRequire')));
      return;
    } else {
      this.setState(this.resetSignerState());

      let contatto = this.recuperaValoriContatto();

      this.setState({
        enableAccessCode: contatto.codice_fiscale ? false : true,
      });

      this.props.dispatch(
        utilsActions.saveContatto({
          contatto,
          id_uo_group: this.state.selectedUO.id_uo_group || utils.current_uo.id_uo_group,
        })
      );
    }
  }

  resetSignerState() {
    let contactState = {
      nomeState: undefined,
      cognomeState: undefined,
      aziendaState: undefined,
      emailState: undefined,
      cellulareState: undefined,
      codiceFiscaleState: undefined,
    };

    return contactState;
  }

  recuperaValoriContatto() {
    const { user } = this.props;
    let contatto = {
      id_contatto:
        this.getFormFieldValue('id_contatto') && this.getFormFieldValue('id_contatto') !== ''
          ? this.getFormFieldValue('id_contatto')
          : undefined,
      nome: this.getFormFieldValue('nome'),
      cognome: this.getFormFieldValue('cognome'),
      email:
        this.getFormFieldValue('email') && this.getFormFieldValue('email') !== ''
          ? this.getFormFieldValue('email')
          : null,
      codice_fiscale: this.getFormFieldValue('codice_fiscale'),
      azienda: this.getFormFieldValue('azienda'),
      utente_ult_modif: user.nome + ' ' + user.cognome,
      data_creazione: moment(new Date()).format(),
      cellulare: this.getFormFieldValue('cellulare'),
      prefisso_internazionale: this.getFormFieldValue('id_prefisso')
        ? '+' + this.getFormFieldValue('id_prefisso')
        : '+39',
    };
    return contatto;
  }

  handleSelectedContact = ({ target: { value } }) => {
    this.props.dispatch(utilsActions.selectSavedContact(value));
    if (value && !value.codice_fiscale) this.setState({ enableAccessCode: true });
    else this.setState({ enableAccessCode: false });
    this.resetFields(value ? false : true, this.state.selectedUO, false, false);
  };

  handleSelectedUO({ target: { value } }) {
    if (typeof value === 'undefined') {
      value = '-1';
      this.props.dispatch(utilsActions.unselectContacts());
    }
    if (value) {
      this.props.dispatch(utilsActions.getContattiEsterniByUo(value.id_uo_group));
    }
    this.props.dispatch(utilsActions.unselectSavedContact());
    this.setState(this.resetSignerState());
    this.setState({ enableAccessCode: false });
    this.resetFields(true, value, value !== '-1' ? false : true, value !== '-1' ? false : true);
  }

  handlePrefix = (event) => {
    const { name, value } = event.target;
    if (typeof value === 'undefined') {
      value = '-1';
    }
    let wState = { [name]: value };
    this.setState(wState);
  };

  resetFields(disabled, selectedUO, disabledField, disabledBtnSave) {
    this.setState({
      nome: undefined,
      cognome: undefined,
      email: undefined,
      azienda: undefined,
      id_prefisso: undefined,
      cellulare: undefined,
      codice_fiscale: undefined,
      disabledAdd: disabled,
      selectedUO: selectedUO,
      selectedContact: undefined,
      disabledField: disabledField !== undefined ? disabledField : this.state.disabledField,
      disabledBtnSave: disabledBtnSave !== undefined ? disabledBtnSave : this.state.disabledBtnSave,
    });
  }

  handleSubmit() {
    const { utils } = this.props;
    const { enable_fea, enable_feq, enable_upload, selectedContact, enableAccessCode } = this.state;
    let selected = selectedContact ?? utils.current_contact;

    let returnData = {
      id_contatto: selected.id_contatto,
      nome_contatto: selected.cognome ? selected.nome + ' ' + selected.cognome : selected.azienda,
      tipo_firma: { enable_fea, enable_feq, enable_upload },
      disabledAdd: true,
    };

    if (enableAccessCode) returnData.codice_accesso = generateOTP();

    this.setState(initialState);
    this.resetFields(true);
    this.props.dispatch(utilsActions.unselectSavedContact());
    this.props.dispatch(utilsActions.unselectContacts());
    this.props.dispatch(utilsActions.unselectUo());
    this.props.onSubmit(returnData);
  }

  onChangeCheckbox = (event) => {
    const { checked, name } = event.target;
    const { enable_fea, enable_feq, enable_upload } = this.state;
    const listaFirme = [
      { label: 'enable_fea', value: enable_fea },
      { label: 'enable_feq', value: enable_feq },
      { label: 'enable_upload', value: enable_upload },
    ];

    const firmeAbilitate = listaFirme.filter((type) => type.value);

    if (firmeAbilitate.length > 1 || firmeAbilitate[0].label !== name)
      this.setState({
        [name]: checked,
      });
    else
      this.props.enqueueSnackbar('Deve essere abilita almeno una modalità di firma', {
        variant: 'warning',
      });
  };

  enableAccessCode = ({ target: { name, checked } }) => {
    this.setState({
      [name]: checked,
    });
  };

  selectDefault() {
    const { user } = this.props;
    if (user?.unita_organizzative?.length > 0) {
      this.props.dispatch(
        utilsActions.getContattiEsterniByUo(user.unita_organizzative[0].id_uo_group)
      );
      this.resetFields(true, user.unita_organizzative[0], false, false);
    }
  }

  render() {
    const {
      enable_fea,
      enable_feq,
      enable_upload,
      enableAccessCode,
      disabledField,
      disabledBtnSave,
    } = this.state;
    const { codiceFiscaleState } = this.state;
    const { selectedUO } = this.state;
    const { t, classes, utils, user, tipo_percorso, tipoFirma } = this.props;

    let disabledUO = false;
    let disabledBtnAdd = utils.current_contact ? false : true;
    let elencoContatti = [];
    let elencoUO = [];
    let elencoPrefissi = [
      { id: 39, prefisso: '+39' },
      { id: 33, vaprefissolue: '+33' },
      { id: 34, prefisso: '+34' },
      { id: 49, prefisso: '+49' },
      { id: 30, prefisso: '+30' },
      { id: 353, prefisso: '+353' },
      { id: 31, prefisso: '+31' },
      { id: 41, prefisso: '+41' },
      { id: 44, prefisso: '+44' },
    ].map((prefix) => ({
      value: prefix.id,
      label: prefix.prefisso,
    }));
    let selectedPrefix =
      this.getFormFieldValue('id_prefisso') ||
      elencoPrefissi.filter((option) => option.label === '+39')[0].value;
    if (user?.unita_organizzative?.length > 0) {
      elencoUO = user.unita_organizzative.map((uo) => ({
        value: uo,
        label: uo.nome,
      }));
      if (elencoUO.length === 1) disabledUO = true;
    }

    if (utils.elenco_contatti && utils.elenco_contatti.length > 0) {
      elencoContatti = utils.elenco_contatti.map((contatto) => ({
        value: contatto,
        label:
          (contatto.cognome ? contatto.cognome + ' ' + contatto.nome : '') +
          (contatto.cognome && contatto.azienda ? ', ' : '') +
          (contatto.azienda ? contatto.azienda : '') +
          (contatto.email ? ', ' + contatto.email : ''),
      }));
    }
    const tipologieFirma = [
      {
        show: ['mix', 'ext'].includes(tipo_percorso) && tipoFirma === 'fea',
        label: t('extContacts.advDigitalSign'),
        name: 'enable_fea',
        checked: enable_fea,
      },
      {
        show: ['mix', 'ext'].includes(tipo_percorso) && tipoFirma === 'fd',
        label: t('extContacts.digitalSignExt'),
        name: 'enable_feq',
        checked: enable_feq,
      },
      {
        show: ['mix', 'ext'].includes(tipo_percorso) && tipoFirma === 'fd',
        label: t('extContacts.uploadSignDoc'),
        name: 'enable_upload',
        checked: enable_upload,
      },
    ];

    return (
      <Dialog
        scroll="body"
        keepMounted
        open={this.props.open}
        PaperProps={{ style: { maxWidth: '50%', width: '50%', height: '72%' } }}
        TransitionComponent={Transition}
        onEntering={() => this.selectDefault()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                className={classes.selectFormControl + ' ' + classes.tcSignSelectControl}
              >
                <SelectSearch
                  success={this.state.id_uo_group_state === 'success'}
                  error={this.state.id_uo_group_state === 'error'}
                  label={t('document.labelUO')}
                  placeholder={t('document.labelSelUO')}
                  options={elencoUO}
                  isClearable={true}
                  id={'id_uo_group'}
                  isSearchable={true}
                  value={selectedUO ?? utils.current_uo ?? ''}
                  onChange={this.handleSelectedUO.bind(this)}
                  disabled={disabledUO}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                className={classes.selectFormControl + ' ' + classes.tcSignSelectControl}
              >
                <SelectSearch
                  success={this.state.id_contatto_state === 'success'}
                  error={this.state.id_contatto_state === 'error'}
                  label={t('extContacts.selExt')}
                  placeholder={t('extContacts.selExt')}
                  options={elencoContatti}
                  isClearable={true}
                  id={'id_contatto'}
                  isSearchable={true}
                  disabled={disabledField}
                  value={utils.current_contact}
                  onChange={this.handleSelectedContact}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: '20px' }}>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                error={this.state.nomeState === 'error'}
                labelText={t('extContacts.name')}
                id="nome"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'nome'),
                  type: 'text',
                  value: this.getFormFieldValue('nome') || '',
                  disabled: disabledField,
                }}
                className={classes.tcSignInputStyle}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                error={this.state.cognomeState === 'error'}
                labelText={t('extContacts.lastName')}
                id="cognome"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'cognome'),
                  type: 'text',
                  value: this.getFormFieldValue('cognome') || '',
                  disabled: disabledField,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                error={this.state.aziendaState === 'error'}
                labelText={t('extContacts.company')}
                id="azienda"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'azienda'),
                  type: 'text',
                  value: this.getFormFieldValue('azienda') || '',
                  disabled: disabledField,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                error={this.state.emailState === 'error'}
                labelText={t('extContacts.email')}
                id="email"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'email'),
                  type: 'email',
                  value: this.getFormFieldValue('email') || '',
                  disabled: disabledField,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                error={codiceFiscaleState === 'error'}
                labelText={t('extContacts.taxCode')}
                id="codice_fiscale"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'codice_fiscale'),
                  type: 'text',
                  value: this.getFormFieldValue('codice_fiscale') || '',
                  disabled: disabledField,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl
                fullWidth
                className={classes.selectFormControl + ' ' + classes.tcSignSelectControl}
              >
                <SelectSearch
                  label={t('extContacts.prefix')}
                  options={elencoPrefissi}
                  isClearable={true}
                  id={'id_prefisso'}
                  isSearchable={true}
                  disabled={true}
                  value={selectedPrefix}
                  onChange={this.handlePrefix}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                error={this.state.cellulareState === 'error'}
                labelText={t('extContacts.phone')}
                id="cellulare"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (event) => this.change(event, 'cellulare'),
                  type: 'text',
                  value: this.getFormFieldValue('cellulare') || '',
                  disabled: disabledField,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: '20px' }}>
            <GridItem xs={12} sm={12} md={12}>
              <FormLabel style={{ display: 'block', marginTop: '10px' }}>
                {t('extContacts.accessCode')}{' '}
                <span style={{ fontSize: '14px', display: 'block', marginTop: '10px' }}>
                  {t('extContacts.accessCodeDesc')}
                </span>
              </FormLabel>
              <FormControlLabel
                style={{ display: 'block', marginTop: '10px' }}
                classes={{ label: classes.label }}
                label={t('extContacts.accessCodeEnable')}
                control={
                  <Checkbox
                    size="sm"
                    name="enableAccessCode"
                    checked={enableAccessCode}
                    onChange={this.enableAccessCode}
                  />
                }
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          <Button color="success" onClick={() => this.handleSubmit()} disabled={disabledBtnAdd}>
            <Add style={{ margin: '0px' }} /> {t('action.add')}
          </Button>
          <Button
            onClick={() => this.handleSaveSigner()}
            color="info"
            style={{ float: 'right' }}
            disabled={disabledBtnSave}
          >
            <Save style={{ margin: '0px' }} /> {t('action.saveAddBook')}
          </Button>
          <Button color="info" onClick={() => this.handleClose()}>
            <Close style={{ margin: '0px' }} /> {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, raccoglitori, utils, users } = state;
  const { user } = authentication ?? {};
  return {
    authentication,
    alert,
    raccoglitori,
    utils,
    users,
    user,
  };
}

const connectedContattoEsternoModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(ContattoEsternoModalForm);
export default connectedContattoEsternoModalForm;
