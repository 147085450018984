//import config from 'config';
import { authHeader } from '../Helpers';

var wMount;
if (process.env.NODE_ENV === 'production') {
  wMount = process.env.REACT_APP_SERVICES_URL;
} else {
  wMount = process.env.REACT_APP_RACC_URL;
}

export const raccoglitoriService = {
  getAll,
  getRaccoglitoreById,
  save,
  getFirmatario,
  deleteById,
  recuperaDocumento,
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  //return fetch(`http://localhost:4000/raccoglitori/getall`, requestOptions).then(handleResponse);

  let url = wMount + '/api/v1/raccoglitore/';
  return fetch(url, requestOptions).then(handleResponse);
}

function getRaccoglitoreById(idRaccoglitore) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    data: { id: idRaccoglitore },
  };

  let url = wMount + '/api/v1/raccoglitore/' + idRaccoglitore;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteById(idRaccoglitore) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/raccoglitore/' + idRaccoglitore;

  return fetch(url, requestOptions).then(handleResponse);
}

function save(raccoglitore) {
  let wMethod = 'POST';
  let url = wMount + '/api/v1/raccoglitore/';
  if (typeof raccoglitore.id_raccoglitore !== 'undefined') {
    wMethod = 'PUT';
    url += raccoglitore.id_raccoglitore;
  }

  raccoglitore.tcs_percorso_firma = JSON.stringify(raccoglitore.tcs_percorso_firma);

  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(raccoglitore),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function getFirmatario(idraccoglitore) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/raccoglitore/firmatario/' + idraccoglitore;
  return fetch(url, requestOptions).then(handleResponse);
}

function recuperaDocumento(hash_code) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = wMount + '/api/v1/raccoglitore/firmatario/esterno/recuperaDoc/' + hash_code;
  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.Result.Code !== 'E100') {
        const error = data && data.Result && data.Result.Message;
        return Promise.reject(error);
      }
    }

    return data.Data;
  });
}
