import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/CustomButtons/Button.jsx';

// @material-ui/core components
import { Dialog, DialogContent, DialogActions, Slide, withStyles } from '@material-ui/core';

//custom
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

//icons
import { Save, Close } from '@material-ui/icons';
import PosizionamentoFirma from '../Utilities/PosizionamentoFirma';
import { wMount } from '../../Services/documenti.service';
import { documentiActions } from '../../Actions';
import { authHeader } from '../../Helpers';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = {
  //...extendedFormsStyle,
  ...regularFormsStyle,
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const initialState = { file: null };

class PosizionamentoFirmaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  onFileChange = (file, idDocumento) => {
    if (idDocumento) this.props.dispatch(documentiActions.getDocumentoById(idDocumento));
    this.setState({
      file,
    });
  };

  render() {
    const { t, classes, docID, uuid, currentTab, tema } = this.props;
    const { listaFirmatariInterni, handleSubmit, handleClose, saveDisabled } = this.props;
    const { file } = this.state;
    let cardColor = tema.theme_color;

    let listaFirmatariEsterni = [];

    const fileUrl = {
      url: wMount + '/api/v1/documents/download/' + uuid,
      httpHeaders: { ...authHeader() },
    };
    return (
      <Dialog
        open={true}
        PaperProps={{
          style: {
            maxWidth: '80%',
            width: '80%',
            height: '90%',
          },
        }}
        scroll="body"
        TransitionComponent={Transition}
        onEntering={() => this.onFileChange(fileUrl, docID)}
        keepMounted
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {file && (
                <PosizionamentoFirma
                  file={file}
                  currentTab={currentTab}
                  listaFirmatariEsterni={listaFirmatariEsterni}
                  listaFirmatariInterni={listaFirmatariInterni}
                />
              )}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          <Button color={cardColor} onClick={handleSubmit} disabled={saveDisabled}>
            <Save style={{ margin: '0px' }} /> {t('action.save')}
          </Button>
          <Button color="info" onClick={handleClose}>
            <Close style={{ margin: '0px' }} /> {t('action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { tema } = state;
  return {
    tema,
  };
}
const connectedPosizionamentoFirmaModal = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(withRouter(PosizionamentoFirmaModal));
//connect(mapStateToProps)(withStyles(styles)(PosizionamentoFirmaModal));

export default withStyles(styles)(connectedPosizionamentoFirmaModal);
