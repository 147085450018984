import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import DocumentNavigator from '../Utilities/DocumentNavigator';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const initialState = {
  showNav: false,
  numPages: null,
  pageNumber: 1,
};

class DocViewer extends React.Component {
  state = { ...initialState, file: this.props.file };

  onDocumentLoadSuccess(pdf) {
    this.setState({ numPages: pdf.numPages, showNav: true });
  }

  goToPrevPage = (pageNumber) => {
    if (pageNumber && pageNumber > 1)
      this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  };
  goToNextPage = (pageNumber, numPages) => {
    if (numPages && numPages !== pageNumber)
      this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
  };

  navigateDocs = (index, listaDoc, prevOrNext) => {
    this.props.handleClickOpenOtherFile(index, listaDoc, prevOrNext);
    this.setState({ pageNumber: 1, numPages: null });
  };

  render() {
    const { pageNumber, numPages, showNav, file } = this.state;
    const { t, documenti, risultati, showRisultati, listType, documento } = this.props;

    let numDocs = '';
    let index = '';
    let listaDoc = null;

    if (showRisultati) {
      const { id } = documento;
      listaDoc = risultati;
      numDocs = risultati.length;
      index = risultati.findIndex((doc) => doc.id_documento === id);
    } else if (documenti?.current?.id_documento) {
      let idLocal = documenti.current.id_documento;
      if (listType === 'items') {
        listaDoc = documenti.items;
        numDocs = documenti.items.length;
        index = documenti.items.map((e) => e.id_documento).indexOf(idLocal);
      } else if (listType === 'tasks') {
        listaDoc = documenti.tasks;
        numDocs = documenti.tasks.length;
        index = documenti.tasks.map((e) => e.id_documento).indexOf(idLocal);
      }
    }

    return (
      <>
        {showNav && !documenti.loading && (
          <DocumentNavigator
            numPages={numPages}
            pageNumber={pageNumber}
            goToPrevPage={this.goToPrevPage}
            goToNextPage={this.goToNextPage}
            listaDoc={listaDoc}
            numDocs={numDocs}
            index={index}
            navigateDocs={this.navigateDocs}
          />
        )}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Document
            file={file}
            loading={<div>{t('document.docViewer.loading')}</div>}
            error={<div>{t('document.docViewer.errorLoading')}</div>}
            onLoadSuccess={(pdf) => this.onDocumentLoadSuccess(pdf)}
          >
            <Page className="pdf_viewer" pageNumber={pageNumber} width={960} />
          </Document>
        </div>
        ;
        {showNav && !documenti.loading && (
          <DocumentNavigator
            numPages={numPages}
            pageNumber={pageNumber}
            goToPrevPage={this.goToPrevPage}
            goToNextPage={this.goToNextPage}
            navPosition="bottom"
            listaDoc={listaDoc}
            numDocs={numDocs}
            index={index}
            navigateDocs={this.navigateDocs}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { documenti } = state;
  const { showRisultati, risultati } = state.search;
  return {
    documenti,
    showRisultati,
    risultati,
  };
}

const connectedDocViewer = compose(connect(mapStateToProps), withTranslation())(DocViewer);
export default withRouter(connectedDocViewer);
