import { temaConstants } from '../Constants';

const initialState = {
  loading: true,
  error: undefined,
  id_tenant: undefined,
};

export function tema(state = initialState, action) {
  switch (action.type) {
    case temaConstants.GET_TEMA_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case temaConstants.GET_TEMA_SUCCESS:
      return {
        ...state,
        loading: false,
        id_tenant: action.value.tema.id_tenant,
        tenant_name: action.value.tema.tenant_name,
        tenant_code: action.value.tema.tenant_code,
        tenant_theme: action.value.tema.tenant_theme,
        theme_color: action.value.tema.theme_color,
        error: undefined,
      };
    case temaConstants.GET_TEMA_FAILURE:
      return {
        ...state,
        error: action.error,
        id_tenant: -1,
        loading: false,
      };
    case temaConstants.GET_TEMA_BY_ID_DOC_REQUEST:
      return { ...state, loading: true };
    case temaConstants.GET_TEMA_BY_ID_DOC_SUCCESS:
      return { ...state, loading: false, tema: action.tema };
    case temaConstants.GET_TEMA_BY_ID_DOC_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
