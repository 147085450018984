import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'components/CustomButtons/Button.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import Table from 'components/Table/Table.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import CheckboxTree from 'react-checkbox-tree';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/AddCircle';
import Remove from '@material-ui/icons/RemoveCircle';
import User from '@material-ui/icons/Person';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';

import SelectSearch from 'Custom/SelectSearch.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import FormValidationHelper from '../../Helpers/form-validation.js';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { userActions, alertActions, utilsActions } from '../../Actions';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import FormControl from '@material-ui/core/FormControl';
import { ModelViewHelper, Constants, Authorize } from 'Helpers/model-view.js';
import _ from 'lodash';
import moment from 'moment';
import Datetime from 'react-datetime';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const styles = {
  ...extendedFormsStyle,
  selectLabel: {
    fontSize: '16px',
    //textTransform: "uppercase",
    color: 'rgba(0, 0, 0, 0.87)',
    top: '8px',
  },
  tcSignSelectControl: {
    margin: '7px 1px 0px 0px !important',
  },
  tcSign_tree_item: {
    color: 'black',
  },
  tc_sign_searchSelectLabel: {
    fontSize: '10px',
    //textTransform: "uppercase",
    color: '#AAA !important',
    top: '8px',
    padding: '10px 0px',
  },
  tc_sign_datetime: {
    color: '#0047BB',
    top: '-2px',
    '& th.rdtSwitch': { textTransform: 'capitalize' },
    '& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle':
      {
        color: '#0047BB',
      },
    '& .rdtPicker td.rdtToday': { backgroundColor: '#eedc00 !important' },
    '& .rdtDay.rdtActive, .rdtDay.rdtActive:hover': {
      backgroundColor: '#0047BB !important',
      color: 'white',
    },
  },
};

const overlay = css`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
`;

const initialState = {
  direzione: '1',
  closeAlert: false,
  checked: undefined,
  expanded: undefined,
  unita_organizzative: undefined,
  ids_uo_visibili: undefined,
  ids_uo_visibili_expanded: undefined,
  id: undefined,
  priorita: undefined,
  tipo_percorso: undefined,
  username: undefined,
  usernameState: undefined,
  nome: undefined,
  nomeState: undefined,
  cognome: undefined,
  cognomeState: undefined,
  email: undefined,
  emailState: undefined,
  telefono: undefined,
  telefonoState: undefined,
  codice_fiscale: undefined,
  codice_fiscaleState: undefined,
  responsabile_uo: false,
  notifica_priorita_standard: undefined,
  notifica_priorita_urgente: undefined,
  notifica_riepilogo_settimana: undefined,
  notifica_riepilogo_giorno: undefined,
  showModalFirmatario: false,
  delegatiSelectedList: [],
  data_inizio_delega: null,
  data_fine_delega: null,
  tipo_delega: null,
  ruoli_utente_default: [],
};

class UtenteModalForm extends Dialog {
  constructor(props, context) {
    super(props, context);

    this.state = initialState;
  }

  getData(idUtente) {
    this.props.dispatch(userActions.getUtenteById(idUtente));
  }

  handleClickAttivaDelegato(id_utente_delegati, checked) {
    const { id } = this.props;
    this.props.dispatch(userActions.attivaDelegato(id, id_utente_delegati, checked));
  }

  //CAMUSI
  componentDidMount() {
    const { id, users, utils } = this.props;
    const { ruoli_utente, unita_organizzative } = utils;

    if (
      (typeof users === 'undefined' || typeof users.loading === 'undefined' || !users.loading) &&
      typeof id !== 'undefined' &&
      typeof users.current === 'undefined' &&
      typeof users.error === 'undefined'
    ) {
      this.getData(this.props.id);
    }

    if (
      (typeof utils.loading === 'undefined' || !utils.loading) &&
      typeof ruoli_utente === 'undefined'
    ) {
      this.props.dispatch(utilsActions.getRuoliUtente());
    }

    if (
      (typeof utils.loading === 'undefined' || !utils.loading) &&
      typeof unita_organizzative === 'undefined'
    ) {
      this.props.dispatch(utilsActions.getUnitaOrganizzative());
    }

    let idUtenteCorrente; // id dell'utente corrente
    idUtenteCorrente = this.props.id;

    //CAMUSI
    if (isNaN(idUtenteCorrente)) {
      this.setState({
        ruoli: utils.ruoli_utente_default,
      });
    }
  }

  handleSelect = (event) => {
    console.log({ [event.target.name]: event.target.value });
    this.setState({ [event.target.name]: event.target.value });
  };

  handleOpenModalFirmatario() {
    this.setState({
      showModalFirmatario: true,
    });
  }

  handleCloseAlert() {
    this.props.dispatch(alertActions.clear());
  }

  handleClose() {
    this.setState(initialState);
    this.setState({ delegatiSelectedList: [] });
    this.props.dispatch(userActions.resetUtente());
    this.props.onClose();
  }

  changeDate(value, stateLabel) {
    var date = moment(value);
    console.log(date);
    if (date.isValid()) {
      this.setState({ [stateLabel]: date.format('DD/MM/YYYY HH:mm') });
    } else {
      this.setState({ [stateLabel]: undefined });
    }
  }

  handleSubmit() {
    const { t, users, i18n } = this.props;

    let usernameState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('username'),
      1
    );
    let nomeState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('nome'),
      1
    );
    let cognomeState = FormValidationHelper.validateField(
      'required',
      this.getFormFieldValue('cognome'),
      1
    );
    let emailState = FormValidationHelper.validateField(
      'email',
      this.getFormFieldValue('email'),
      1
    );
    let codice_fiscaleState = FormValidationHelper.validateField(
      'codice_fiscale',
      this.getFormFieldValue('codice_fiscale'),
      1
    );

    if (
      usernameState === 'error' ||
      cognomeState === 'error' ||
      nomeState === 'error' ||
      emailState === 'error' ||
      codice_fiscaleState === 'error'
    ) {
      this.props.dispatch(alertActions.error('compilationError'));

      this.setState({
        usernameState: usernameState,
        nomeState: nomeState,
        cognomeState: cognomeState,
        emailState: emailState,
        codice_fiscaleState: codice_fiscaleState,
      });

      return;
    }

    this.setState({
      usernameState: usernameState,
      nomeState: nomeState,
      cognomeState: cognomeState,
      emailState: emailState,
      codice_fiscaleState: codice_fiscaleState,
    });

    let notifiche = [];
    let notifica_priorita_standard = this.getFormFieldValue('notifica_priorita_standard');
    let notifica_priorita_urgente = this.getFormFieldValue('notifica_priorita_urgente');
    let notifica_riepilogo_giorno = this.getFormFieldValue('notifica_riepilogo_giorno');
    let notifica_riepilogo_settimana = this.getFormFieldValue('notifica_riepilogo_settimana');

    //CAMUSI 06062020 inizio
    if (notifica_priorita_standard) {
      let wCurrent = {
        id_tipologia_notifica: 1,
        priorita: 'B',
        frequenza: '',
        flag_abilitato: true,
      };

      notifiche.push(wCurrent);
    }
    if (notifica_priorita_urgente) {
      let wCurrent = {
        id_tipologia_notifica: 1,
        priorita: 'A',
        frequenza: '',
        flag_abilitato: true,
      };

      notifiche.push(wCurrent);
    }

    if (notifica_riepilogo_giorno) {
      let wCurrent = {
        id_tipologia_notifica: 2,
        priorita: '',
        frequenza: '1G',
        flag_abilitato: true,
        lang: i18n.language,
      };

      notifiche.push(wCurrent);
    } else if (notifica_riepilogo_settimana) {
      let wCurrent = {
        id_tipologia_notifica: 2,
        priorita: '',
        frequenza: '1S',
        flag_abilitato: true,
        lang: i18n.language,
      };

      notifiche.push(wCurrent);
    }

    let uo_visibili = this.getFormFieldValue('ids_uo_visibili');

    //CAMUSI modifiche per rendere le unità organizzative obbligatorie
    let elenco_uo_org = this.getFormFieldValue('unita_organizzative');
    let unita_organizzative = [];
    if (elenco_uo_org.length > 0) {
      unita_organizzative = this.getFormFieldValue('unita_organizzative').map((prop, key) => {
        return { id_uo: prop.id_uo_group, is_responsabile: prop.is_responsabile };
      });
    } else {
      this.props.dispatch(alertActions.error('compilationErrorInUO'));
      return;
    }

    let ruoli = this.getFormFieldValue('ruoli').map((prop, key) => {
      return prop.id_ruolo;
    });

    let delegatiSel = [];

    if (this.state.delegatiSelectedList.length > 0) {
      delegatiSel = this.state.delegatiSelectedList;
    } else if (users && users.current && users.current.utente_delegati.length > 0) {
      delegatiSel = users.current.utente_delegati;
    }

    var idCorrente = users.current ? users.current.id_utente : undefined;
    let wUser = {
      id_utente: this.props.id ? this.props.id : idCorrente,
      codice_fiscale: this.getFormFieldValue('codice_fiscale'),
      cognome: this.getFormFieldValue('cognome'),
      nome: this.getFormFieldValue('nome'),
      telefono: this.getFormFieldValue('telefono'),
      email: this.getFormFieldValue('email'),
      username: this.getFormFieldValue('username'),
      ids_uo_visibili: uo_visibili,
      unita_organizzative: unita_organizzative,
      utente_delegati: delegatiSel,
      ruoli: ruoli,
      notifiche: notifiche,
    };

    this.setState({ delegatiSelectedList: [] });

    this.props.dispatch(userActions.saveUtente(wUser));
  }

  handleClickRemoveDelegato(idDelegato, tipo_delega) {
    let delegatiList = [];
    const { users } = this.props;

    if (this.state.delegatiSelectedList.length > 0) delegatiList = this.state.delegatiSelectedList;
    else if (users && users.current && users.current.utente_delegati.length > 0)
      delegatiList = users.current.utente_delegati;

    for (var i = 0; i < delegatiList.length; i++) {
      if (delegatiList[i].id_utente === idDelegato && delegatiList[i].tipo_delega === tipo_delega) {
        delegatiList.splice(i, 1);
        break;
      }
    }

    this.setState({ delegatiSelectedList: delegatiList });
  }

  handleClickRemoveUnitaOrganizzativa(idUnitaOrganizzativa) {
    const { utils } = this.props;
    let uoList = this.getFormFieldValue('unita_organizzative');

    //CAMUSI
    let idUtenteCorrente; // id dell'utente apppena selezionato
    idUtenteCorrente = this.props.id;

    let counter = 0; // verifico per quante UO l'utente in creazione è responsabile
    for (var j = 0; j < uoList.length; j++) {
      if (uoList[j].is_responsabile) {
        counter = counter + 1;
      }
    }

    let listaUoUtente = this.getFormFieldValue('unita_organizzative')
      ? this.getFormFieldValue('unita_organizzative')
      : [];

    let idUoDaEliminare = [];
    let listaUoUtenteResponsabile = [];
    let idFigliUoResponsabile = [];
    let idListaUoUtenteResponsabile = [];
    let idListaUoUtente = [];
    let totaleCheckedUo = [];

    for (let i = 0; i < uoList.length; i++) {
      if (uoList[i].id_uo_group === idUnitaOrganizzativa) {
        if (uoList[i].is_responsabile && counter === 1 && isNaN(idUtenteCorrente)) {
          this.handleClickRemoveRuolo(5); //CAMUSI vado ad eliminare dell'elenco il ruolo OPERATORE
        }
        uoList[i].is_responsabile = undefined; //CAMUSI altrimenti mi rimane la responsabilità dell'utente sulla UO
        idUoDaEliminare.push(uoList[i].id_uo_group); //CAMUSI 04032021 id della UO che si sta cancellando
        uoList.splice(i, 1);
        break;
      }
    }

    // "listaUoUtenteResponsabile" contiene tutte le UO per le quali l'utente è responsabile
    for (let i = 0; i < listaUoUtente.length; i++) {
      if (listaUoUtente[i].is_responsabile === true) {
        listaUoUtenteResponsabile = listaUoUtenteResponsabile.concat(listaUoUtente[i]);
      }
    }

    // "idListaUoUtenteResponsabile" contiene gli ID di tutte le UO per le quali l'utente è responsabile
    for (let i = 0; i < listaUoUtenteResponsabile.length; i++) {
      idListaUoUtenteResponsabile = idListaUoUtenteResponsabile.concat(
        listaUoUtenteResponsabile[i].id_uo_group
      );
    }

    //"idFigliUoResponsabile" contiene gli ID di tutte le UO figlie delle UO visibili all'utente e per le quali è responsabile
    idFigliUoResponsabile = this.trovaFigliUO(
      idListaUoUtenteResponsabile,
      utils.unita_organizzative
    );

    //"idListaUoUtente" contiene gli ID di tutte visibili all'utente
    for (let i = 0; i < listaUoUtente.length; i++) {
      idListaUoUtente = idListaUoUtente.concat(listaUoUtente[i].id_uo_group);
    }

    //"totaleCheckedUo" contiene gli ID di tutte visibili che devono andare in ckecked
    totaleCheckedUo = totaleCheckedUo.concat(idFigliUoResponsabile);
    totaleCheckedUo = totaleCheckedUo.concat(idListaUoUtente);

    this.setState({
      unitaOrganizzative: uoList,
      checked: totaleCheckedUo,
    });
  }

  handleClickRemoveRuolo(idRuolo) {
    let ruoliList = this.getFormFieldValue('ruoli');

    for (var i = 0; i < ruoliList.length; i++) {
      if (ruoliList[i].id_ruolo === idRuolo) {
        ruoliList.splice(i, 1);
        break;
      }
    }

    this.setState({ ruoli: ruoliList });
  }

  handleComprimiVisibilita() {
    this.setState({ ids_uo_visibili_expanded: [] });
  }

  handleEspandiVisibilita() {
    const { utils } = this.props;

    let espandi = [];
    if (typeof utils.unita_organizzative !== 'undefined') {
      espandi = utils.unita_organizzative.map((prop, key) => {
        return prop.id_uo_group;
      });
    }

    this.setState({ ids_uo_visibili_expanded: espandi });
  }

  handleAddUtenteDelegato() {
    const { users } = this.props;
    var _this = this;
    if (typeof this.state.id_utente_delegato === 'undefined') {
      return;
    }

    if (
      this.state.id_utente_delegato &&
      this.props.users.current &&
      this.state.id_utente_delegato === this.props.users.current.id_utente
    ) {
      this.props.dispatch(alertActions.error('selectNoDelegateUser'));
      return;
    }

    if (!this.state.data_inizio_delega) {
      this.props.dispatch(alertActions.error('startDateRequire'));
      return;
    }

    if (!this.state.tipo_delega) {
      this.props.dispatch(alertActions.error('delegationTypeReq'));
      return;
    }

    var utentiDelegatiList = [];
    if (this.state.delegatiSelectedList.length > 0)
      utentiDelegatiList = this.state.delegatiSelectedList;
    else if (users && users.current && users.current.utente_delegati.length > 0)
      utentiDelegatiList = users.current.utente_delegati;

    //var controlVar = false;
    if (
      _.find(utentiDelegatiList, {
        id_utente: this.state.id_utente_delegato,
        tipo_delega: this.state.tipo_delega,
      })
    ) {
      _this.props.dispatch(alertActions.error('existingDelegation'));
      return;
    }

    var delegatiArr = _.filter(utentiDelegatiList, { tipo_delega: 'D' });
    if (delegatiArr.length >= 1 && this.state.tipo_delega === 'D') {
      //CAMUSI 23022021
      _this.props.dispatch(alertActions.error('onlyOneDelegation'));
      return;
    }

    if (
      moment(_this.state.data_fine_delega, 'DD/MM/YYYY HH:mm').isBefore(
        moment(_this.state.data_inizio_delega, 'DD/MM/YYYY HH:mm')
      )
    ) {
      _this.props.dispatch(alertActions.error('delegationDateLessThen'));
      return;
    }

    for (var i = 0; i < users.items.length; i++) {
      if (users.items[i].id_utente === this.state.id_utente_delegato) {
        var utente = {};
        utente['id_utente'] = users.items[i].id_utente;
        utente['utente'] = users.items[i].utente;
        utente['data_inizio_delega'] = moment(
          this.state.data_inizio_delega,
          'DD/MM/YYYY HH:mm'
        ).format('YYYY-MM-DD HH:mm:ss');
        utente['data_fine_delega'] = this.state.data_fine_delega
          ? moment(this.state.data_fine_delega, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
          : null;
        utente['attiva'] = true;
        utente['disabled'] = true;
        utente['tipo_delega'] = this.state.tipo_delega;

        if (
          utentiDelegatiList.length > 0 &&
          utentiDelegatiList.some((item) => !_.isEqual(item, utente))
        )
          utentiDelegatiList.push(utente);
        else if (utentiDelegatiList.length === 0) utentiDelegatiList.push(utente);
      }
    }

    if (utentiDelegatiList.length > 0)
      this.setState({
        delegatiSelectedList: utentiDelegatiList,
        id_utente_delegato: undefined,
        data_inizio_delega: undefined,
        data_fine_delega: undefined,
        tipo_delega: undefined,
      });
    return;
  }

  /**
   *
   * @param {ID di tutte le UO visibili all'utente e per le quali è responsabile} a
   * @param {ID di tutte le UO esistenti} b
   *
   * Il metodo "trovaFigliUO" restituisce gli ID di tutte le UO figlie delle UO visibili all'utente e per le quali è responsabile
   */
  trovaFigliUO(a, b) {
    let c = [];
    for (let i = 0; i < a.length; i++) {
      for (let y = 0; y < b.length; y++) {
        if (b[y].parent_id === a[i]) {
          c.push(b[y].id_uo_group);
        }
      }
    }
    if (c.length === 0) return c;
    return c.concat(this.trovaFigliUO(c, b));
  }

  /**
   *
   * @param {codice ruolo da inserire nel caso di responsabilità sullaUO} codiceRuolo
   *
   * Il metodo "trovaRuolo" restituisce tutti quei ruoli che devono essere aggiunti in caso di resposabilità dell'utente sulla UO che si sta inserendo
   */
  trovaRuolo(codiceRuolo) {
    const { utils } = this.props;

    let ruoloBase = [];

    if (utils && utils.ruoli_utente) {
      for (var i = 0; i < utils.ruoli_utente.length; i++) {
        if (utils.ruoli_utente[i].cod_ruolo === codiceRuolo) {
          ruoloBase = ruoloBase.concat(utils.ruoli_utente[i]);
        }
      }
    }
    return ruoloBase;
  }

  handleAddUnitaOrganizzativa() {
    const { utils, user } = this.props;

    let ruoloPerResponsabile = [];

    if (typeof this.state.id_unita_organizzativa === 'undefined') {
      return;
    }

    for (var i = 0; i < utils.unita_organizzative.length; i++) {
      if (utils.unita_organizzative[i].id_uo_group === this.state.id_unita_organizzativa) {
        let wUnitaOrganizzativeList = this.getFormFieldValue('unita_organizzative');

        //CAMUSI
        for (var j = 0; j < wUnitaOrganizzativeList.length; j++) {
          if (wUnitaOrganizzativeList[j].id_uo_group === this.state.id_unita_organizzativa) {
            return;
          }
        }

        let wSelectedUO = utils.unita_organizzative[i];
        if (this.state.responsabile_uo) {
          wSelectedUO.id_responsabile = user.id_utente;
          wSelectedUO.is_responsabile = true;
          ruoloPerResponsabile = this.trovaRuolo('OPERATORE');

          //utilizzo dell'array "ruoloPerResponsabile" per poter avere la possibilità di inserire anche più ruoli aventi lo stesso codice
          for (var k = 0; k < ruoloPerResponsabile.length; k++) {
            this.handleAddRuolo(ruoloPerResponsabile[k]);
          }
        } else {
          wSelectedUO.is_responsabile = false;
        }
        wUnitaOrganizzativeList.push(wSelectedUO);

        let uoSelected = [];
        uoSelected = uoSelected.concat(utils.unita_organizzative[i]);

        let checked =
          this.getFormFieldValue('unita_organizzative').length > 0
            ? this.getFormFieldValue('unita_organizzative')
            : uoSelected;

        let idCheckedUo = [];
        let idUoCheckedResponsabili = [];
        let idFigliUoCheckedResponsabili = [];

        // Il ciclo estrapola gli ID delle UO visibili all'utente
        //"idCheckedUo" contiene gli ID di tutte le UO visibili all'utente
        for (let w = 0; w < checked.length; w++) {
          idCheckedUo.push(checked[w].id_uo_group);
        }

        //Il ciclo estrapola gli ID delle UO visibili all'utente e per le quali è responsabile
        //"idUoCheckedResponsabili" contiene gli ID di tutte le UO visibili all'utente e per le quali è responsabile
        for (let x = 0; x < checked.length; x++) {
          if (checked[x].is_responsabile === true) {
            idUoCheckedResponsabili = idUoCheckedResponsabili.concat(checked[x].id_uo_group);
          }
        }

        //"idFigliUoCheckedResponsabili" contiene gli ID di tutte le UO figlie delle UO visibili all'utente e per le quali è responsabile
        idFigliUoCheckedResponsabili = this.trovaFigliUO(
          idUoCheckedResponsabili,
          utils.unita_organizzative
        );

        //"idCheckedUo" contiene gli ID delle UO visibili all'utente E di tutte le UO figlie delle UO visibili all'utente e per le quali è responsabile
        idCheckedUo = idCheckedUo.concat(idFigliUoCheckedResponsabili);

        this.setState({
          unita_organizzative: wUnitaOrganizzativeList,
          id_unita_organizzativa: undefined,
          responsabile_uo: undefined,
          checked: idCheckedUo,
        });
        return;
      }
    }
  }

  handleAddRuolo(paramRuolo) {
    let ruolo = this.state.ruoloSelected ? this.state.ruoloSelected : paramRuolo; //CAMUSI
    // let ruoloBase = [];

    if (ruolo === 'undefined') {
      return;
    }

    let wRuoliList = this.getFormFieldValue('ruoli');

    for (var j = 0; j < wRuoliList.length; j++) {
      if (wRuoliList[j].id_ruolo === ruolo.id_ruolo) {
        return;
      }
    }
    wRuoliList.push(ruolo);
    this.setState({
      ruoli: wRuoliList,
      ruoloSelected: undefined,
    });
    return;
  }

  handleClickOpenFirmaForm(ruolo) {
    this.setState({
      selected_ruolo: ruolo,
      showModalRuolo: true,
    });
  }

  handleSubmitModalFirma(ruolo) {
    //this.props.dispatch(userActions.editSignature(firma));
  }

  handleCloseModalRuolo() {
    this.setState({
      showModalRuolo: false,
    });
  }

  getFormFieldValue(pFieldName) {
    const { id, users, utils } = this.props;

    if (pFieldName === 'ids_uo_visibili') {
      if (typeof this.state.checked === 'undefined') {
        if (users && users.current && users.current.ids_uo_visibili) {
          return users.current.ids_uo_visibili.split(',');
        } else {
          return [];
        }
      } else {
        return this.state.checked;
      }
    }

    if (typeof this.state[pFieldName] !== 'undefined') {
      return this.state[pFieldName];
    } else if (typeof id !== 'undefined' && typeof users.current !== 'undefined') {
      if (pFieldName === 'ids_uo_visibili_expanded') {
        if (typeof utils.unita_organizzative !== 'undefined') {
          let appo = utils.unita_organizzative.map((prop, key) => {
            return prop.id_uo_group;
          });
          return appo;
        }
      }
      let wReturn = ModelViewHelper.user_ModelToView(users.current);
      return wReturn[pFieldName];
    }

    if (
      pFieldName === 'ruoli' ||
      pFieldName === 'unita_organizzative' ||
      pFieldName === 'notifiche'
    ) {
      return [];
    } else if (pFieldName === 'ids_uo_visibili_expanded') {
      if (typeof utils.unita_organizzative !== 'undefined') {
        let appo = utils.unita_organizzative.map((prop, key) => {
          return prop.id_uo_group;
        });
        return appo;
      } else {
        return [];
      }
    }

    return '';
  }

  flatToHierarchy(flat) {
    let roots = []; // things without parent

    // make them accessible by guid on this map
    let all = {};

    flat.forEach(function (item) {
      //item.value = item.id_uo_group;
      //item.label = item.nome;
      //item.title = item.path;

      all[item.id_uo_group] = item;
    });

    // connect childrens to its parent, and split roots apart
    Object.keys(all).forEach(function (id_uo_group) {
      let item = all[id_uo_group];

      if (item.parent_id === null) {
        roots.push(item);
      } else if (item.parent_id in all) {
        let p = all[item.parent_id];
        if (!('children' in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });

    // done!
    return roots;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    const { value, checked } = event.target;

    let wState = {};
    if (!FormValidationHelper.validateChar(value)) {
      wState[stateName + 'State'] = 'error';
      this.setState(wState);
      return;
    }
    wState[stateName] = value;

    if (stateName === 'notifica_priorita_standard') {
      wState = {
        notifica_priorita_standard: checked,
      };
    }
    //else
    if (stateName === 'notifica_priorita_urgente') {
      wState = {
        notifica_priorita_urgente: checked,
      };
    }

    if (stateName === 'notifica_riepilogo_settimana') {
      wState = {
        notifica_riepilogo_settimana: checked,
      };

      if (checked) {
        wState['notifica_riepilogo_giorno'] = false;
      }
    } else if (stateName === 'notifica_riepilogo_giorno') {
      wState = {
        notifica_riepilogo_giorno: checked,
      };

      if (checked) {
        wState['notifica_riepilogo_settimana'] = false;
      }
    } else if (stateName === 'responsabile_uo') {
      wState = {
        responsabile_uo: checked,
      };
    }

    wState[stateName + 'State'] = FormValidationHelper.validateField(type, event.target.value, 1);

    this.setState(wState);
  }

  render() {
    const { tipo_delega, data_inizio_delega, data_fine_delega, id_utente_delegato } = this.state;
    const { t, classes, users, utils, tema } = this.props;
    const { unita_organizzative } = utils;
    let cardColor = tema.theme_color;
    let alertMessage = '';
    if (alert && typeof alert.message !== 'undefined') {
      const message = t(`alert.${alert.message}`);
      if (alert.type === 'alert-success') {
        alertMessage = (
          <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
            {message}
          </SweetAlert>
        );
      } else if (alert.type === 'alert-danger') {
        alertMessage = (
          <SweetAlert
            danger
            title={t('alert.warning')}
            onConfirm={() => {
              this.handleCloseAlert();
              if (alert.message === 'sessionExpired') {
                this.props.dispatch(userActions.logout());
              }
            }}
          >
            {message}
          </SweetAlert>
        );
      }
    }

    let loading;
    if (users && users.loading) {
      loading = (
        <div css={overlay}>
          <div css={override}>
            <ClipLoader sizeUnit={'px'} size={80} color={'#3a783d'} loading={users.loading} />
          </div>
        </div>
      );
    }

    //let ruoliAssegnati = this.getFormFieldValue("ruoli");
    //let ruoli = this.getFormFieldValue("ruoli").map((prop, key) => {

    let ruoli = this.getFormFieldValue('ruoli').map((prop, key) => {
      return [
        prop.id_ruolo,
        prop.cod_ruolo,
        prop.desc_ruolo,
        <Tooltip
          id="tooltip-top-start"
          title={t('action.delete')}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="Close"
            className={classes.tableActionButton}
            onClick={() => this.handleClickRemoveRuolo(prop.id_ruolo)}
          >
            <DeleteIcon
              style={{ color: 'red' }}
              className={classes.tableActionButtonIcon + ' ' + classes.close}
            />
          </IconButton>
        </Tooltip>,
      ];
    });

    let unitaOrganizzative = this.getFormFieldValue('unita_organizzative').map((prop, key) => {
      return [
        prop.id_uo_group,
        prop.nome,
        prop.is_responsabile ? t('misc.yes') : t('misc.no'),
        <Tooltip
          id="tooltip-top-start"
          title={t('action.delete')}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="Close"
            className={classes.tableActionButton}
            onClick={() => this.handleClickRemoveUnitaOrganizzativa(prop.id_uo_group)}
          >
            <DeleteIcon
              style={{ color: 'red' }}
              className={classes.tableActionButtonIcon + ' ' + classes.close}
            />
          </IconButton>
        </Tooltip>,
      ];
    });

    let delegatiSelectedList = [];
    var this_ = this;
    if (this.state.delegatiSelectedList.length > 0) {
      delegatiSelectedList = this.state.delegatiSelectedList;
    } else if (users?.current?.utente_delegati?.length > 0) {
      delegatiSelectedList = users.current.utente_delegati;
    }
    delegatiSelectedList = delegatiSelectedList.map((prop, key) => {
      return [
        prop.id_utente,
        prop.utente,
        moment(prop.data_inizio_delega).isValid()
          ? moment(prop.data_inizio_delega).format('DD/MM/YYYY HH:mm')
          : '',
        prop.data_fine_delega && moment(prop.data_fine_delega).isValid()
          ? moment(prop.data_fine_delega).format('DD/MM/YYYY HH:mm')
          : '',
        prop.tipo_delega === 'D' ? t('user.delegates.delegation') : t('user.delegates.verify'),
        <Checkbox
          disabled={prop.disabled ? true : false}
          tabIndex={-1}
          onChange={(e) =>
            this_.handleClickAttivaDelegato(prop.id_utente_delegati, e.target.checked)
          }
          checked={prop.attiva}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
          }}
        />,
        <Tooltip
          id="tooltip-top-start"
          title={t('action.delete')}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="Close"
            className={classes.tableActionButton}
            onClick={() => this.handleClickRemoveDelegato(prop.id_utente, prop.tipo_delega)}
          >
            <DeleteIcon
              style={{ color: 'red' }}
              className={classes.tableActionButtonIcon + ' ' + classes.close}
            />
          </IconButton>
        </Tooltip>,
      ];
    });

    let wUnitorganizzativeTree = [];
    if (typeof unita_organizzative !== 'undefined') {
      let wUnitaOrganizzative = unita_organizzative.map((item) => ({
        id_uo_group: item.id_uo_group,
        parent_id: item.parent_id,
        value: item.id_uo_group,
        label: item.nome,
        title: item.path,
        className: 'tcSign_tree_item',
      }));
      wUnitorganizzativeTree = this.flatToHierarchy(wUnitaOrganizzative);
    }

    let wButtonSalva = '';
    if (Authorize.checkAuthorizeFunc('FNCCMU')) {
      wButtonSalva = (
        <Button color={cardColor} onClick={() => this.handleSubmit()}>
          <Save style={{ margin: '0px' }} /> {t('action.save')}
        </Button>
      );
    }

    let optionRuolo = [];
    if (utils && utils.ruoli_utente) {
      optionRuolo = utils.ruoli_utente.map((ruolo) => ({
        value: ruolo,
        label: ruolo.desc_ruolo,
      }));
    }

    let optionUnitaOrganizzative = [];
    if (utils && utils.unita_organizzative) {
      optionUnitaOrganizzative = utils.unita_organizzative.map((unitaOrganizzativa) => ({
        value: unitaOrganizzativa.id_uo_group,
        label: unitaOrganizzativa.nome,
      }));
    }

    let optionUtentiDelegabili = [];
    if (users && users.items) {
      optionUtentiDelegabili = users.items.map((utente) => ({
        value: utente.id_utente,
        label: utente.utente,
      }));
    }

    let wButtonAddRuoloDisabled = false;
    let disableAddUnitaOrganizzativa = false;
    let disableAddUtenteDelegato = false;

    if (!this.state.ruoloSelected) wButtonAddRuoloDisabled = true;
    if (!this.state.id_unita_organizzativa) disableAddUnitaOrganizzativa = true;
    if (!tipo_delega || !data_inizio_delega || !data_fine_delega || !id_utente_delegato)
      disableAddUtenteDelegato = true;

    let wTitle = this.props.title;
    if (typeof users.current !== 'undefined') {
      wTitle = 'Username: ' + this.getFormFieldValue('username');
    }

    return (
      <div>
        <Dialog
          open={this.props.open}
          TransitionComponent={Transition}
          keepMounted
          scroll="body"
          fullScreen
          onClose={() => this.handleClose()}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          {loading}
          {alertMessage}
          <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
            <Card style={{ marginBottom: '0px' }}>
              <CardHeader color={cardColor} icon>
                <CardIcon color={cardColor}>
                  <User />
                </CardIcon>
                <h4 className={classes.cardTitle} style={{ color: '#777' }}>
                  {wTitle}
                </h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  color={cardColor}
                  tabs={[
                    {
                      tabButton: t('user.general'),
                      tabContent: (
                        <GridContainer style={{ width: '100%', margin: '0px' }}>
                          <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  success={this.state.usernameState === 'success'}
                                  error={this.state.usernameState === 'error'}
                                  labelText="Username"
                                  id="username"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    onChange: (event) => this.change(event, 'username', 'required'),
                                    type: 'username',
                                    value: this.getFormFieldValue('username'),
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              success={this.state.nomeState === 'success'}
                              error={this.state.nomeState === 'error'}
                              labelText={t('user.name')}
                              id="nome"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                onChange: (event) => this.change(event, 'nome', 'required'),
                                type: 'nome',
                                value: this.getFormFieldValue('nome') || '',
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              success={this.state.cognomeState === 'success'}
                              error={this.state.cognomeState === 'error'}
                              labelText={t('user.lastName')}
                              id="cognome"
                              formControlProps={{
                                fullWidth: true,
                                required: false,
                              }}
                              inputProps={{
                                onChange: (event) => this.change(event, 'cognome', 'required'),
                                type: 'cognome',
                                value: this.getFormFieldValue('cognome') || '',
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              success={this.state.codice_fiscaleState === 'success'}
                              error={this.state.codice_fiscaleState === 'error'}
                              labelText={t('user.taxCode')}
                              id="codice_fiscale"
                              formControlProps={{
                                fullWidth: true,
                                required: false,
                              }}
                              inputProps={{
                                onChange: (event) =>
                                  this.change(event, 'codice_fiscale', 'codice_fiscale'),
                                type: 'codice_fiscale',
                                value: this.getFormFieldValue('codice_fiscale') || '',
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              success={this.state.emailState === 'success'}
                              error={this.state.emailState === 'error'}
                              labelText="E-mail"
                              id="email"
                              formControlProps={{
                                fullWidth: true,
                                required: true,
                              }}
                              inputProps={{
                                onChange: (event) => this.change(event, 'email', 'email'),
                                type: 'email',
                                value: this.getFormFieldValue('email') || '',
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              success={this.state.telefonoState === 'success'}
                              error={this.state.telefonoState === 'error'}
                              labelText={t('user.phoneNumber')}
                              id="telefono"
                              formControlProps={{
                                fullWidth: true,
                                required: false,
                              }}
                              inputProps={{
                                onChange: (event) => this.change(event, 'telefono', 'required'),
                                type: 'telefono',
                                value: this.getFormFieldValue('telefono') || '',
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: t('user.uoTab'),
                      tabContent: (
                        <GridContainer style={{ width: '100%' }}>
                          <GridItem xs={12} sm={12} md={7}>
                            <Table
                              hover
                              tableHeaderColor="warning"
                              tableHead={['Id', t('uo.name'), t('uo.manager')]}
                              tableData={unitaOrganizzative}
                              customCellClasses={[classes.center, classes.center, classes.left]}
                              customClassesForCells={[0, 1, 2]}
                              customHeadCellClasses={[
                                classes.center,
                                classes.center,
                                classes.center,
                              ]}
                              customHeadClassesForCells={[0, 1, 2]}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{
                              borderLeftWidth: '1px',
                              borderLeftColor: '#c4c4c4',
                              borderLeftStyle: 'solid',
                            }}
                          >
                            <GridContainer style={{ width: '100%' }} direction="column">
                              <GridItem xs={12} sm={12} md={12}>
                                <SelectSearch
                                  label={t('user.uo')}
                                  placeholder={t('user.selectUo')}
                                  options={optionUnitaOrganizzative}
                                  isClearable={true}
                                  id={'id_unita_organizzativa'}
                                  isSearchable={true}
                                  onChange={this.handleSelect}
                                  value={this.state.id_unita_organizzativa || ''}
                                  disabled={false}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <FormControlLabel
                                  style={{ marginTop: '18px' }}
                                  control={
                                    <Switch
                                      color="primary"
                                      checked={this.getFormFieldValue('responsabile_uo')}
                                      onChange={(event) =>
                                        this.change(event, 'responsabile_uo', 'responsabile_uo')
                                      }
                                    />
                                  }
                                  classes={{ label: classes.selectLabel }}
                                  label={t('user.respUser')}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <Tooltip
                                  id="tooltip-top"
                                  title={t('user.addUo')}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <Button
                                    disabled={disableAddUnitaOrganizzativa}
                                    simple
                                    onClick={this.handleAddUnitaOrganizzativa.bind(this)}
                                    color="success"
                                    className={classes.tcsign_action}
                                  >
                                    <Add />
                                    {t('action.add')}
                                  </Button>
                                </Tooltip>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: t('user.visibility'),
                      tabContent: (
                        <GridContainer style={{ width: '100%' }} direction="column">
                          <GridItem xs={12} sm={12} md={12}>
                            <Tooltip
                              id="tooltip-top"
                              title={t('user.expandTree')}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                              //style={{ float: "left" }}
                            >
                              <Button
                                simple
                                style={{ padding: '0px', margin: '5px' }}
                                onClick={this.handleEspandiVisibilita.bind(this)}
                                color="info"
                                className={classes.tcsign_action}
                              >
                                <Add />
                                {t('user.expand')}
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title={t('user.compressTree')}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                              //style={{ float: "right" }}
                            >
                              <Button
                                style={{ padding: '0px', margin: '5px' }}
                                simple
                                onClick={this.handleComprimiVisibilita.bind(this)}
                                color="info"
                                className={classes.tcsign_action}
                              >
                                <Remove />
                                {t('user.compress')}
                              </Button>
                            </Tooltip>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CheckboxTree
                              icons={{
                                leaf: '',
                                parentClose: '',
                                parentOpen: '',
                              }}
                              showExpandAll={true}
                              showNodeTitle={true}
                              nodes={wUnitorganizzativeTree}
                              checked={this.getFormFieldValue('ids_uo_visibili')}
                              expanded={this.getFormFieldValue('ids_uo_visibili_expanded')}
                              noCascade={true}
                              onCheck={(checked) => this.setState({ checked })}
                              onExpand={(expanded) =>
                                this.setState({ ids_uo_visibili_expanded: expanded })
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: t('user.rolesTab'),
                      tabContent: (
                        <GridContainer style={{ width: '100%' }}>
                          <GridItem xs={12} sm={12} md={7}>
                            <Table
                              hover
                              tableHeaderColor="warning"
                              tableHead={['#', t('user.code'), t('user.description')]}
                              tableData={ruoli}
                              customCellClasses={[classes.center, classes.center, classes.left]}
                              customClassesForCells={[0, 1, 2]}
                              customHeadCellClasses={[
                                classes.center,
                                classes.center,
                                classes.center,
                              ]}
                              customHeadClassesForCells={[0, 1, 2]}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{
                              borderLeftWidth: '1px',
                              borderLeftColor: '#c4c4c4',
                              borderLeftStyle: 'solid',
                            }}
                          >
                            <GridContainer style={{ width: '100%' }} direction="column">
                              <GridItem xs={12} sm={12} md={12}>
                                <SelectSearch
                                  placeholder={t('user.selectRole')}
                                  options={optionRuolo}
                                  isClearable={true}
                                  id={'ruoloSelected'}
                                  isSearchable={false}
                                  onChange={this.handleSelect}
                                  value={this.state.ruoloSelected}
                                  disabled={false}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <Tooltip
                                  id="tooltip-top"
                                  title={t('user.addRole')}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <Button
                                    disabled={wButtonAddRuoloDisabled}
                                    simple
                                    onClick={this.handleAddRuolo.bind(this)}
                                    color="success"
                                    className={classes.tcsign_action}
                                  >
                                    <Add />
                                    {t('action.add')}
                                  </Button>
                                </Tooltip>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: t('user.notifications.title'),
                      tabContent: (
                        <GridContainer style={{ width: '100%' }}>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              style={{ marginTop: '18px' }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={this.getFormFieldValue('notifica_priorita_standard')}
                                  onChange={(event) =>
                                    this.change(
                                      event,
                                      'notifica_priorita_standard',
                                      'notifica_priorita_standard'
                                    )
                                  }
                                  classes={{}}
                                />
                              }
                              classes={{
                                label: classes.selectLabel,
                              }}
                              label={t('user.notifications.standard')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              style={{ marginTop: '18px' }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={this.getFormFieldValue('notifica_priorita_urgente')}
                                  onChange={(event) =>
                                    this.change(
                                      event,
                                      'notifica_priorita_urgente',
                                      'notifica_priorita_urgente'
                                    )
                                  }
                                  classes={{}}
                                />
                              }
                              classes={{
                                label: classes.selectLabel,
                              }}
                              label={t('user.notifications.urgent')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              style={{ marginTop: '18px' }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={this.getFormFieldValue('notifica_riepilogo_giorno')}
                                  onChange={(event) =>
                                    this.change(
                                      event,
                                      'notifica_riepilogo_giorno',
                                      'notifica_riepilogo_giorno'
                                    )
                                  }
                                  classes={{}}
                                />
                              }
                              classes={{
                                label: classes.selectLabel,
                              }}
                              label={t('user.notifications.oneDay')}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              style={{ marginTop: '18px' }}
                              control={
                                <Switch
                                  color="primary"
                                  checked={this.getFormFieldValue('notifica_riepilogo_settimana')}
                                  onChange={(event) =>
                                    this.change(
                                      event,
                                      'notifica_riepilogo_settimana',
                                      'notifica_riepilogo_settimana'
                                    )
                                  }
                                  classes={{}}
                                />
                              }
                              classes={{
                                label: classes.selectLabel,
                              }}
                              label={t('user.notifications.oneWeek')}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: t('user.delegates.title'),
                      tabContent: (
                        <GridContainer style={{ width: '100%' }}>
                          <GridItem xs={12} sm={12} md={7}>
                            <Table
                              hover
                              tableHeaderColor="warning"
                              tableHead={[
                                'Id',
                                t('user.delegates.name'),
                                t('user.delegates.startDate'),
                                t('user.delegates.endDate'),
                                t('user.delegates.type'),
                                t('user.delegates.status'),
                              ]}
                              tableData={delegatiSelectedList || ''}
                              customCellClasses={[
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.left,
                              ]}
                              customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                              customHeadCellClasses={[
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                                classes.center,
                              ]}
                              customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={5}
                            style={{
                              borderLeftWidth: '1px',
                              borderLeftColor: '#c4c4c4',
                              borderLeftStyle: 'solid',
                            }}
                          >
                            <GridContainer style={{ width: '100%' }} direction="row">
                              <GridItem xs={12} sm={12} md={12}>
                                <SelectSearch
                                  label={t('user.delegates.delegablesUsr')}
                                  placeholder={t('user.delegates.selectUser')}
                                  options={optionUtentiDelegabili}
                                  isClearable={true}
                                  id={'id_utente_delegato'}
                                  isSearchable={true}
                                  onChange={this.handleSelect}
                                  value={this.state.id_utente_delegato || ''}
                                  disabled={false}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl fullWidth>
                                  <label className={classes.tc_sign_searchSelectLabel}>
                                    {t('user.delegates.startDate')}
                                  </label>
                                  <Datetime
                                    className={classes.tc_sign_datetime}
                                    closeOnSelect={true}
                                    onChange={(value) =>
                                      this.changeDate(value, 'data_inizio_delega')
                                    }
                                    timeFormat={true}
                                    locale={'IT-it'}
                                    viewDate={moment()}
                                    value={this.state.data_inizio_delega}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl fullWidth>
                                  <label className={classes.tc_sign_searchSelectLabel}>
                                    {t('user.delegates.endDate')}
                                  </label>
                                  <Datetime
                                    className={classes.tc_sign_datetime}
                                    closeOnSelect={true}
                                    onChange={(value) => this.changeDate(value, 'data_fine_delega')}
                                    timeFormat={true}
                                    locale={'IT-it'}
                                    viewDate={moment()}
                                    value={this.state.data_fine_delega}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <SelectSearch
                                  label={t('user.delegates.type')}
                                  placeholder={t('user.delegates.selectType')}
                                  options={[
                                    { value: 'D', label: t('user.delegates.delegation') },
                                    { value: 'V', label: t('user.delegates.verify') },
                                  ]}
                                  isClearable={true}
                                  id={'tipo_delega'}
                                  isSearchable={true}
                                  onChange={this.handleSelect}
                                  value={this.state.tipo_delega || ''}
                                  disabled={false}
                                />
                              </GridItem>
                              {Authorize.checkAuthorizeFunc('FNCGDG') ? (
                                <GridItem xs={12} sm={12} md={12}>
                                  <Tooltip
                                    id="tooltip-top"
                                    title={t('user.delegates.userAdd')}
                                    placement="bottom"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Button
                                      disabled={disableAddUtenteDelegato}
                                      simple
                                      onClick={this.handleAddUtenteDelegato.bind(this)}
                                      color="success"
                                      className={classes.tcsign_action}
                                    >
                                      <Add />
                                      {t('action.add')}
                                    </Button>
                                  </Tooltip>
                                </GridItem>
                              ) : (
                                ''
                              )}
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            {wButtonSalva}
            <Button color="info" onClick={() => this.handleClose()}>
              <Close style={{ margin: '0px' }} /> {t('action.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, users, utils, tema } = state;
  const { user } = authentication ?? {};
  return {
    authentication,
    alert,
    users,
    utils,
    user,
    tema,
  };
}

const connectedUtenteModalForm = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTranslation()
)(UtenteModalForm);
export default connectedUtenteModalForm;
