//import config from 'config';
import { authHeader } from '../Helpers';

var wMount;
var wMountDoc;
var wMountRac;
if (process.env.NODE_ENV === 'production') {
  wMount = process.env.REACT_APP_SERVICES_URL;
  wMountDoc = process.env.REACT_APP_SERVICES_URL;
  wMountRac = process.env.REACT_APP_SERVICES_URL;
} else {
  wMount = process.env.REACT_APP_USR_URL;
  wMountDoc = process.env.REACT_APP_DOC_URL;
  wMountRac = process.env.REACT_APP_RACC_URL;
}

export const utilsService = {
  getUnitaOrganizzative,
  getTipologiaDocumento,
  getStatoDocumento,
  getUtentiByUnitaOrganizzativa,
  getTipologiaRaccoglitore,
  getAllFunctions,
  saveContatto,
  getContattiEsterni,
  getContattiEsterniByUo,
};

function getUnitaOrganizzative() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let wUrl = wMount + '/api/v1/uo';

  return fetch(wUrl, requestOptions).then(handleResponse);
}

function getUtentiByUnitaOrganizzativa(unita_organizzativa) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let wUrl = wMount + '/api/v1/uo/' + unita_organizzativa.id_unita_organizzativa;

  return fetch(wUrl, requestOptions).then(handleResponse);
}

function getTipologiaDocumento() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  //let wUrl = "http://localhost:4000/utils/gettipologiadocumento";
  let wUrl = wMountDoc + '/api/v1/documents/tipologica/tipiDocumento';
  return fetch(wUrl, requestOptions).then(handleResponse);
}

function getTipologiaRaccoglitore() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let wUrl = wMountRac + '/api/v1/raccoglitore/tipologica/tipiRaccoglitore';
  return fetch(wUrl, requestOptions).then(handleResponse);
}

function getStatoDocumento() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  //let wUrl = `http://localhost:4000/utils/getstatodocumento`;
  let wUrl = wMountDoc + '/api/v1/documents/tipologica/statiDocumento';

  return fetch(wUrl, requestOptions).then(handleResponse);
}

function getAllFunctions() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let wUrl = wMountRac + '/api/v1/raccoglitore/funzioni/all';

  return fetch(wUrl, requestOptions).then(handleResponse);
}

function saveContatto(contatto) {
  let wMethod = 'POST';
  if (contatto.contatto.id_contatto) {
    wMethod = 'PUT';
  }
  const requestOptions = {
    method: wMethod,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(contatto),
  };

  let url = wMountRac + '/api/v1/raccoglitore/firmatario/contatto/save/';
  return fetch(url, requestOptions).then(handleResponse);
}

function getContattiEsterni() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let wUrl = wMountRac + '/api/v1/raccoglitore/firmatario/contatti/getAll';
  return fetch(wUrl, requestOptions).then(handleResponse);
}

function getContattiEsterniByUo(id_uo) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let wUrl = wMountRac + '/api/v1/raccoglitore/firmatario/contatti/getAll/' + id_uo;
  return fetch(wUrl, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = data?.message || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.Result.Code !== 'E100') {
        const error = data?.Result?.Message;
        return Promise.reject(error);
      }
    }

    return data.Data;
  });
}
